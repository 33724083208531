import { LinearProgress, Typography } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { MyFab } from '../comps/MyFab';
import { VirtualizedTable, stableSort, getComparator } from '../comps/MyTable';
import AddBrandDialog from '../dialogs/brand/AddBrandDialog';
import EditBrandDialog from '../dialogs/brand/EditBrandDialog';
import { useWhyDidYouUpdate } from '../hooks/whyDidYouUpdate';
import { useGlobalAppState } from '../state/myGlobalContext';
import { renderExceptionCell } from '../comps/CheckmarkCell';
import { ErrorsAlert } from '../comps/ErrorsAlert';

export default function BrandConfiguration(props) {

    console.log('BrandConfiguration props:', props);

    useWhyDidYouUpdate('BrandConfiguration', props)

    const {
        state: {
            brands,
            isLoading,
            lastError
        },
        load_brands, set_lastError
    } = useGlobalAppState()

    const [toOpenAddBrandModal, set_toOpenAddBrandModal] = useState(false);
    const [toOpen_EditBrandDialog, set_toOpen_EditBrandDialog] = useState(false);
    const [editBrandItem, set_editBrandItem] = useState(null);

    const [itemsSorted, set_itemsSorted] = useState([]);
    // sorting
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('chain_code_desc');

    const handleRequestSort = (event, property) => {
        console.log('handleRequestSort:', property);

        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    }

    const load_brands_cb = useCallback(load_brands, []);

    useEffect(() => {
        load_brands_cb()
    }, [load_brands_cb]);

    useEffect(() => {
        const sorted = stableSort(brands, getComparator(order, orderBy))
        set_itemsSorted(sorted)
    }, [brands, order, orderBy]);

    const onRowDoubleClick = (e) => {
        console.log('onRowDoubleClick', e);
        onEditItem(e.index)
    }

    const onEditItem = (rowIndex) => {
        // const itemToEdit = brands[rowIndex];
        const itemToEdit = itemsSorted[rowIndex];
        // console.log('onEditItem', rowIndex, itemToEdit);
        set_editBrandItem(itemToEdit)
        set_toOpen_EditBrandDialog(true)
    }

    const handleClose_AddBrandDialog = (toReload = false) => {
        set_toOpenAddBrandModal(false)
        if (toReload) {
            load_brands_cb()
        }
    };

    const handleClose_EditBrandDialog = (toReload = false) => {
        set_toOpen_EditBrandDialog(false)
        set_editBrandItem(null)
        if (toReload) {
            load_brands_cb()
        }
    }

    // this way, close handler does not provoke rerender of Dialog
    const handleClose_AddBrandDialog_cb = useCallback(handleClose_AddBrandDialog, []);
    const handleClose_EditBrandDialog_cb = useCallback(handleClose_EditBrandDialog, []);



    return (
        <div className="BrandConfiguration">

            <Typography variant="h2" color="primary">BRAND CONFIGURATION</Typography>

            {/*  <div>
                <Button onClick={loadBrandsFromBackend}>get token (RE)load brands</Button>
                <Button onClick={dev_emptyTable}>empty table</Button>
            </div> */}

            <div style={{ height: '12px' }}>
                {
                    isLoading && !toOpenAddBrandModal && <LinearProgress />
                }
            </div>

            <ErrorsAlert lastError={lastError} set_lastError={set_lastError} />

            <div style={{ height: '100%', width: '100%', padding: 12, boxSizing: 'border-box' }}>
                {
                    // brands &&
                    itemsSorted &&
                    <VirtualizedTable
                        // rowCount={brands.length}
                        // rowGetter={({ index }) => brands[index]}
                        rowCount={itemsSorted.length}
                        rowGetter={({ index }) => itemsSorted[index]}
                        // onRowClick={onRowClick}
                        onEditItem={onEditItem}
                        onRowDoubleClick={onRowDoubleClick}

                        order={order}
                        orderBy={orderBy}
                        // defaultOrder="asc"
                        // defaultOrderBy="chain_code_desc"
                        onRequestSort={handleRequestSort}

                        columns={[
                            {
                                width: 80,
                                label: 'BRAND',
                                dataKey: 'chain_code_desc',
                            },
                            {
                                width: 80,
                                label: 'BILLING GROUP',
                                dataKey: 'billing_group_desc',
                                numeric: true,
                            },
                            {
                                width: 280,
                                label: 'PROCESS GROUP',
                                dataKey: 'procedure_description',
                                numeric: true,
                            },
                            {
                                width: 5,
                                label: 'ENROLLMENT STAY EXCEPTIONS',
                                dataKey: 'exception_codes_enrollment',
                                numeric: true,
                                renderCustom: renderExceptionCell,
                            },
                            {
                                width: 5,
                                label: 'REPEAT STAY EXCEPTIONS',
                                dataKey: 'exception_codes_repeat',
                                numeric: true,
                                renderCustom: renderExceptionCell,
                            },
                            {
                                width: 70,
                                label: '',
                                dataKey: 'actionButtons',
                                numeric: true,
                            },
                        ]}
                    />
                }
            </div>

            <div className="footer">
                <MyFab onClick={e => set_toOpenAddBrandModal(true)} />
            </div>

            {
                toOpenAddBrandModal &&
                <AddBrandDialog
                    open={toOpenAddBrandModal}
                    onClose={handleClose_AddBrandDialog_cb}
                />
            }
            {
                toOpen_EditBrandDialog &&
                <EditBrandDialog
                    open={toOpen_EditBrandDialog}
                    onClose={handleClose_EditBrandDialog_cb}
                    editBrandItem={editBrandItem}
                />}

        </div>
    );
}
