import { makeStyles } from "@material-ui/core";

export const useStyles_dialog = makeStyles(theme => (
    {
        formControl: {
            margin: theme.spacing(1, 0),
        },
        formGroup_enrollment: {
            margin: theme.spacing(1, 0),
        },

        formGroup_row: {
            display: 'flex',
            flexDirection: 'row',
            width: '100%'
        },

        dialogContentMinHeight: {
            minHeight: '240px'
        }
    }
));

export const useStyles_dialogLink = makeStyles(theme => (
    {
        formControl: {
            margin: theme.spacing(1, 0),
        },
        formControlRadioGroup: {
            margin: theme.spacing(3, 0),
        },
        formControlRadioGroup_horizontal: {
            margin: theme.spacing(1, 0),
            display: 'flex',
            flexDirection: 'row'
        },
        formControlFilter_horizontal_date: {
            marginRight: theme.spacing(1),
        },
    }
));

export const useStyles_dialogRules = makeStyles(theme => (
    {
        formControl: {
            margin: theme.spacing(1, 1),
        },
        formControlRadioGroup: {
            margin: theme.spacing(1, 1),
        },
        formControlRadioGroup_horizontal: {
            margin: theme.spacing(1, 0),
            display: 'flex',
            flexDirection: 'row'
        },
        formControl_subGroup_horizontal: {
            margin: theme.spacing(0, 2),
        },
        formControl_inHorizontal: {
            marginRight: theme.spacing(2),
        },
        dialogPaper: {
            minHeight: '85vh',
            maxHeight: '85vh',
            height: '85vh',
        },
    }
));