import React, { useEffect, useState, useCallback } from 'react';
import { Typography, Box, Fab, LinearProgress } from '@material-ui/core';
import CreateLinkDialog from '../dialogs/link/CreateLinkDialog';
import EditLinkDialog from '../dialogs/link/EditLinkDialog';
import { MyFab } from '../comps/MyFab';
import { VirtualizedTable, getComparator, stableSort } from '../comps/MyTable';
import { useWhyDidYouUpdate } from '../hooks/whyDidYouUpdate';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useGlobalAppState } from '../state/myGlobalContext';

export default function LinkedPropertiesBrands(props) {

    console.log('LinkedPropertiesBrands props:', props);

    useWhyDidYouUpdate('LinkedPropertiesBrands', props)

    const { location } = props;

    const {
        state: { rules, isLoading,
            // complete_map 
        },
        // load_LOVs, 
        loadSetupsFromBackend,
        // load_stayTypes_LOV, set_lastError
    } = useGlobalAppState();

    const [items, set_items] = useState([]);
    const [toOpen_CreateLinkDialog, set_toOpen_CreateLinkDialog] = useState(false);
    const [toOpen_EditLinkDialog, set_toOpen_EditLinkDialog] = useState(false);
    const [editLinkItem, set_editLinkItem] = useState(null);

    // const [itemsSorted, set_itemsSorted] = useState([]);
    // sorting
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('resort_desc');

    const handleRequestSort = (event, property) => {
        console.log('handleRequestSort:', property);

        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    }

    const loadSetupsFromBackend_cb = useCallback(loadSetupsFromBackend, []);
    // const load_LOVs_cb = useCallback(load_LOVs, [])
    // const load_stayTypes_LOV_cb = useCallback(load_stayTypes_LOV, []);

    useEffect(() => {
        loadSetupsFromBackend_cb()
        // load_LOVs_cb()
        // load_stayTypes_LOV_cb()
        return () => { }
    }, [loadSetupsFromBackend_cb]);
    // }, [loadSetupsFromBackend_cb, load_LOVs_cb, load_stayTypes_LOV_cb]);

    useEffect(() => {
        console.log('LinkedPropertiesBrands componentDidMount');
        async function runOnce() {

            console.log('LinkedPropertiesBrands RUNNED');

            if (location && location.selectedRule) {
                const calcId = location.selectedRule.calc_id;
                // const items = rules.filter(v => v.calc_id === calcId);
                const items = rules.filter(v => v.id && (v.calc_id === calcId));
                if (items) {
                    const sorted = stableSort(items, getComparator(order, orderBy))
                    set_items(sorted)
                } else {
                    set_items([])
                }
            } else {
                set_items([])
            }
        }

        runOnce();
        return () => {
            console.log('LinkedPropertiesBrands componentWillUnmount');
        }
    }, [location, order, orderBy, rules]);

    const onRowClick = (e) => {
        console.log('onRowClick', e);
    }

    const onRowDoubleClick = (e) => {
        console.log('onRowDoubleClick', e);
        onEditItem(e.index)
    }

    const onEditItem = (rowIndex) => {
        // console.log('onEditItem', rowIndex);
        const itemToEdit = items[rowIndex];
        console.log('onEditItem', rowIndex, itemToEdit);

        //
        set_editLinkItem(itemToEdit)
        set_toOpen_EditLinkDialog(true)
    }

    const handleClose_CreateLink = (toReload) => {
        set_toOpen_CreateLinkDialog(false)
        if (toReload) {
            loadSetupsFromBackend()
        }
    };
    const handleClose_CreateLinkDialog_cb = useCallback(handleClose_CreateLink, []);

    const handleClose_EditLinkDialog = (toReload = false) => {
        set_toOpen_EditLinkDialog(false)
        set_editLinkItem(null)
        if (toReload) {
            // loadLinkFromBackend(); NO ENDPOINT FOR THIS
            loadSetupsFromBackend()
        }
    };
    const handleClose_EditLinkDialog_cb = useCallback(handleClose_EditLinkDialog, []);


    if (!props.location.selectedRule) {
        return <p>Rule must be selected</p>
    }



    /* selectedRule:
    YEARLY_YN: ""
    ID: "22354"
    CALC_ID: "4"
    RESORT: ""
    RESORT_DESC: ""
    CHAIN: "AF"
    CHAIN_DESC: "Al Faisaliah"
    DESCRIPTION: "ENROLMENT"
    FEE_TYPE_DESC: "FLAT"
    FEE_TYPE: "FLAT"
    VIP_YN: ""
    AMT_DESC: "$0"
    AMT: "0"
    CAP_REVENUE: ""
    CAP_NIGHTS: ""
    CURRENCY: "USD"
    BASE: "ROOM_REVENUE"
    STAY_TYPE: "ENROLMENT"
    BEGIN_DATE: "01-JAN-20"
    END_DATE: "31-DEC-20"
    MONTHLY_YN: ""
    QUARTERLY_YN: "" */

    const rule = props.location.selectedRule;

    console.log('rule:', rule);

    const amountpreview = () => {
        if(rule.fee_type === "PCT") {
            if(rule.discovery1_yn && rule.discovery1_yn === 'Y'){
                return rule.amt;
            } else{
                return rule.amt_rooms + '% ROOMS / ' + rule.amt_non_rooms + '% NON ROOMS';
            }
        } 
        return rule.amt;
    }


    return (
        <div className="LinkedPropertiesBrands">

            {/* <h2>LINKED PROPERTIES/BRANDS</h2> */}
            <Typography variant="h2" color="primary">LINKED PROPERTIES/BRANDS</Typography>

            <div style={{ height: '12px' }}>{isLoading && <LinearProgress />}</div>

            <Typography
                color="primary">Opened rule with CALC_ID: {rule.calc_id}</Typography>

            {/*
            <Grid container spacing={3}>
    
                 <Grid item xs={2}>
                    <Paper className={classes.paper}>
                        <p>RULE NAME:</p>
                        <p>FEE TYPE:</p>
                    </Paper>
                </Grid>
                <Grid item xs={4}>
                    <Paper className={classes.paper}>
                        <p>{rule.DESCRIPTION} </p>
                        <p>{rule.FEE_TYPE_DESC}</p>
                    </Paper>
                </Grid>


                <Grid item xs={4}>
                    <Paper className={classes.paper}>
                        <p>BASED ON:</p>
                        <p>AMOUNT: </p>
                        <p>VIP ONLY:</p>
                    </Paper>
                </Grid>
                <Grid item xs={2}>
                    <Paper className={classes.paper}>
                        <p>{rule.BASE}</p>
                        <p>{rule.AMT_DESC}</p>
                        <p>{rule.VIP_YN}</p>
                    </Paper>
                </Grid>
            </Grid> */}

            <div className="topData">

                <div className="topData-left">
                    <p>RULE NAME: <b>{rule.description}</b></p>
                    {/* <p>FEE TYPE: <b>{rule.FEE_TYPE_DESC}</b></p> */}
                    <p>FEE TYPE: <b>{rule.fee_type}</b></p>
                </div>
                <div className="topData-right">
                    {/* <p>BASED ON: <b>{rule.base}</b></p> */}
                    {/* <p>AMOUNT: <b>{rule.amt_desc}</b></p> */}
                    {/* <p>AMOUNT: <b>{rule.amt}</b></p> */}
                    <p>AMOUNT: <b>{amountpreview()}</b></p>
                    <p>VIP ONLY: <b>{rule.vip_yn}</b></p>
                </div>
            </div>

            <div style={{ height: '100%', width: '100%', padding: 12, boxSizing: 'border-box' }}>

                <VirtualizedTable
                    rowCount={items ? items.length : 0}
                    rowGetter={({ index }) => items[index]}
                    onRowClick={onRowClick}
                    onEditItem={onEditItem}
                    onRowDoubleClick={onRowDoubleClick}

                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}

                    columns={[
                        {
                            width: 130,
                            label: 'NAME',
                            dataKey: 'resort_desc',
                            renderCustom: (item) => {
                                if (!item)
                                    return '';
                                if (!item.resort_desc || item.resort_desc === '')
                                    return item.chain_desc;
                                return item.resort_desc;
                            }
                        },
                        {
                            width: 120,
                            label: 'CODE',
                            dataKey: 'resort',
                            numeric: true,
                            renderCustom: (item) => {
                                if (!item)
                                    return '';
                                if (!item.resort || item.resort === '')
                                    return item.chain;
                                return item.resort;
                            }
                        },
                        {
                            width: 260,
                            label: 'STAY TYPE',
                            dataKey: 'stay_type',
                            numeric: true,
                        },
                        {
                            width: 130,
                            label: 'FROM DATE',
                            dataKey: 'begin_date',
                        },
                        {
                            width: 130,
                            label: 'TO DATE',
                            dataKey: 'end_date',
                            // numeric: true,
                        },
                        /* {
                            width: 130,
                            label: 'UPDATE DATE',
                            dataKey: 'update_date',
                            // numeric: true,
                        }, */
                        {
                            width: 80,
                            label: '',
                            dataKey: 'actionButtons',
                            numeric: true,
                        },
                    ]}
                />
            </div>

            <div className="footer">
                {/* <MyFabBack onClick={e => on_go_back()} /> */}
                <Fab
                    style={{
                        background: 'linear-gradient(45deg,#bec6c0 0,#99a49c 100%)',
                        color: '#fff'
                    }}
                    size="medium"
                    component={Link}
                    to={{
                        pathname: "/billing-setup",
                    }}
                >
                    <ArrowBackIcon />
                </Fab>

                <Box m={1} width="100%" />

                <MyFab onClick={e => set_toOpen_CreateLinkDialog(true)} />
            </div>

            <CreateLinkDialog
                open={toOpen_CreateLinkDialog}
                onClose={handleClose_CreateLinkDialog_cb}
                selectedRule={rule}
            />

            <EditLinkDialog
                open={toOpen_EditLinkDialog}
                onClose={handleClose_EditLinkDialog_cb}
                editLinkItem={editLinkItem}
                selectedRule={rule}
            />

        </div>
    );
}
