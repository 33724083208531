import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import React from "react";
import { MyFabCancel } from "../comps/MyFab";
export default function AlertDialog(props) {
  // console.log("AlertDialog render, props:", props);
  //   useWhyDidYouUpdate("EditBrandDialog", props);
  const { open, onClose, title } = props;

  return (
    // <Dialog open={open && Boolean(title !== "")} onClose={() => onClose()}>
    <Dialog open={open} onClose={() => onClose()}>
      <DialogTitle disableTypography>
        <Typography variant="h5" color="primary">
          EDIT RULE (id: {title})
        </Typography>
      </DialogTitle>

      <DialogContent>
        <Typography>
          RULES BEFORE DISCOVERY 2.0 CAN NO LONGER BE EDITED.
        </Typography>
      </DialogContent>

      <DialogActions>
        <MyFabCancel onClick={() => onClose()} />
      </DialogActions>
    </Dialog>
  );
}
