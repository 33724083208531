import { Box, Checkbox, Fab, FormControl, FormControlLabel, Input, InputLabel, LinearProgress, MenuItem, Select, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ErrorsAlert } from '../comps/ErrorsAlert';
import { MyFab } from '../comps/MyFab';
import { getComparator, stableSort, VirtualizedTable } from '../comps/MyTable';
import AlertDialog from '../dialogs/AlertDialog';
import CreateRuleDialog from '../dialogs/rule/CreateRuleDialog';
import EditRuleDialog from '../dialogs/rule/EditRuleDialog';
import { useWhyDidYouUpdate } from '../hooks/whyDidYouUpdate';
import { useGlobalAppState } from '../state/myGlobalContext';
import { groupArrayBy } from '../utils/utils';

const useStyles = makeStyles(theme => ({
    formControlFilter: {
        margin: theme.spacing(1, 0),
        // width: '100%',
        width: 220,
        minWidth: 140,
    },
    formControlFilter_date: {
        margin: theme.spacing(1, 0),
        width: 140,
        minWidth: 140,
        // minWidth: 80,
        textTransform: 'uppercase',
    },
    formControlFilter_checkbox: {
        // margin: theme.spacing(1, 0),
        // width: '100%',
        // width: 220,
        minWidth: 140,

        marginBottom: 0,
        alignSelf: 'flex-end',
    },

}));

export default function BillingSetup(props) {

    console.log('BillingSetup props:', props);

    useWhyDidYouUpdate('BillingSetup', props)

    const classes = useStyles();
    const {
        state: { brands_LOV, rules, isLoading, stayTypes_LOV, lastError,
            // complete_map 
        },
        load_LOVs, loadSetupsFromBackend, load_stayTypes_LOV, set_lastError
    } = useGlobalAppState();
    const [itemsFiltered, set_itemsFiltered] = useState([]);
    const [selectedRowIndex, set_selectedRowIndex] = useState(-1);

    const [toOpen_CreateRuleDialog, set_toOpen_CreateRuleDialog] = useState(false);
    const [toOpen_EditRuleDialog, set_toOpen_EditRuleDialog] = useState(false);

    const [toOpen_nonEditableDialog, set_toOpen_nonEditableDialog] = useState(false);

    const [editRuleItem, set_editRuleItem] = useState(null);
    const [filterValues, set_filterValues] = useState({
        feeType: '',
        // type: '',
        // chain: '',
        chain_code_label: '',
        stayType: '',
        dateFrom: null,
        dateTo: null,

        onlyCurrentlyActive: false,
        onlyUnlinkedRules: false,

        toDisplayDiscovery: false,
    });

    // sorting
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('chain_desc');

    const handleRequestSort = (event, property) => {
        console.log('handleRequestSort:', property);

        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    }

    const loadSetupsFromBackend_cb = useCallback(loadSetupsFromBackend, []);
    const load_LOVs_cb = useCallback(load_LOVs, [])
    const load_stayTypes_LOV_cb = useCallback(load_stayTypes_LOV, []);

    useEffect(() => {
        loadSetupsFromBackend_cb()
        load_LOVs_cb()
        load_stayTypes_LOV_cb()
        return () => { }
    }, [loadSetupsFromBackend_cb, load_LOVs_cb, load_stayTypes_LOV_cb]);

    useEffect(() => {
        function applyFilters() {
            // console.log('applyFilters()');
            // let res = [...rules];
            // group by:
            // CALC_ID, CHAIN, FEE_TYPE_DESC, BASE, BEGIN_DATE, END_DATE
            var result = groupArrayBy(rules, function (item) {
                return [item.calc_id, item.chain, item.fee_type_desc, item.base, item.begin_date, item.end_date];
            });
            console.log('applyFilters groupArrayBy:', result);

            let res = result.map(v => { return v[0] });
            console.log('applyFilters groupArrayBy res:', res);

            //
            if (filterValues.feeType !== '') {
                // res = res.filter(item => item.fee_type_desc === filterValues.feeType)
                res = res.filter(item => item.fee_type === filterValues.feeType)
            }
            if (filterValues.chain_code_label !== '') {
                // res = res.filter(item => item.chain_code === filterValues.chain_code)
                res = res.filter(item => item.chain_desc === filterValues.chain_code_label)
            }
            if (filterValues.stayType !== '') {
                res = res.filter(item => item.stay_type === filterValues.stayType)
            }
            if (filterValues.dateFrom) {
                res = res.filter(item => {
                    const fromDate = moment(filterValues.dateFrom);
                    const myMoment = moment(item.begin_date, 'DD-MMM-YYYY');

                    return myMoment.isSameOrAfter(fromDate, 'day');
                })
            }
            if (filterValues.dateTo) {
                res = res.filter(item => {
                    const toDate = moment(filterValues.dateTo);
                    const myMoment = moment(item.end_date, 'DD-MMM-YYYY');

                    return myMoment.isSameOrBefore(toDate, 'day');
                })
            }

            if (filterValues.onlyCurrentlyActive) {
                res = res.filter(item => {
                    const fromDate = moment(item.begin_date, 'DD-MMM-YYYY');
                    const toDate = moment(item.end_date, 'DD-MMM-YYYY');
                    return moment().isBetween(fromDate, toDate, 'day');
                })
            }
            console.log('filterValues.onlyUnlinkedRules:', filterValues.onlyUnlinkedRules);
            if (filterValues.onlyUnlinkedRules) {
                res = res.filter(item => item.id === null)
            }

            // if false exclude from results
            if (!filterValues.toDisplayDiscovery) {
                res = res.filter(item => item.discovery1_yn !== 'Y')
            } 

            console.log('applyFilters before sort:', res);

            const sorted = stableSort(res, getComparator(order, orderBy))

            console.log('applyFilters final:', sorted);

            // addNotIfEditable
            sorted.forEach(item => {
                // this is only frontend prop
                item.__preventEditing = item.discovery1_yn === 'Y'
            });

            set_itemsFiltered(sorted)

            if (sorted.length > 0) {
                set_selectedRowIndex(0)
            }
        }
        applyFilters();

        return () => { }
        //}, [items, filterValues]);
    }, [rules, filterValues, order, orderBy]);

    const handleChangeFilterVal = event => {
        const { name, value } = event.target; // <-- moved outside asynchronous context
        console.log('set_filterValues name value:', name, value);
        set_filterValues(prev => ({ ...prev, [name]: value }))
    };

    const handleChangeFilterVal_date = (d, inputName) => {
        console.log('handleChangeFilterVal_date a,b,c:', d, inputName);
        set_filterValues(prev => ({ ...prev, [inputName]: d }))
    }

    const handleCloseCreateARuleDialog = (toReload = false) => {
        set_toOpen_CreateRuleDialog(false)
        if (toReload) {
            loadSetupsFromBackend()
        }
    };
    const handleCloseCreateARuleDialog_cb = useCallback(handleCloseCreateARuleDialog, []);

    const onRowClick = (e) => {
        // console.log('onRowClick', e);
        // event: Class, index: 0, rowData: {…}}
        set_selectedRowIndex(e.index)
    }

    const onRowDoubleClick = (e) => {
        console.log('onRowDoubleClick', e);
        onEditItem(e.index)
    }

    const onEditItem = (rowIndex) => {
        // console.log('onEditItem', rowIndex);
        const itemToEdit = itemsFiltered[rowIndex];

        console.log('onEditItem', rowIndex, itemToEdit);

        // prevent editing old items
        if (itemToEdit.discovery1_yn === 'Y') {
            set_editRuleItem(itemToEdit) // just to get id to know which item is not editable ?
            set_toOpen_nonEditableDialog(true);
            return;
        }

        //
        set_editRuleItem(itemToEdit)
        set_toOpen_EditRuleDialog(true)
    }

    const handleClose_EditRuleDialog = (toReload = false) => {
        set_toOpen_EditRuleDialog(false)
        set_editRuleItem(null)
        if (toReload) {
            loadSetupsFromBackend()
        }
    };
    const handleClose_EditRuleDialog_cb = useCallback(handleClose_EditRuleDialog, []);

    const handleClose_nonEditableDialog = () => {
        set_toOpen_nonEditableDialog(false)
        set_editRuleItem(null)
    };
    const handleClose_nonEditableDialog_cb = useCallback(handleClose_nonEditableDialog, []);

    const getRowLinkedItems = (row) => {
        return rules
            .filter(rule => rule.calc_id === row.calc_id)
            .filter(rule => {
                // console.log('getRowLinkedItems rule:', rule);
                return rule.resort !== null && rule.resort !== ''
                // return rule.id && rule.resort !== null && rule.resort !== '';
            })
    }

    const selectedRow = itemsFiltered[selectedRowIndex];
    const selectedRowLinkedItems = selectedRow ? getRowLinkedItems(selectedRow) : [];
    // exclude rules with not created links yet
    // const selectedRowLinkedItems_onlyCreatedOnes = selectedRowLinkedItems.filter(rule => rule.id);

    // const hasResortValue = selectedRow && selectedRow.resort && selectedRow.resort.length > 0;
    // const linkedToCount = hasResortValue ? selectedRowLinkedItems.filter(rule => rule.id).length : 0;
    const linkedToCount = selectedRowLinkedItems.filter(rule => rule.id).length;
    const haslinks = selectedRow && (selectedRow.id !== null);

    console.log('selectedRow:', selectedRow);
    console.log('selectedRowLinkedItems:', selectedRowLinkedItems);
    console.log('linkedToCount:', linkedToCount);

    return (
        <div className="BillingSetup">

            <Typography variant="h2" color="primary">BILLING SETUP</Typography>

            <div style={{ height: '12px' }}>{isLoading && <LinearProgress />}</div>

            <ErrorsAlert lastError={lastError} set_lastError={set_lastError} />

            <div className="filtersDiv">

                <FormControl className={classes.formControlFilter} >
                    <InputLabel>FEE TYPE</InputLabel>
                    <Select
                        value={filterValues.feeType}
                        onChange={handleChangeFilterVal}
                        input={<Input name="feeType" />}
                    >
                        <MenuItem value=""><em>None</em></MenuItem>
                        <MenuItem value="PCT">PERCENTAGE</MenuItem>
                        {/* <MenuItem value="PERCENTAGE">PERCENTAGE</MenuItem> */}
                        <MenuItem value="FLAT">FLAT</MenuItem>
                        <MenuItem value="FIXED_DAILY">FIXED DAILY</MenuItem>
                        <MenuItem value="FIXED_MONTHLY">FIXED MONTHLY</MenuItem>
                        <MenuItem value="FIXED_QUARTERLY">FIXED QUARTERLY</MenuItem>
                        <MenuItem value="FIXED_YEARLY">FIXED YEARLY</MenuItem>
                    </Select>
                </FormControl>

                <Box component="span" m={1} />

                <FormControl className={classes.formControlFilter} >
                    <InputLabel>BRAND</InputLabel>
                    <Select
                        value={filterValues.chain_code_label}
                        onChange={handleChangeFilterVal}
                        input={<Input name="chain_code_label" />}
                    >
                        <MenuItem value=""><em>None</em></MenuItem>
                        {
                            brands_LOV.map((b, i) => {
                                return <MenuItem value={b.label} key={i}>{b.label}</MenuItem>
                            })
                        }
                    </Select>
                </FormControl>


                <Box component="span" m={1} />

                <FormControl className={classes.formControlFilter} >
                    <InputLabel>STAY TYPE</InputLabel>
                    <Select
                        value={filterValues.stayType}
                        onChange={handleChangeFilterVal}
                        input={<Input name="stayType" />}
                    >
                        <MenuItem value=""><em>None</em></MenuItem>
                        {
                            stayTypes_LOV.map((b, i) => {
                                return <MenuItem value={b.value} key={i}>{b.label}</MenuItem>
                            })
                        }
                    </Select>
                </FormControl>

                <Box component="span" m={1} />

                <FormControl className={classes.formControlFilter_date} >
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="dd-MMM-yy"
                        // margin="normal"
                        name="dateFrom"
                        label="FROM DATE"
                        value={filterValues.dateFrom}
                        // onChange={handleChangeFilterVal_date}
                        onChange={date => handleChangeFilterVal_date(date, 'dateFrom')}
                        KeyboardButtonProps={{
                            'aria-label': 'change date from',
                        }}

                        maskChar="-"
                        inputProps={{
                            style: {
                                textTransform: "uppercase",
                            }
                        }}
                        refuse="/[^\d]+/gi"
                    />
                </FormControl>

                <Box component="span" m={1} />

                <FormControl className={classes.formControlFilter_date} >
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="dd-MMM-yy"
                        // margin="normal"
                        name="dateTo"
                        label="TO DATE"
                        /* labelFunc={
                            (date, invalidLabel) => {

                                //console.log('date, invalidLabel:', date, invalidLabel);
                                if (invalidLabel)
                                    return invalidLabel;

                                if (date) {
                                    const string = format(date, 'dd-MMM-yy').toUpperCase();
                                    console.log('ret string:', string);
                                    return string; // || '';
                                }
                            }
                        } */
                        value={filterValues.dateTo}
                        // value={String(filterValues.dateTo).toUpperCase()}
                        onChange={date => handleChangeFilterVal_date(date, 'dateTo')}
                        KeyboardButtonProps={{
                            'aria-label': 'change date to',
                        }}

                        maskChar="-"
                        inputProps={{
                            style: {
                                textTransform: "uppercase",
                            }
                        }}
                        refuse="/[^\d]+/gi"
                    />
                </FormControl>

                <Box component="span" m={1} />

                <FormControl className={classes.formControlFilter_checkbox}>
                    <FormControlLabel
                        label={<Typography color="primary">CURRENTLY ACTIVE</Typography>}
                        control={
                            <Checkbox
                                value={filterValues.onlyCurrentlyActive}
                                onChange={e => {
                                    set_filterValues(prev =>
                                        ({ ...prev, onlyCurrentlyActive: !prev.onlyCurrentlyActive })
                                    )
                                }
                                }
                                color="primary"
                            />
                        }
                    />
                </FormControl>

                <Box component="span" m={1} />

                <FormControl className={classes.formControlFilter_checkbox}>
                    <FormControlLabel
                        label={<Typography color="primary">UNLINKED RULES</Typography>}
                        control={
                            <Checkbox
                                value={filterValues.onlyUnlinkedRules}
                                onChange={e => {
                                    set_filterValues(prev =>
                                        ({ ...prev, onlyUnlinkedRules: !prev.onlyUnlinkedRules })
                                    )
                                }
                                }
                                color="primary"
                            />
                        }
                    />
                </FormControl>

                <Box component="span" m={1} />

                <FormControl className={classes.formControlFilter_checkbox}>
                    <FormControlLabel
                        label={<Typography color="primary">DISPLAY DISCOVERY 1.0</Typography>}
                        control={
                            <Checkbox
                                value={filterValues.toDisplayDiscovery}
                                onChange={e => {
                                    set_filterValues(prev =>
                                        ({ ...prev, toDisplayDiscovery: !prev.toDisplayDiscovery })
                                    )
                                }
                                }
                                color="primary"
                            />
                        }
                    />
                </FormControl>
            </div>

            <div style={{ height: '100%', width: '100%', padding: 12, boxSizing: 'border-box' }}>

                <VirtualizedTable
                    rowCount={itemsFiltered.length}
                    rowGetter={({ index }) => itemsFiltered[index]}

                    onRowClick={onRowClick}
                    onEditItem={onEditItem}
                    onRowDoubleClick={onRowDoubleClick}
                    selectedRowIndex={selectedRowIndex}

                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}

                    columns={[
                        {
                            width: 130,
                            label: 'BRAND',
                            dataKey: 'chain_desc',
                        },
                        {
                            width: 130,
                            label: 'DESCRIPTION',
                            dataKey: 'description',
                        },
                        {
                            width: 100,
                            label: 'FEE TYPE',
                            dataKey: 'fee_type',
                        },
                        {
                            width: 130,
                            label: 'STAY TYPE',
                            dataKey: 'stay_type',
                            renderCustom: (item) => {
                                if (!item)
                                    return '';
                                if (item.vip_yn === 'Y') {
                                    return item.stay_type + ' VIP ONLY';
                                }
                                return item.stay_type;
                            }
                        },
                        /* {
                            width: 130,
                            label: 'BASED ON',
                            dataKey: 'NAME',
                        }, */
                        {
                            width: 200,
                            label: 'AMOUNT',
                            dataKey: 'amt',
                            renderCustom: (item) => {
                                // console.log('renderCustom: item:', item);

                                if(item.fee_type === "PCT") {
                                    
                                    if(item.discovery1_yn && item.discovery1_yn === 'Y'){
                                        return item.amt;
                                    } else{
                                        return item.amt_rooms + '% ROOMS / ' + item.amt_non_rooms + '% NON ROOMS';
                                    }
                                } 
                                // else {
                                //     return item.amt;
                                // }
                                return item.amt;
                            }
                        },
                        {
                            width: 100,
                            label: 'CURRENCY',
                            dataKey: 'currency',
                        },
                        {
                            width: 100,
                            label: 'FROM DATE',
                            dataKey: 'begin_date',
                        },
                        {
                            width: 100,
                            label: 'TO DATE',
                            dataKey: 'end_date',
                            // numeric: true,
                        },
                        {
                            width: 80,
                            minWidth: 50,
                            label: '',
                            dataKey: 'actionButtons',
                        },
                    ]}
                />
            </div>

            <div className="footer">
                {
                    selectedRow && selectedRowIndex > -1 && 
                    // haslinks &&
                    // linkedToCount > 0 &&
                    <Fab
                        style={{
                            background: 'linear-gradient(45deg,#bec6c0 0,#99a49c 100%)',
                            color: '#fff'
                        }}
                        size="medium"
                        component={Link}
                        to={{
                            pathname: "/linked-properties-brands",
                            selectedRule: selectedRow,
                            items: selectedRowLinkedItems
                        }}
                    >
                        <ListAltOutlinedIcon />
                    </Fab>
                }

                {
                    selectedRow && selectedRowIndex > -1 &&
                    <p style={{ textTransform: 'uppercase' }}>
                        {
                            // linkedToCount === 0 &&
                            !haslinks &&
                            <span>RULE IS NOT LINKED YET</span>
                        }
                        {
                            // haslinks && linkedToCount > 0 &&
                            haslinks &&
                            <span>
                                LINKED TO {linkedToCount > 0 ? linkedToCount + ' PROPERTIES OF' : ''} BRAND {selectedRow.chain_desc}
                                {/* <br />NOT LINKED TO {selectedRow.no_rules_defined} */}
                            </span>
                        }
                    </p>
                }

                <MyFab onClick={e => set_toOpen_CreateRuleDialog(true)} />
            </div>

            <CreateRuleDialog
                open={toOpen_CreateRuleDialog}
                onClose={handleCloseCreateARuleDialog_cb}
            />

            <EditRuleDialog
                open={toOpen_EditRuleDialog}
                onClose={handleClose_EditRuleDialog_cb}
                editRuleItem={editRuleItem}
            />
            <AlertDialog
                open={toOpen_nonEditableDialog}
                onClose={handleClose_nonEditableDialog_cb}
                editRuleItem={editRuleItem}
                title={editRuleItem?.id ?? ''}
            />

        </div>
    );
}
