import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import { AutoSizer, Column, Table } from 'react-virtualized';
import { TableSortLabel, IconButton, SvgIcon } from '@material-ui/core';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { myTableStyles } from './MyTableStyles';

function MyEditOffIcon(props) {
    return (
        <SvgIcon {...props}>
            <path d="M0 0h24v24H0zm0 0h24v24H0z" fill="none" />
            <path d="M12.126 8.125l1.937-1.937 3.747 3.747-1.937 1.938zM20.71 5.63l-2.34-2.34a1 1 0 0 0-1.41 0l-1.83 1.83 3.75 3.75L20.71 7a1 1 0 0 0 0-1.37zM2 5l6.63 6.63L3 17.25V21h3.75l5.63-5.62L18 21l2-2L4 3 2 5z" />
        </SvgIcon>
    );
}

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

export function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

class MuiVirtualizedTable extends React.PureComponent {
    static defaultProps = {
        headerHeight: 48,
        rowHeight: 48,
    };

    getRowClassName = ({ index }) => {
        // console.log('getRowClassName index:', index);
        const { classes, onRowClick, selectedRowIndex } = this.props;

        return clsx(
            classes.tableRow,
            classes.flexContainer,
            {
                [classes.tableRowHeader]: index === -1,
                [classes.tableRowHover]: index !== -1 && onRowClick != null,
                // adding selectedRowIndex
                [classes.tableRowSelected]: index !== -1 && selectedRowIndex === index,
            },
            // {
            //     [classes.tableRowHeader]: index === -1,
            // }
        );
    };

    cellRenderer = ({ cellData, columnData, dataKey, rowData, columnIndex, rowIndex }) => {
        const { columns, classes, rowHeight, onRowClick, onEditItem } = this.props;
        // console.log('this.props:', this.props);

        /* const cellDataPreVal = columns[columnIndex].dataKey === 'actionButtons' 
        ? <>
        : cellData */
        //const dk = columns[columnIndex].dataKey;
        const isActionsCol = dataKey === 'actionButtons'
        // const isItemSelected = true; //this.isSelected(dk.);
        // const labelId = `enhanced-table-checkbox-${index}`;
        const currItem = columns[columnIndex];
        const customRender = currItem.renderCustom;

        return (
            <TableCell
                component="div"
                className={clsx(classes.tableCell, classes.flexContainer, {
                    [classes.noClick]: onRowClick == null,
                })}
                variant="body"
                style={{ height: rowHeight }}
                // align={(columnIndex != null && columns[columnIndex].numeric) || false ? 'right' : 'left'}
                align={isActionsCol ? "right" : "left"}
            >
                {
                    !isActionsCol && !customRender && Array.isArray(cellData) && cellData.join(', ')
                }
                {
                    !isActionsCol && !customRender && !Array.isArray(cellData) && cellData
                }
                {
                    !isActionsCol && customRender && customRender(rowData, cellData, dataKey)
                }
                {
                    isActionsCol && (
                        <div>
                            <IconButton
                                size="small"
                                // brand? shoudnt this be abstract? so just Edit?
                                title="Edit"
                                onClick={(e) => onEditItem(rowIndex)}
                            >
                            {
                                rowData.__preventEditing === true &&
                                <MyEditOffIcon />
                            }
                            {
                                !rowData.__preventEditing &&
                                <EditOutlinedIcon />
                            }
                            </IconButton>
                        </div>
                    )
                }
            </TableCell>
        );
    };
    headerRenderer = ({ label, columnIndex, dataKey }) => {
        const { headerHeight, columns, classes, onRequestSort, order, orderBy } = this.props;
        // console.log('headerRenderer:', label, order, orderBy, dataKey, orderBy === dataKey);
        const dk = columns[columnIndex].dataKey;
        const isActionsCol = dk === 'actionButtons'

        const createSortHandler = (property) => (event) => {
            console.log('createSortHandler:', event, property);
            onRequestSort(event, property);
        };

        return (
            <TableCell
                component="div"
                // className={clsx(classes.tableCell, classes.flexContainer, classes.noClick)}
                className={clsx(
                    classes.tableCell, classes.flexContainer, classes.noClick,
                    classes.tableCellHeader
                )}
                variant="head"
                style={{ height: headerHeight }}
                // align={columns[columnIndex].numeric || false ? 'right' : 'left'}
                align={isActionsCol ? 'right' : 'left'}

                sortDirection={orderBy === dataKey ? order : false}
            >
                <TableSortLabel
                    disabled={isActionsCol}
                    active={orderBy === dataKey}
                    direction={orderBy === dataKey ? order : 'asc'}
                    onClick={createSortHandler(dataKey)}
                >
                    <span>{label}</span>
                    {orderBy === dataKey ? (
                        <span className={classes.visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </span>
                    ) : null}
                </TableSortLabel>
            </TableCell>
        );
    };

    render() {
        const { classes, columns, rowHeight, headerHeight, ...tableProps } = this.props;
        return (
            <AutoSizer>
                {({ height, width }) => (
                    <Table
                        height={height}
                        width={width}
                        rowHeight={rowHeight}
                        gridStyle={{
                            direction: 'inherit',
                        }}
                        headerHeight={headerHeight}
                        className={classes.table}
                        {...tableProps}
                        rowClassName={this.getRowClassName}

                        noRowsRenderer={() => {
                            return <div style={{
                                width, height,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontSize: 28
                            }}
                            >No data</div>
                        }}
                    >
                        {columns.map(({ dataKey, ...other }, index) => {
                            return (
                                <Column
                                    key={dataKey}
                                    headerRenderer={headerProps =>
                                        this.headerRenderer({
                                            ...headerProps,
                                            columnIndex: index,
                                            // dataKey
                                        })
                                    }
                                    className={classes.flexContainer}
                                    cellRenderer={this.cellRenderer}
                                    dataKey={dataKey}
                                    {...other}
                                    // width={100}
                                    // flexGrow={1}
                                    flexGrow={dataKey === 'actionButtons' ? 0 : 1}
                                />
                            );
                        })}
                    </Table>
                )}
            </AutoSizer>
        );
    }
}

MuiVirtualizedTable.propTypes = {
    classes: PropTypes.object.isRequired,
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            dataKey: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            numeric: PropTypes.bool,
            width: PropTypes.number.isRequired,
        }),
    ).isRequired,
    headerHeight: PropTypes.number,
    onRowClick: PropTypes.func,
    rowHeight: PropTypes.number,

    onEditItem: PropTypes.func,

    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
};

export const VirtualizedTable = withStyles(myTableStyles)(MuiVirtualizedTable);

/* export const MyVirtualizedTable = (props) => (
    <VirtualizedTable {...props} />
); */

/*

****** HOW TO USE IT ******

export default function ReactVirtualizedTable() {
    const onRowClick = (e) => {
        console.log('onRowClick', e);
    }
    return (
        <Paper style={{ height: '100%', width: '100%', padding: 24, boxSizing: 'border-box' }}>
            <VirtualizedTable
                rowCount={rows.length}
                rowGetter={({ index }) => rows[index]}
                onRowClick={onRowClick}
                columns={[
                    {
                        width: 200,
                        label: 'Dessert',
                        dataKey: 'dessert',
                    },
                    {
                        width: 120,
                        label: 'Calories\u00A0(g)',
                        dataKey: 'calories',
                        numeric: true,
                    },
                    {
                        width: 120,
                        label: 'Fat\u00A0(g)',
                        dataKey: 'fat',
                        numeric: true,
                    },
                    {
                        width: 120,
                        label: 'Carbs\u00A0(g)',
                        dataKey: 'carbs',
                        numeric: true,
                    },
                    {
                        width: 120,
                        label: 'Protein\u00A0(g)',
                        dataKey: 'protein',
                        numeric: true,
                    },
                    {
                        width: 120,
                        label: 'Actions Protein\u00A0(g)',
                        dataKey: 'actionButtons',
                        // numeric: true,
                    },
                ]}
            />
        </Paper>
    );
} */
