import Base64 from 'crypto-js/enc-base64';
import SHA256 from 'crypto-js/sha256';

function generateCodeVerifier() {
    var code_verifier = generateRandomString(50)
    return code_verifier;
}
function generateRandomString(length) {
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (var i = 0; i < length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
}
function generateCodeChallenge(code_verifier) {
    return base64URL(SHA256(code_verifier))
}
function base64URL(string) {
    return string.toString(Base64).replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_')
}
export function getCodes() {
    // from Okta docs:
    // Code verifier: Random URL-safe string with a minimum length of 43 characters.
    // Code challenge: Base64 URL-encoded SHA-256 hash of the code verifier.
    var code_verifier = generateCodeVerifier();
    var code_challenge = generateCodeChallenge(code_verifier)
    console.log('code_verifier', code_verifier);
    console.log('code_challenge', code_challenge);
    return {
        code_verifier,
        code_challenge
    }
}
