import { parseJwt, logoutRedirectURL } from './login';

// const restoredUserID = sessionStorage.getItem('userID')
//     ? parseInt(sessionStorage.getItem('userID'), 10) : undefined
const restoredUserName = sessionStorage.getItem('userName')
    ? sessionStorage.getItem('userName') : undefined
const restoredUserEmail = sessionStorage.getItem('userEmail')
    ? sessionStorage.getItem('userEmail') : undefined

export const AuthService = {
    userData: {
        name: restoredUserName ? restoredUserName : 'n/a',
        email: restoredUserEmail ? restoredUserEmail : 'n/a',
    },
    dataNEW: {},
    dataAPI_TokenData: {},
    // latestCodes: {},
    userToken: sessionStorage.getItem('token'),
    // for pkce
    codeVerifier: sessionStorage.getItem('codeVerifier'),

    idToken: sessionStorage.getItem('idtoken'),

    isAuthenticated() {
        return this.userToken && this.userToken.length > 0;
    },
    logout() {
        const logOutURL = logoutRedirectURL();
        console.log('logOutURL:', logOutURL);

        this.userData.name = undefined;
        this.userData.email = undefined;
        this.userToken = undefined;

        sessionStorage.removeItem("userID");
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("idtoken");

        window.location.href = logOutURL;
        // window.location = logOutURL
    },
    setUserProfile(data) {
        console.log('setUserProfile:', data);
        console.log('setUserProfile:', JSON.stringify(data, null, 2));

        this.dataNEW = data;
        /*
        access_token: "eyJhbGciOiJSUzI1NiIs...."
        expires_in: 1800
        refresh_expires_in: 10800
        refresh_token: "eyJhbGciOiJIUzI1NiIsInR..."
        token_type: "bearer"
        id_token: "eyJhbGciOiJSUzI1NiIsInR5cCIgOiA..."
        not-before-policy: 1576674200
        session_state: "efc52302-631a-40b6-a8c2-06232304933c"
        scope: "openid profile email"
        */

        // get user info like name, email
        const extracted_accessToken = parseJwt(data.access_token)
        const extracted_idToken = parseJwt(data.id_token)

        console.log('extracted_accessToken:', JSON.stringify(extracted_accessToken));
        console.log('extracted_idToken:', JSON.stringify(extracted_idToken));

        const usersData = {
            name: extracted_idToken.name,
            email: extracted_idToken.email,
            preferred_username: extracted_idToken.preferred_username,
            // "auth_time": 1584433501,
        }

        this.userData = usersData;
        
        this.userToken = data.access_token;

        // save token to session
        save_tokens_ToLocalStorage(data);
        save_UserData_ToLocalStorage(usersData);
    }
}

const save_tokens_ToLocalStorage = (data) => {
    if (data.access_token) {
        sessionStorage.setItem('token', data.access_token)
    }
    if (data.id_token) {
        sessionStorage.setItem('idtoken', data.id_token)
    }
}

const save_UserData_ToLocalStorage = (usersData) => {
    if (usersData.name) {
        sessionStorage.setItem('userName', usersData.name)
    } else {
        sessionStorage.removeItem('userName')
    }
    if (usersData.email) {
        sessionStorage.setItem('userEmail', usersData.email)
    } else {
        sessionStorage.removeItem('userEmail')
    }
}
