import {
  Button, CssBaseline, FormControlLabel, Menu, MenuItem, Paper, Tab, Tabs, ThemeProvider
} from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
// import { getBrandsData, getBrandsAndGroupsMap } from './api/api';
// import preval from 'preval.macro';
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, NavLink, Redirect, Switch as RouterSwitch, Route } from "react-router-dom";
import { AuthService } from './api/auth';
import { extractParamsFromLocation, loginRedirectURL_PKCE, loginWithCode_PKCE } from './api/login';
import './App.css';
import BillingSetup from './pages/BillingSetup';
import BrandConfiguration from './pages/BrandConfiguration';
import Exceptions from './pages/Exceptions';
import LinkedPropertiesBrands from './pages/LinkedPropertiesBrands';
import { defaultThemeDark, defaultThemeLight } from './theme/theme';
import { useWhyDidYouUpdate } from './hooks/whyDidYouUpdate';
import { ErrorsAlert } from './comps/ErrorsAlert';

// const dateTimeStamp = preval`module.exports = new Date().toLocaleString();`

const _isThemeLight = sessionStorage.getItem('isThemeLight')

function App(props) {

  useWhyDidYouUpdate('BrandConfiguration', props)
  /* const {
    // state: { isLoading },
    set_isLoading,
  } = useGlobalAppState() */

  const [lastError, set_lastError] = useState([]);
  // const [isLoading, set_isLoading] = useState(true);
  const [, set_isLoading] = useState(true);
  const [isAuthenticated, set_isAuthenticated] = useState(false); // test with true
  const [usermenu_anchorEl, set_usermenu_anchorEl] = useState(null);
  const [selectedTabValue, set_selectedTabValue] = useState(0);
  const [isThemeLight, set_isThemeLight] = useState(_isThemeLight === 'true');

  useEffect(() => {
    // console.log('set isThemeLight to sessionStorage:', isThemeLight);
    sessionStorage.setItem('isThemeLight', Boolean(isThemeLight))
  }, [isThemeLight])

  const set_correct_tab = () => {
    const pathname = window.location.pathname;

    switch (pathname) {
      case "/brand-configuration":
        set_selectedTabValue(0)
        break;
      case "/billing-setup":
        set_selectedTabValue(1)
        break;
      case "/linked-properties-brands":
        set_selectedTabValue(1)
        break;
      case "/exceptions":
        set_selectedTabValue(2)
        break;
      default:
        set_selectedTabValue(0)
        break;
    }
  }

  useEffect(() => {
    async function checkAuth() {
      console.log('checkAuth, isAuthenticated:', AuthService.isAuthenticated());

      if (AuthService.isAuthenticated()) {
        set_isAuthenticated(true);
        set_isLoading(false);
        set_correct_tab();
        return;
      }

      // check if it is a return after start login redirect
      const urlParams = extractParamsFromLocation();
      const code = urlParams.code;
      console.log('got urlParams:', urlParams)
      console.log('got code:', code)

      if (!code) {
        console.log('no code')

        // chek if error response
        // http://localhost:3000/?state=xyz
        // &error=access_denied&error_description=User+is+not+assigned+to+the+client+application.
        // const errorFromAuth = extractErrorFromLocation();

        if (urlParams.error) {
          set_lastError(prev => { return [...prev, urlParams] });
          set_isAuthenticated(false);
          set_isLoading(false);
        } else {
          const redirectURL = loginRedirectURL_PKCE();
          console.log('redirectURL:', redirectURL);
          window.location.href = redirectURL;
        }
      } else {
        loginWithCode_PKCE(code)
          .then((myRespResult) => {
            console.log('loginWithCode resp:', myRespResult)
            // const myRespResult = resp;
            // console.log('myRespResult:', myRespResult);

            // only if resp is from lambda?
            if (myRespResult.error) {
              console.error('loginWithCode resp error')
              //window.location = '/'
              set_isLoading(false);
              set_isAuthenticated(false);
              return;
            }

            console.log('LOGGED IN')

            // user is logged in
            AuthService.setUserProfile(myRespResult);
            // redirect
            window.location = '/'
            //props.history.push({pathname: '/',//search: '?arg=val'})
            // }
          })
          .catch((e) => {
            console.error('loginWithCode catched error:', e);
            set_isAuthenticated(false)
            set_lastError(prev => { return [...prev, e] });
            set_isLoading(false)
            // try again
            // setTimeout(() => { window.location = '/' }, 30000)
          })
      }
    }

    checkAuth();
    return () => { }
  }, []);

  const openUserMenu = event => {
    set_usermenu_anchorEl(event.currentTarget);
  };

  const usermenu_handleClose = (itemName) => {

    set_usermenu_anchorEl(null);

    if (itemName === 'logout') {
      AuthService.logout();
    }
  }

  const handleChangeTab = (event, newValue) => {
    console.log('handleChangeTab  newValue:', newValue);
    set_selectedTabValue(newValue);
  };

  const toggleDarkTheme = () => {
    set_isThemeLight(prev => {
      return !prev
    })
  };

  if (!isAuthenticated) {
    // return <p>Not authenticated.</p>
    return <p></p>;
  }

  console.log('App render');

  return (

    <ThemeProvider theme={isThemeLight ? defaultThemeLight : defaultThemeDark}>

      <CssBaseline />

      <div className="App">

        <div className="devDiv" style={{ fontSize: '12px' }}>
          {/* {
            isDevelopmentMode() &&
            <Fragment>
              <p>isDevEnv: {isDevelopmentMode() ? 'yes' : 'no'}</p>
              <p>isAuthenticated: {isAuthenticated ? 'yes' : 'no'}</p>
            </Fragment>
          } */}
          {/* <p style={{ display: 'none' }}>bt: {dateTimeStamp}</p> */}
        </div>

        <div className="userDiv">
          <Button onClick={openUserMenu} size="small">
            <span style={{ color: 'white' }}>
              {'> ' + AuthService.userData.name}
            </span>
          </Button>

          <Menu
            anchorEl={usermenu_anchorEl}
            keepMounted
            open={Boolean(usermenu_anchorEl)}
            onClose={usermenu_handleClose}
            anchorOrigin={{
              horizontal: 'right',
              vertical: 'bottom'
            }}
            getContentAnchorEl={null}
          >
            <MenuItem>
              <FormControlLabel
                control={<Switch size="small" checked={!isThemeLight}
                  onChange={() => toggleDarkTheme()}
                />}
                label="DARK THEME"
              />
            </MenuItem>
            <MenuItem onClick={e => usermenu_handleClose('logout')}>SIGN OUT</MenuItem>
          </Menu>
        </div>

        <Paper className="App-Paper">

          <Router>
            <div className="Security-div">
              <div>
                <Tabs
                  value={selectedTabValue}
                  onChange={handleChangeTab}
                  indicatorColor="primary"
                  textColor="primary"
                  centered
                  variant="fullWidth"
                >
                  <Tab label="Brands" to="/brand-configuration" component={NavLink} />
                  <Tab label="Billing Setup" to="/billing-setup" component={NavLink} />
                  <Tab label="Exceptions" to="/exceptions" component={NavLink} />
                </Tabs>
              </div>

              {/*   <div style={{ height: '12px' }}>
                {
                  isLoading && <LinearProgress />
                }
              </div> */}

              <ErrorsAlert lastError={lastError} set_lastError={set_lastError} />

              <RouterSwitch>

                <SecretRoute exact path="/">
                  {<Redirect to="/brand-configuration" />}
                </SecretRoute>

                <SecretRoute
                  path="/brand-configuration"
                  component={BrandConfiguration} />

                <SecretRoute
                  path="/billing-setup"
                  component={BillingSetup} />

                <SecretRoute
                  path="/linked-properties-brands"
                  component={LinkedPropertiesBrands} />

                <SecretRoute
                  path="/exceptions"
                  component={Exceptions} />
                <SecretRoute component={NoMatch} />

              </RouterSwitch>
            </div>
          </Router>

        </Paper>
      </div>
    </ThemeProvider>
  );
}

export default App;

function NoMatch({ location }) {
  return (
    <div>
      <h3>
        No match for <code>{location.pathname}</code>
      </h3>
    </div>
  );
}

export const SecretRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    AuthService.isAuthenticated() === true
      ? <Component {...props} {...rest} />
      : <Redirect to='/' />
    // : <Redirect to='/login' />
  )} />
);
