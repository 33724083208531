import { isDevelopmentMode } from "./utils/utils";

export const API_GATEWAY_URL = 'https://gfnj8qahmb.execute-api.eu-west-1.amazonaws.com';

// to avoid CORS error, set proxy in package.json, so in develop root API written there
// "proxy": "https://gha-soa-billing-api-v1.de-c1.cloudhub.io/api/gha/billing"
// export const DATA_API_URL = isDevelopmentMode() ? '' : 'https://gha-soa-billing-api-v1.de-c1.cloudhub.io/api/gha/billing';
export const DATA_API_URL = process.env.REACT_APP_BM_DATA_API_URL;

// frontend access
export const AUTH_CONFIG = {
    ISSUER_URI: 'https://gha.okta-emea.com',
    AUTH_PATH: '/oauth2/aus4nhdgknsNGdJFM0i7/v1/authorize',
    CLIENT_ID: '0oa4lt0dlsfwIMtFP0i7',
    REDIRECT_URI: window.location.origin + '', // window.location.origin + '/implicit/callback'
    LOGOUT_PATH: '/oauth2/aus4nhdgknsNGdJFM0i7/v1/logout',
    // to validate code: send code, receive accessToken from auth server
    // QUERY_ACCESSTOKEN_FOR_CODE_URL: API_GATEWAY_URL + '/getTokenFromCode',
    // for PKCE:
    TOKEN_PATH: '/oauth2/aus4nhdgknsNGdJFM0i7/v1/token',
    // userinfo_endpoint: '/oauth2/v1/userinfo',
    LOGOUT_REDIRECT_URI: isDevelopmentMode() ? 'http://localhost:3000' : process.env.REACT_APP_BM_LOGOUT_REDIRECT_URL,
}
