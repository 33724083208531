import { Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, Input, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField, Typography, LinearProgress, Box } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useGlobalAppState } from '../../state/myGlobalContext';
import { MyFabCancel, MyFabSubmit } from '../../comps/MyFab';
import { useWhyDidYouUpdate } from '../../hooks/whyDidYouUpdate';
import { AuthService } from '../../api/auth';
import { useStyles_dialogRules } from '../../theme/dialogStyles';
import { insertRule } from '../../api/api';
import { ErrorsAlert } from '../../comps/ErrorsAlert';

const INITIAL_STATE = {
    // PCT | FLAT | FIXED
    type: 'PCT',

    amt: 0,
    int_amt: 0,
    currency: 'USD',

    chain_code: '',
    description: '',

    // type percentage selected
    // ROOM_REVENUE | TOTAL_REVENUE
    base: 'ROOM_REVENUE',
    int_base: 'ROOM_REVENUE',


    is_vip: 'N',

    percentage_amount: '',

    amt_rooms: undefined,
    amt_non_rooms: undefined,
    int_amt_rooms: undefined,
    int_amt_non_rooms: undefined,


    // cap_revenue: '',   // default must be null '' unless specified (cannot be 0)
    // cap_nights: '',    // default must be null '' unless specified (cannot be 0)
    // percentage_isApplicableVIPonly: 'N',  // N is default
    pct_int_amt: '',

    flat_amount: '',
    flat_currency: 'USD', // for FLAT and FIXED rules submit the selected value (USD, EUR) otherwise submit null “”
    // flat_isApplicableVIPonly: 'N', // N is default
    flat_int_amt: '',

    fixedFee_frequency: 'monthly',
    is_daily: 'N',
    is_quarterly: 'N',
    is_monthly: 'Y',
    is_yearly: 'N',

    fixedFee_amount: '',
    fixedFee_currency: 'USD', // for FLAT and FIXED rules submit the selected value (USD, EUR) otherwise submit null “”
    fixedFee_int_amt: '',
    requires_trx_yn: 'N',

    invoicing_fee_yn: 'N',
    invoice_to_hq_yn: 'N',
}

export default function CreateRuleDialog(props) {

    useWhyDidYouUpdate('CreateRuleDialog', props)

    const classes = useStyles_dialogRules();
    const {
        state: { brands_LOV, isLoading_LOV }
    } = useGlobalAppState();
    const { open, onClose } = props;
    const [lastError, set_lastError] = useState([]);
    const [entryValues, set_entryValues] = useState(INITIAL_STATE);

    const [toShowValidationErrors, set_toShowValidationErrors] = useState(false);
    const [validationErrors, set_validationErrors] = useState({
        percentage_amount: '',
        pct_int_amt: '',

        amt_rooms: '',
        amt_non_rooms: '',
        int_amt_rooms: '',
        int_amt_non_rooms: '',
    });

    useEffect(() => {
        if (open) {
            // reset view
            set_entryValues(INITIAL_STATE)
            // load_LOVs_cb()
            // load_exceptionCodes_LOV_cb()
        }
    }, [open]);

    useEffect(() => {
        function validate() {
            if (entryValues.type === 'PCT') {
                // check perc amount
                set_validationErrors(prev => ({
                    ...prev,
                    // percentage_amount: entryValues.percentage_amount === '0' ? 'Incorrect entry.' : '',
                    // pct_int_amt: entryValues.pct_int_amt === '0' ? 'Incorrect entry.' : '',

                    amt_rooms: isAmtValid(entryValues.amt_rooms),
                    amt_non_rooms: isAmtValid(entryValues.amt_non_rooms),
                    int_amt_rooms: isAmtValid(entryValues.int_amt_rooms),
                    int_amt_non_rooms: isAmtValid(entryValues.int_amt_non_rooms),
                }))
            } else {
                // add next validation here

                set_validationErrors(prev => ({
                    ...prev,
                                        
                    amt_rooms: '',
                    amt_non_rooms: '',
                    int_amt_rooms: '',
                    int_amt_non_rooms: '',
                }))
            }
        }
        validate();

        return () => { }
    }, [entryValues]);

    const isAmtValid = amt => {
        console.log('isAmtValid = amt:', amt);

        if (isNaN(amt)){
            return 'Please enter comission amount'
        }

        if(amt >= 0 && amt <= 15) {
            console.log('isAmtValid = amt:', amt, 'YES');
            return ''
        }
        console.log('isAmtValid = amt:', amt, 'NO');
        return 'Please enter comission amount'
    }
    
    const handleChangeEntry = event => {
        const { name, value } = event.target;
        console.log('name value:', name, value);

        if (name === 'fixedFee_frequency') {
            set_entryValues(prev => ({
                ...prev,
                is_daily: value === 'daily' ? 'Y' : 'N',
                is_quarterly: value === 'quarterly' ? 'Y' : 'N',
                is_monthly: value === 'monthly' ? 'Y' : 'N',
                is_yearly: value === 'yearly' ? 'Y' : 'N',
            }))
        }

        if (name === 'percentage_amount' || name === 'flat_amount' || name === 'fixedFee_amount') {
            set_entryValues(prev => ({ ...prev, amt: Number(value) }))
        }

        if (name === 'pct_int_amt' || name === 'flat_int_amt' || name === 'fixedFee_int_amt') {
            set_entryValues(prev => ({ ...prev, int_amt: Number(value) }))
        }

        if (name === 'percentage_isApplicableVIPonly' || name === 'flat_isApplicableVIPonly') {
            set_entryValues(prev => ({ ...prev, is_vip: value ? 'Y' : 'N' }))
        }

        if (name === 'flat_currency' || name === 'fixedFee_currency') {
            set_entryValues(prev => ({ ...prev, currency: value }))
        }

        const parseTheseAsNumber = [
            'amt_rooms',
            'amt_non_rooms',
            'int_amt_rooms',
            'int_amt_non_rooms',
        ]
        if (parseTheseAsNumber.includes(name) ) {
            console.log('GOT NEW ONE:', name);
            if (value) {
                set_entryValues(prev => ({ ...prev, [name]: Number(value) }))
            } else{
                // deleted value
                set_entryValues(prev => ({ ...prev, [name]: undefined }))
            }
        } else {
            set_entryValues(prev => ({ ...prev, [name]: value }))
        }
    };

    const get_payload_preview = () => {

        if (entryValues.type === 'PCT') {

            /* PCT
            {
                "type": "PCT", -- based on radio button selected (possible values are PCT, FLAT, FIXED)
                "amt": 38,
                "cap_revenue": 5,  -- only applicable to PCT based on submitted field value
                "cap_nights": 22, -- only applicable to PCT based on submitted field value
                "insert_user": "daniel.voegele@nokantdo.com", 
                "base": "ROOM_REVENUE",
                "currency": "USD",  -- always default to USD, the field is not available on the dialogue for PCT
                "is_vip": "Y", --based on the selected value on the dialogue
                "is_quarterly": "N", -- always submit N on PCT, does not exist on PCT
                "is_monthly": "N", -- always submit N on PCT, does not exist on PCT 
                "is_yearly": "N", -- always submit N on PCT, does not exist on PCT 
                "chain_code": "GHA", -- chain_code from the selected property of brand-mapping (brand value becomes chain_code)
                "description": "TEST RULE DESCRIPTION",
                "requires_trx": "N",  -- always N for PCT, does not exist on PCT
                "invoicing_fee_yn": "N", -- always N for PCT, does not exist on PCT
                "invoice_to_hq_yn": "N", -- always N for PCT, does not exist on PCT
                "int_amt": 5, -- based on the submited value on the dialogue
                "int_base": "ROOM_REVENUE" -- based on the submited value on the dialogue
            }*/
            return {
                type: entryValues.type,
                // amt: entryValues.amt,
                // NO more used, but send fixed 0
                amt: 0,

                amt_rooms: entryValues.amt_rooms,
                amt_non_rooms: entryValues.amt_non_rooms,
                int_amt_rooms: entryValues.int_amt_rooms,
                int_amt_non_rooms: entryValues.int_amt_non_rooms,



                // cap_revenue: null, // Number(entryValues.cap_revenue),
                // cap_nights: null, // Number(entryValues.cap_nights),
                base: entryValues.base,
                currency: 'USD', // entryValues.currency,
                is_vip: entryValues.is_vip,

                is_daily: 'N',
                is_quarterly: 'N', // entryValues.is_quarterly,
                is_monthly: 'N', //entryValues.is_monthly,
                is_yearly: 'N', //entryValues.is_yearly,
                chain_code: entryValues.chain_code,
                description: entryValues.description,

                int_amt: entryValues.int_amt,
                int_base: entryValues.int_base,

                requires_trx: 'N', //entryValues.requires_trx_yn,
                /* invoicing_fee_yn: 'N', //entryValues.invoicing_fee_yn,
                invoice_to_hq_yn: 'N', //entryValues.invoice_to_hq_yn, */
                invoicing_fee_YN: 'N', //entryValues.invoicing_fee_yn,
                invoice_to_HQ_YN: 'N', //entryValues.invoice_to_hq_yn,

                insert_user: AuthService.userData.email,
            }
        }


        if (entryValues.type === 'FLAT') {

            /* "type": "FLAT", -- based on radio button selected (possible values are PCT, FLAT, FIXED)
            "amt": 200, -- based on submitted value on the dialogue
            "cap_revenue": , -- does not exist and should be null or not submitted
            "cap_nights": , -- does not exist and should be null or not submitted
            "insert_user": "daniel.voegele@nokantdo.com",
            "base": , -- does not exist and should be null or not submitted
            "currency": "USD", --based on selection on drop down, default to USD
            "is_vip": "Y", -- based on submitted value on the dialogue
            "is_quarterly": , -- does not exist and should be null or not submitted
            "is_monthly": , -- does not exist and should be null or not submitted
            "is_yearly": , -- does not exist and should be null or not submitted
            "chain_code": "GHA", -- chain_code from the selected property of brand-mapping (brand value becomes chain_code)
            "description": "TEST RULE DESCRIPTION",
            "requires_trx": , -- does not exist and should be null or not submitted
            "invoicing_fee_yn": , -- does not exist and should be null or not submitted
            "invoice_to_hq_yn": , -- does not exist and should be null or not submitted
            "int_amt": 5, -- based on selected value
            "int_base": -- does not exist and should be null or not submitted */


            return {
                type: entryValues.type,
                amt: entryValues.amt,
                // cap_revenue: null, // Number(entryValues.cap_revenue),
                // cap_nights: null, // Number(entryValues.cap_nights),
                // base: null, //entryValues.base,
                // currency: entryValues.currency,
                currency: entryValues.flat_currency,
                is_vip: entryValues.is_vip,
                // is_quarterly: 'N', // entryValues.is_quarterly,
                // is_monthly: 'N', //entryValues.is_monthly,
                // is_yearly: 'N', //entryValues.is_yearly,
                chain_code: entryValues.chain_code,
                description: entryValues.description,

                int_amt: entryValues.int_amt,
                // int_base: entryValues.int_base,

                // requires_trx: 'N', //entryValues.requires_trx_yn,
                // invoicing_fee_yn: 'N', //entryValues.invoicing_fee_yn,
                // invoice_to_hq_yn: 'N', //entryValues.invoice_to_hq_yn,

                insert_user: AuthService.userData.email,
            }
        }

        if (entryValues.type === 'FIXED') {
            return {
                type: entryValues.type,
                amt: entryValues.amt,
                // cap_revenue: Number(entryValues.cap_revenue),
                // cap_nights: Number(entryValues.cap_nights),
                base: entryValues.base,
                // currency: entryValues.currency,
                currency: entryValues.fixedFee_currency,

                is_vip: entryValues.is_vip,

                is_daily: entryValues.is_daily,
                is_quarterly: entryValues.is_quarterly,
                is_monthly: entryValues.is_monthly,
                is_yearly: entryValues.is_yearly,

                chain_code: entryValues.chain_code,
                description: entryValues.description,

                requires_trx: entryValues.requires_trx_yn,
                invoicing_fee_YN: entryValues.invoicing_fee_yn,
                invoice_to_HQ_YN: entryValues.invoice_to_hq_yn,

                int_amt: entryValues.int_amt,
                // int_base: null, // entryValues.int_base,

                insert_user: AuthService.userData.email,
            }
        }
    }

    /* const payload_preview = {

        type: entryValues.type,
        amt: entryValues.amt,
        cap_revenue: Number(entryValues.cap_revenue),
        cap_nights: Number(entryValues.cap_nights),
        base: entryValues.base,
        currency: entryValues.currency,
        is_vip: entryValues.is_vip,
        is_quarterly: entryValues.is_quarterly,
        is_monthly: entryValues.is_monthly,
        is_yearly: entryValues.is_yearly,
        chain_code: entryValues.chain_code,
        description: entryValues.description,
        requires_trx: entryValues.requires_trx,
        int_amt: entryValues.int_amt,
        int_base: entryValues.int_base,

        requires_trx_yn: entryValues.requires_trx_yn,
        invoicing_fee_yn: entryValues.invoicing_fee_yn,
        invoice_to_hq_yn: entryValues.invoice_to_hq_yn,

        insert_user: AuthService.userData.email,
        
    } */

    const handleSubmit = event => {
        // props.onSubmitRule()
        console.log('handleSubmit:', entryValues);

        if (!canSubmit()) {
            set_toShowValidationErrors(true)
            return;
        }

        set_toShowValidationErrors(false)

        /* {
            “type”: “PCT”,
            “chain_code”: “CHA”,
            “amt”: “7.5”,
            “cap_revenue”: “”,
            “cap_nights”: “”,
            “base”: “ROOM_REVENUE”,
            “currency”: “USD”,          // USD EUR or ''
            “vip_yn”: “”,
            “monthly_yn”: “”,
            “quarterly_yn”: “”,
            “yearly_yn”: “”,
            “description”: “TEST DESCRIPTION”,
            “insert_user”: “dnaiel.voegele@nokantdo.com”
            } */

        // insertRule(entryValues)
        insertRule(get_payload_preview())
            .then(resp => {
                console.log('insertRule resp:', resp);
                onClose(true)
            }
            ).catch(err => {
                console.error('insertRule err:', err);
                set_lastError(prev => { return [...prev, err] });
            });
    };

    const canSubmit = () => {
        // console.log('canSubmit');
        for (const key in validationErrors) {
            const errorMessage = String(validationErrors[key]);
            // console.log('key:', key, 'errMsg:', errorMessage);
            if (errorMessage.length > 0)
                return false;
        }
        return true;
    }

    return (
        <Dialog
            fullWidth maxWidth="md"
            classes={{ paper: classes.dialogPaper }}
            open={open}
            onClose={() => onClose(false)}
        >
            <DialogTitle disableTypography>
                <Typography variant="h5" color="primary">CREATE A RULE</Typography>
            </DialogTitle>

            <div style={{ height: '12px' }}>
                {
                    isLoading_LOV && <LinearProgress />
                }
            </div>

            <DialogContent>
                <ErrorsAlert lastError={lastError} set_lastError={set_lastError} />

                <FormControl className={classes.formControl} fullWidth>
                    <InputLabel>BRAND</InputLabel>
                    <Select
                        value={entryValues.chain_code}
                        onChange={handleChangeEntry}
                        input={<Input name="chain_code" />}
                    >
                        <MenuItem value=""><em>None</em></MenuItem>
                        {
                            brands_LOV.map((b, i) => {
                                return <MenuItem value={b.value} key={i}>{b.label}</MenuItem>
                            })
                        }
                    </Select>
                </FormControl>

                <FormControl className={classes.formControl} fullWidth>
                    <TextField
                        label="DESCRIPTION"
                        name="description"
                        value={entryValues.description}
                        onChange={handleChangeEntry}
                    />
                </FormControl>

                <FormControl component="fieldset" className={classes.formControlRadioGroup}>
                    <FormLabel component="p">DISCOVERY FEE TYPE</FormLabel>

                    <RadioGroup
                        name="type"
                        className={classes.formControlRadioGroup_horizontal}
                        value={entryValues.type}
                        onChange={handleChangeEntry}
                    >
                        <FormControlLabel
                            value="PCT"
                            // value="PERCENTAGE"
                            control={<Radio />}
                            label={<Typography color="primary">PERCENTAGE</Typography>}
                        />
                        <FormControlLabel
                            value="FLAT"
                            control={<Radio />}
                            label={<Typography color="primary">FLAT</Typography>}
                        />
                        <FormControlLabel
                            value="FIXED"
                            control={<Radio />}
                            label={<Typography color="primary">FIXED FEE</Typography>}
                        />

                    </RadioGroup>
                </FormControl>

                <Box m={2}></Box>

                {
                    entryValues.type === 'PCT' &&
                    // entryValues.type === 'PERCENTAGE' &&

                    <div className={classes.formControl_subGroup_horizontal}>

                        <FormLabel>BASED ON</FormLabel>

                        <div className={classes.formControlRadioGroup_horizontal}>
                            {/* <FormControl component="fieldset" className={classes.formControl} fullWidth>
                                <FormLabel>BASED ON</FormLabel>
                                <RadioGroup
                                    className={classes.formControlRadioGroup_horizontal}
                                    name="base"
                                    value={entryValues.base}
                                    onChange={handleChangeEntry}
                                >
                                    <FormControlLabel
                                        value="ROOM_REVENUE"
                                        control={<Radio />}
                                        label={<Typography color="primary">ROOM REVENUE</Typography>}
                                    />
                                    <FormControlLabel
                                        value="TOTAL_REVENUE"
                                        control={<Radio />}
                                        label={<Typography color="primary">TOTAL REVENUE</Typography>}
                                    />
                                </RadioGroup>
                            </FormControl>

                            <FormControl className={classes.formControl} fullWidth>
                                <TextField
                                    label="AMOUNT"
                                    name="percentage_amount"
                                    type="number"
                                    value={entryValues.percentage_amount}
                                    onChange={handleChangeEntry}

                                    error={validationErrors.percentage_amount.length > 0}
                                    helperText={validationErrors.percentage_amount}
                                />
                            </FormControl> */}


                            <FormControl className={classes.formControl} fullWidth>
                                {/* <FormLabel>BASED ON</FormLabel> */}
                                <TextField
                                    label="AMOUNT ROOMS"
                                    name="amt_rooms"
                                    type="number"
                                    value={entryValues.amt_rooms}
                                    onChange={handleChangeEntry}
                                    inputProps={{ min: 0, max: 15 }}

                                    error={toShowValidationErrors && validationErrors.amt_rooms.length > 0}
                                    helperText={toShowValidationErrors && validationErrors.amt_rooms}
                                    />
                            </FormControl>
                            <FormControl className={classes.formControl} fullWidth>
                                <TextField
                                    label="AMOUNT NON-ROOMS"
                                    name="amt_non_rooms"
                                    type="number"
                                    value={entryValues.amt_non_rooms}
                                    onChange={handleChangeEntry}
                                    inputProps={{ min: 0, max: 15 }}

                                    error={toShowValidationErrors && validationErrors.amt_non_rooms.length > 0}
                                    helperText={toShowValidationErrors && validationErrors.amt_non_rooms}
                                    />
                                    
                            </FormControl>
                        </div>








                        <FormLabel>INTERNAL FEES BASED ON</FormLabel>

                        <div className={classes.formControlRadioGroup_horizontal}>
                            {/* <FormControl component="fieldset" className={classes.formControl} fullWidth>
                                <FormLabel>INTERNAL FEES BASED ON</FormLabel>
                                <RadioGroup
                                    className={classes.formControlRadioGroup_horizontal}
                                    name="int_base"
                                    value={entryValues.int_base}
                                    onChange={handleChangeEntry}
                                >
                                    <FormControlLabel
                                        value="ROOM_REVENUE"
                                        control={<Radio />}
                                        label={<Typography color="primary">ROOM REVENUE</Typography>}
                                    />
                                    <FormControlLabel
                                        value="TOTAL_REVENUE"
                                        control={<Radio />}
                                        label={<Typography color="primary">TOTAL REVENUE</Typography>}
                                    />
                                </RadioGroup>
                            </FormControl>

                            <FormControl className={classes.formControl} fullWidth>
                                <TextField
                                    label="INTERNAL AMOUNT"
                                    name="pct_int_amt"
                                    type="number"
                                    value={entryValues.pct_int_amt}
                                    onChange={handleChangeEntry}
                                />
                            </FormControl> */}


                            <FormControl className={classes.formControl} fullWidth>
                                <TextField
                                    label="INTERNAL AMOUNT ROOMS"
                                    name="int_amt_rooms"
                                    type="number"
                                    value={entryValues.int_amt_rooms}
                                    onChange={handleChangeEntry}
                                    inputProps={{ min: 0, max: 15 }}

                                    error={toShowValidationErrors && validationErrors.int_amt_rooms.length > 0}
                                    helperText={toShowValidationErrors && validationErrors.int_amt_rooms}
                                />
                            </FormControl>

                            <FormControl className={classes.formControl} fullWidth>
                                <TextField
                                    label="INTERNAL AMOUNT NON-ROOMS"
                                    name="int_amt_non_rooms"
                                    type="number"
                                    value={entryValues.int_amt_non_rooms}
                                    onChange={handleChangeEntry}
                                    inputProps={{ min: 0, max: 15 }}

                                    error={toShowValidationErrors && validationErrors.int_amt_non_rooms.length > 0}
                                    helperText={toShowValidationErrors && validationErrors.int_amt_non_rooms}
                                />
                            </FormControl>


                           


                        </div>





                        {/* <div className={classes.formControlRadioGroup_horizontal}>
                            <FormControl className={classes.formControl} fullWidth>
                                <TextField
                                    label="CAPPED REVENUE"
                                    name="cap_revenue"
                                    type="number"
                                    value={entryValues.cap_revenue}
                                    onChange={handleChangeEntry}
                                />
                            </FormControl>

                            <FormControl className={classes.formControl} fullWidth>
                                <TextField
                                    label="CAPPED NIGHTS"
                                    name="cap_nights"
                                    type="number"
                                    value={entryValues.cap_nights}
                                    onChange={handleChangeEntry}
                                />
                            </FormControl>
                        </div> */}

                        <FormControl component="fieldset" className={classes.formControlRadioGroup}>
                            <FormControlLabel
                                label={<Typography color="primary">APPLICABLE TO VIP MEMBERS ONLY</Typography>}
                                control={
                                    <Checkbox
                                        name="percentage_isApplicableVIPonly"
                                        checked={entryValues.is_vip === 'Y'}
                                        onChange={(e, val) => {
                                            set_entryValues(prev => {
                                                return {
                                                    ...prev,
                                                    is_vip: prev.is_vip === 'Y' ? 'N' : 'Y'
                                                }
                                            })
                                        }}
                                        color="primary"
                                    />
                                }
                            />
                        </FormControl>
                    </div>
                }

                {
                    entryValues.type === 'FLAT' &&

                    <div className={classes.formControl_subGroup_horizontal}>

                        <div className={classes.formControlRadioGroup_horizontal}>
                            <FormControl className={classes.formControl} fullWidth>
                                <TextField
                                    label="AMOUNT"
                                    name="flat_amount"
                                    type="number"
                                    value={entryValues.flat_amount}
                                    onChange={handleChangeEntry}
                                />
                            </FormControl>

                            <FormControl className={classes.formControl} fullWidth>
                                <TextField
                                    label="INTERNAL AMOUNT"
                                    name="flat_int_amt"
                                    type="number"
                                    value={entryValues.flat_int_amt}
                                    onChange={handleChangeEntry}
                                />
                            </FormControl>

                            <FormControl className={classes.formControl} fullWidth>
                                <InputLabel>CURRENCY</InputLabel>
                                <Select
                                    value={entryValues.flat_currency}
                                    onChange={handleChangeEntry}
                                    input={<Input name="flat_currency" />}
                                >
                                    <MenuItem value="EUR">EUR</MenuItem>
                                    <MenuItem value="USD">USD</MenuItem>
                                </Select>
                            </FormControl>
                        </div>

                        <FormControl component="fieldset" className={classes.formControlRadioGroup}>
                            <FormControlLabel
                                label={<Typography color="primary">APPLICABLE TO VIP MEMBERS ONLY</Typography>}
                                control={
                                    <Checkbox
                                        name="flat_isApplicableVIPonly"
                                        checked={entryValues.is_vip === 'Y'}
                                        onChange={(e, val) => {
                                            set_entryValues(prev => {
                                                return {
                                                    ...prev,
                                                    is_vip: prev.is_vip === 'Y' ? 'N' : 'Y'
                                                }
                                            })
                                        }}
                                        color="primary"
                                    />
                                }
                            />
                        </FormControl>

                    </div>
                }

                {
                    entryValues.type === 'FIXED' &&


                    // requires_trx_yn: please add check box if FIXED is selected (not applicable to all others).

                    <div className={classes.formControl_subGroup_horizontal}>

                        <FormControl component="fieldset" className={classes.formControl}>
                            <FormLabel component="p">FREQUENCY</FormLabel>
                            <RadioGroup
                                name="fixedFee_frequency"
                                className={classes.formControlRadioGroup_horizontal}
                                value={entryValues.fixedFee_frequency}
                                onChange={handleChangeEntry}
                            >
                                <FormControlLabel
                                    value="daily"
                                    control={<Radio />}
                                    label={<Typography color="primary">DAILY</Typography>}
                                />
                                <FormControlLabel
                                    value="monthly"
                                    control={<Radio />}
                                    label={<Typography color="primary">MONTHLY</Typography>}
                                />
                                <FormControlLabel
                                    value="quarterly"
                                    control={<Radio />}
                                    label={<Typography color="primary">QUARTERLY</Typography>}
                                />
                                <FormControlLabel
                                    value="yearly"
                                    control={<Radio />}
                                    label={<Typography color="primary">YEARLY</Typography>}
                                />
                            </RadioGroup>
                        </FormControl>

                        <div className={classes.formControlRadioGroup_horizontal}>
                            <FormControl className={classes.formControl} fullWidth>
                                <TextField
                                    label="AMOUNT"
                                    name="fixedFee_amount"
                                    type="number"
                                    value={entryValues.fixedFee_amount}
                                    onChange={handleChangeEntry}
                                />
                            </FormControl>

                            <FormControl className={classes.formControl} fullWidth>
                                <TextField
                                    label="INTERNAL AMOUNT"
                                    name="fixedFee_int_amt"
                                    type="number"
                                    value={entryValues.fixedFee_int_amt}
                                    onChange={handleChangeEntry}

                                // error={validationErrors.fixedFee_int_amt.length > 0}
                                // helperText={validationErrors.fixedFee_int_amt}
                                />
                            </FormControl>

                            <FormControl className={classes.formControl} fullWidth>
                                <InputLabel>CURRENCY</InputLabel>
                                <Select
                                    value={entryValues.fixedFee_currency}
                                    onChange={handleChangeEntry}
                                    input={<Input name="fixedFee_currency" />}
                                >
                                    <MenuItem value="EUR">EUR</MenuItem>
                                    <MenuItem value="USD">USD</MenuItem>
                                </Select>
                            </FormControl>
                        </div>


                        <FormControl className={classes.formControl} fullWidth>
                            <FormControlLabel
                                label={<Typography color="primary">VALIDATION OF TRANSACTIONS REQUIRED</Typography>}
                                control={
                                    <Checkbox
                                        name="requires_trx_yn"
                                        checked={entryValues.requires_trx_yn === 'Y'}
                                        onChange={e => {
                                            set_entryValues(prev => {
                                                return {
                                                    ...prev,
                                                    requires_trx_yn: prev.requires_trx_yn === 'Y' ? 'N' : 'Y'
                                                }
                                            })
                                        }}
                                        color="primary"
                                    />
                                }
                            />
                        </FormControl>

                        <FormControl className={classes.formControl} fullWidth>
                            <FormControlLabel
                                label={<Typography color="primary">INVOICING FEES</Typography>}
                                control={
                                    <Checkbox
                                        name="invoicing_fee_yn"
                                        checked={entryValues.invoicing_fee_yn === 'Y'}
                                        onChange={e => {
                                            set_entryValues(prev => {
                                                return {
                                                    ...prev,
                                                    invoicing_fee_yn: prev.invoicing_fee_yn === 'Y' ? 'N' : 'Y'
                                                }
                                            })
                                        }}
                                        color="primary"
                                    />
                                }
                            />
                        </FormControl>

                        <FormControl className={classes.formControl} fullWidth>
                            <FormControlLabel
                                label={<Typography color="primary">INVOICE TO HQ</Typography>}
                                control={
                                    <Checkbox
                                        name="invoice_to_hq_yn"
                                        checked={entryValues.invoice_to_hq_yn === 'Y'}
                                        onChange={e => {
                                            set_entryValues(prev => {
                                                return {
                                                    ...prev,
                                                    invoice_to_hq_yn: prev.invoice_to_hq_yn === 'Y' ? 'N' : 'Y'
                                                }
                                            })
                                        }}
                                        color="primary"
                                    />
                                }
                            />
                        </FormControl>

                    </div>

                }

                {/* <div style={{ position: 'absolute', bottom: 0, marginLeft: 100, fontSize: 11 }}>
                    <span>PAYLOAD PREVIEW:</span>
                    <pre>{JSON.stringify(
                        //entryValues, 
                        get_payload_preview(),
                        null, 2)}</pre>

                </div> */}
            </DialogContent>

            <DialogActions>
                <MyFabCancel onClick={() => onClose(false)} />
                <MyFabSubmit onClick={() => handleSubmit()} />
                {/* disabled={!canSubmit()} */}
                {/* <p>
                    ccc: { canSubmit() ? 'true' : 'false' }
                </p> */}
            </DialogActions>
        </Dialog >
    );
}
