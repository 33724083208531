import React, { useState, useEffect, useCallback } from 'react';
import {
    Dialog, DialogActions, DialogContent, DialogTitle, FormControl,
    FormControlLabel, Input, InputLabel, MenuItem, Radio, RadioGroup, Select, Typography, LinearProgress
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { useGlobalAppState } from '../../state/myGlobalContext';
import { MyFabCancel, MyFabSubmit } from '../../comps/MyFab';
import { useStyles_dialogLink } from '../../theme/dialogStyles';
import { useWhyDidYouUpdate } from '../../hooks/whyDidYouUpdate';
import { getFormattedDate, isDateValid } from '../../utils/utils';
import { AuthService } from '../../api/auth';
import { insertLinkedItem } from '../../api/api';
import { ErrorsAlert } from '../../comps/ErrorsAlert';

export default function CreateLinkDialog(props) {

    //console.log('CreateLinkDialog props:', props);

    useWhyDidYouUpdate('CreateLinkDialog', props)

    const classes = useStyles_dialogLink();
    const {
        state: { brands_LOV, resorts_LOV, stayTypes_LOV, isLoading_LOV },
        load_LOVs, load_stayTypes_LOV,
        loadExceptionsFromBackend // to get resorts_LOV , is this right way?
    } = useGlobalAppState();
    const { open, onClose, selectedRule } = props;
    console.log('CreateLinkDialog selectedRule:', selectedRule);

    const [lastError, set_lastError] = useState([]);
    const [entryValues, set_entryValues] = useState({

        calc_id: selectedRule.calc_id || '',
        // chain: props.selectedRule.calc_id || '',
        chain_code: selectedRule.chain || '',

        // once written upon dialog open, non editable
        calc_desc: selectedRule.description || '',
        fee_type: selectedRule.fee_type || '',
        base: selectedRule.base || '',
        amount: selectedRule.amt || '',
        is_vip: selectedRule.vip_yn, // === 'Y' ? 'Y' : '',

        resortOrBrand: 'resort',

        // “resort”: “KIBUD”,
        // submit RESORT value corresponding to the selected RESORT_NAME. If BRAND was selected then submit null “”
        // set on change
        resort: '',
        brand: '',

        stayTypes: [],

        dateFrom: null,
        dateTo: null,
        begin_date: '', // "2020-01-01",
        end_date: '', //"2020-12-31",

        insert_user: AuthService.userData.email,
    });

    const load_LOVs_cb = useCallback(load_LOVs, []);
    const load_stayTypes_LOV_cb = useCallback(load_stayTypes_LOV, []);
    const loadExceptionsFromBackend_cb = useCallback(loadExceptionsFromBackend, []);

    useEffect(() => {
        load_LOVs_cb()
        load_stayTypes_LOV_cb()
        loadExceptionsFromBackend_cb()
        return () => { }
    }, [load_LOVs_cb, load_stayTypes_LOV_cb, loadExceptionsFromBackend_cb]);

    const handleChangeEntry = event => {
        const targetName = event.target.name;
        const targetValue = event.target.value;
        console.log('handleChangeEntry name value:', targetName, targetValue);

        // submit RESORT value corresponding to the selected RESORT_NAME. If BRAND was selected then submit null “”
        if (targetName === 'resortOrBrand') {

            if (targetValue === 'brand') {
                // set_entryValues(prev => ({ ...prev, resort: null, chain_code: selectedRule.chain }))
                set_entryValues(prev => ({ ...prev, resort: '', chain_code: selectedRule.chain }))
            } else {

                // get chain code per targetValue
                /* const resortsList = getResortsForChain(selectedRule.chain).map((b, i) => {
                    // console.log('b:', b);
                    return <MenuItem value={b.value} key={i}>{b.label}</MenuItem>
                }) */

                // set_entryValues(prev => ({ ...prev, resort: targetValue, chain_code: '' }))
                set_entryValues(prev => ({ ...prev, resort: entryValues.resort, chain_code: entryValues.chain_code }))
            }
        }

        set_entryValues(prev => ({ ...prev, [targetName]: targetValue }))
    };

    const handleSubmit = async event => {
        /* "calc_id": 38,
           "chain_code": "KI",
           "resort": "KIBUD",
           "status": "ENROLMENT",
           "begin_date": "2020-01-01",
           "end_date": "2020-12-31",
           "insert_user": "daniel.voegele@nokantdo.com",
           "is_vip": "Y",
           "calc_desc": "TEST RULE DESCRIPTION" */

        let status = '';

        if (selectedRule.fee_type === 'FIXED_DAILY'
            || selectedRule.fee_type === 'FIXED_MONTHLY'
            || selectedRule.fee_type === 'FIXED_QUARTERLY'
            || selectedRule.fee_type === 'FIXED_YEARLY'
        ) {
            status = 'FIXED'
            //
            // CALL ONLY ONCE
            //
            const payload = {
                calc_id: entryValues.calc_id,
                chain_code: entryValues.chain_code,
                resort: entryValues.resort,
                begin_date: entryValues.begin_date,
                end_date: entryValues.end_date,
                is_vip: entryValues.is_vip,
                calc_desc: entryValues.calc_desc,
                status: status,
                insert_user: entryValues.insert_user
            }

            insertLinkedItem(payload)
                .then(resp => {
                    console.log('insertLinkedItem resp:', resp);
                    onClose(true)
                }
                ).catch(err => {
                    console.error('insertLinkedItem err:', err);
                    set_lastError(prev => { return [...prev, err] });
                });

        } else {
            // it is PCT or FLAT
            // read stay types. per each entry do one call

            let wasError = false;

            if (entryValues.stayTypes.length === 0) {
                console.log('insertLinkedItem return: no stayTypes');
                return;
            }

            const payloads = [];

            entryValues.stayTypes.forEach((stayType, i) => {
                const payload = {
                    calc_id: entryValues.calc_id,
                    chain_code: entryValues.chain_code,
                    resort: entryValues.resort,
                    begin_date: entryValues.begin_date,
                    end_date: entryValues.end_date,
                    is_vip: entryValues.is_vip,
                    calc_desc: entryValues.calc_desc,
                    status: stayType,
                    insert_user: entryValues.insert_user
                }
                console.log('payload:', i, payload);
                payloads.push(payload)
            })

            await Promise.all(payloads.map((payload, index, array) => {
                console.log('Create Link sendAll index:', index);
                return insertLinkedItem(payload).then(resp => {
                    console.log('insertLinkedItem resp:', resp);
                })
            })).catch(err => {
                console.error('Promise.all insertLinkedItem err:', err);
                wasError = true;
                set_lastError(prev => { return [...prev, err] });
            });

            console.log('DONE');

            if (!wasError) {
                onClose(true)
            }
        }
    };

    const handleChange_date = (d, inputName) => {
        console.log('handleChange_date:', d, inputName);

        // set date val to state
        set_entryValues(prev => ({ ...prev, [inputName]: d }))

        if (!isDateValid(d)) {
            return;
        }

        // save for payload formatted date
        if (inputName === 'dateFrom') {
            set_entryValues(prev => ({ ...prev, begin_date: getFormattedDate(d) }))
        }
        if (inputName === 'dateTo') {
            set_entryValues(prev => ({ ...prev, end_date: getFormattedDate(d) }))
        }
    }

    const getResortsForChain = (chain) => {
        console.log('getResortsForChain:', chain, resorts_LOV);
        const finalRes = resorts_LOV.filter(val => {
            // return val.chain_code === entryValues.chain_code
            return val.chain_code === chain
        })
        return finalRes;
    };

    const amountpreview = () => {
        const rule = selectedRule;
        if(rule.fee_type === "PCT") {
            if(rule.discovery1_yn && rule.discovery1_yn === 'Y'){
                return rule.amt;
            } else{
                return rule.amt_rooms + '% ROOMS / ' + rule.amt_non_rooms + '% NON ROOMS';
            }
        } 
        return rule.amt;
    }


    return (
        <Dialog
            fullWidth maxWidth="md"
            open={open}
            onClose={() => onClose(false)}
        >
            <DialogTitle disableTypography>
                <Typography variant="h5" color="primary">CREATE A LINK</Typography>

                <ErrorsAlert lastError={lastError} set_lastError={set_lastError} />
            </DialogTitle>

            <div style={{ height: '12px' }}>
                {
                    isLoading_LOV && <LinearProgress />
                }
            </div>

            <DialogContent>

                <FormControl className={classes.formControl} fullWidth>
                    <InputLabel>RULE NAME</InputLabel>
                    <Input
                        name="calc_desc"
                        value={entryValues.calc_desc}
                        onChange={handleChangeEntry}
                        disabled
                    />
                </FormControl>

                <FormControl className={classes.formControl} fullWidth>
                    <InputLabel>FEE TYPE</InputLabel>
                    <Input
                        name="fee_type"
                        value={entryValues.fee_type}
                        onChange={handleChangeEntry}
                        disabled
                    />
                </FormControl>

                {/* <FormControl className={classes.formControl} fullWidth>
                    <InputLabel>BASED ON</InputLabel>
                    <Input
                        name="base"
                        value={entryValues.base}
                        onChange={handleChangeEntry}
                        disabled
                    />
                </FormControl> */}

                <FormControl className={classes.formControl} fullWidth>
                    <InputLabel>AMOUNT</InputLabel>
                    {/* value={entryValues.amount} */}
                    <Input
                        name="amount"
                        value={amountpreview()}
                        onChange={handleChangeEntry}
                        disabled
                    />
                </FormControl>

                <FormControl className={classes.formControl} fullWidth>
                    <InputLabel>VIP ONLY</InputLabel>
                    <Input
                        name="is_vip"
                        value={entryValues.is_vip}
                        onChange={handleChangeEntry}
                        disabled
                    />
                </FormControl>

                <div className={classes.formControlRadioGroup}>
                    <RadioGroup
                        name="resortOrBrand"
                        className={classes.formControlRadioGroup_horizontal}
                        value={entryValues.resortOrBrand}
                        onChange={handleChangeEntry}
                    >
                        <FormControlLabel
                            value="resort"
                            control={<Radio />}
                            label={<Typography color="primary">RESORT</Typography>}
                        />
                        <FormControlLabel
                            value="brand"
                            control={<Radio />}
                            label={<Typography color="primary">BRAND</Typography>}
                        />
                    </RadioGroup>
                </div>

                {
                    entryValues.resortOrBrand === 'resort' &&
                    <FormControl className={classes.formControl} fullWidth>
                        <InputLabel>RESORT</InputLabel>
                        <Select
                            value={entryValues.resort}
                            onChange={handleChangeEntry}
                            input={<Input name="resort" />}
                        >
                            <MenuItem value=""><em>None</em></MenuItem>
                            {
                                // brands_LOV.map((b, i) => {
                                // select distinct property_name from brand_mapping where chain=‘fetched chain’ for that CALC_ID.
                                getResortsForChain(selectedRule.chain).map((b, i) => {
                                    // console.log('b:', b);
                                    return <MenuItem value={b.value} key={i}>{b.label}</MenuItem>
                                })
                            }
                        </Select>
                    </FormControl>
                }

                {
                    entryValues.resortOrBrand === 'brand' &&
                    <FormControl className={classes.formControl} fullWidth>
                        <InputLabel>BRAND</InputLabel>
                        <Select
                            disabled
                            // value={entryValues.brand}
                            value={selectedRule.chain}
                            // onChange={handleChangeEntry}
                            input={<Input name="brand" />}
                        >
                            <MenuItem value=""><em>None</em></MenuItem>
                            {
                                brands_LOV.map((b, i) => {
                                    return <MenuItem value={b.value} key={i}>{b.label}</MenuItem>
                                })
                            }
                        </Select>
                    </FormControl>
                }



                {/* If FEE TYPE (which you will display on top) is PCT (PERCENTAGE) or FLAT then it will be visible. */}
                {
                    (entryValues.fee_type === 'PCT' || entryValues.fee_type === 'FLAT') &&
                    // (entryValues.fee_type === 'PERCENTAGE' || entryValues.fee_type === 'FLAT') &&

                    <FormControl className={classes.formControl} fullWidth>
                        <InputLabel>STAY TYPE</InputLabel>
                        <Select
                            multiple
                            MenuProps={{ variant: 'menu' }}
                            value={entryValues.stayTypes}
                            onChange={handleChangeEntry}
                            input={<Input name="stayTypes" />}
                        >
                            <MenuItem value=""><em>None</em></MenuItem>
                            {
                                stayTypes_LOV.map((b, i) => {
                                    return <MenuItem value={b.value} key={i}>{b.label}</MenuItem>
                                })
                            }
                        </Select>
                    </FormControl>
                }


                <div className={classes.formControlRadioGroup_horizontal}>

                    <FormControl className={classes.formControlFilter_horizontal_date} fullWidth>

                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="dd-MMM-yy"
                            name="dateFrom"
                            label="FROM DATE"
                            value={entryValues.dateFrom}
                            onChange={date => handleChange_date(date, 'dateFrom')}

                            maskChar="-"
                            inputProps={{
                                style: {
                                    textTransform: "uppercase",
                                }
                            }}
                            refuse="/[^\d]+/gi"

                            invalidDateMessage="Expected Date Format DD-MON-YY"
                        />
                    </FormControl>

                    <FormControl fullWidth>
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="dd-MMM-yy"
                            name="dateTo"
                            label="TO DATE"
                            value={entryValues.dateTo}
                            onChange={date => handleChange_date(date, 'dateTo')}

                            maskChar="-"
                            inputProps={{
                                style: {
                                    textTransform: "uppercase",
                                }
                            }}
                            refuse="/[^\d]+/gi"

                            invalidDateMessage="Expected Date Format DD-MON-YY"
                        />
                    </FormControl>

                </div>

                {/* <pre>
                    {JSON.stringify(entryValues, null, 2)}
                </pre> */}

            </DialogContent>

            <DialogActions>
                <MyFabCancel onClick={() => onClose(false)} />
                <MyFabSubmit onClick={() => handleSubmit()} />
            </DialogActions>

        </Dialog>
    );
}
