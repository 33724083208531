import React from 'react';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';

export const renderExceptionCell = (item, cellData, dataKey) => {
    // console.log('renderExceptionCell dataKey:', dataKey);
    // console.log('renderExceptionCell cellData:', cellData);
    // console.log('renderExceptionCell item:', item);
    
    // if from brands page
    // const isEnrollCol = dataKey === 'exception_codes_enrollment' || dataKey === 'exception_codes_repeat';
    
    // if from exceptions page
    // const isEnrollCol = dataKey === 'is_enrollment' || dataKey === 'is_repeat';
    
    const isTrue = cellData === 'Y';

    // if (!isEnrollCol) {
    //     return ''
    // }

    return isTrue ? <CheckCircleOutlinedIcon color='action' /> : ''
}