import { createMuiTheme } from "@material-ui/core";
import { orange, red } from "@material-ui/core/colors";

import proximanovaRegularWoff2 from "../assets/fonts/proximanova-regular-webfont.woff2";
import proximanovaBoldWoff2 from "../assets/fonts/proximanova-bold-webfont.woff2";

const defaultTheme = createMuiTheme({ palette: { type: 'dark' } });

const proximanovaRegular = {
    fontFamily: 'proxima_novaregular',
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: `
    local('proxima_novaregular'),
    local('proxima_novaregular-Regular'),
    url(${proximanovaRegularWoff2}) format('woff2')
  `,
};

const proximanovaBold = {
    fontFamily: 'proxima_novabold',
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: `
    local('proxima_novabold'),
    local('proxima_novabold-Bold'),
    url(${proximanovaBoldWoff2}) format('woff2')
  `,
};

const defTypography = {
    fontFamily: 'proxima_novaregular, Arial',

    h2: {
        margin: '18px 0',
        fontSize: '24px',
    }
};
const defOverrides = {
    MuiCssBaseline: {
        '@global': {
            '@font-face': [proximanovaRegular, proximanovaBold],
            // '@font-face': [proximanovaBold],
        },
    },
    // set custom Tab's label font size
    MuiTab: {
        root: {
            fontSize: 26
        }
    },
    /* MuiButton: {
        root: {
            color: '#99a39b',
        }
    } */
    MuiPickersDay: {
        daySelected: {
            // backgroundColor: lightBlue["400"],
            color: '#fff',
        },
        // day: {
        //     color: lightBlue.A700,
        // },
        // daySelected: {
        //     backgroundColor: lightBlue["400"],
        // },
        // dayDisabled: {
        //     color: lightBlue["100"],
        // },
        // current: {
        //     color: lightBlue["900"],
        // },
    },


    MuiDialogActions: {
        root: {
            /* 
            flex: 0 0 auto;
            display: flex;
            padding: 8px;
            align-items: center; */
            flex: '0 0 auto',
            display: 'flex',
            padding: '8px',

            justifyContent: 'space-between',
            margin: '8px 12px'
        }
    },

    MuiFab: {
        root: {
            boxShadow: 'none'
        }
    }


};

export const defaultThemeLight = createMuiTheme({
    palette: {
        type: 'light',
        background: { default: '#99a39b' },
        primary: {
            main: '#99a39b',
        },
        secondary: {
            main: '#99a49c',
            // main: 'linear-gradient(45deg,#2226c0 0,#99a49c 100%)', // error
            contrastText: '#fff'
        }
        ,

    },
    typography: defTypography,
    overrides: defOverrides,
});
export const defaultThemeDark = createMuiTheme({
    palette: {
        type: 'dark',
        // background: { default: '#99a39b' },
        primary: {
            main: '#99a39b',
        }
    },
    typography: defTypography,
    overrides: defOverrides,
});




































let myTheme = createMuiTheme({
    /* palette: {
        // type: prefersDarkMode ? 'dark' : 'light',
    }, */
    palette: {
        type: 'light', // : 'light',
        primary: {
            main: '#0000FF',
        },
        secondary: {
            main: '#19857b',
        },
        error: {
            main: red.A400,
        },
        background: {
            default: '#fff',
        },
    },

    // custom?
    status: {
        danger: orange[500],
    },
});

export function createTheme(type) {

    console.log('createTheme type:', type);
    // myTheme.palette.type = 'dark';

    const paletteObj = defaultTheme.palette;

    // paletteObj.primary = { main: '#9944AA' }

    return createMuiTheme({
        /* palette: {
            // type: prefersDarkMode ? 'dark' : 'light',
        }, */
        // palette: {
        //     type: type || 'light',
        //   /*   primary: {
        //         main: '#0000FF',
        //     },
        //     secondary: {
        //         main: '#19857b',
        //     }, */
        //     error: {
        //         main: red.A400,
        //     },
        //     background: {
        //         default: '#fff',
        //     },
        // },
        palette: paletteObj,

        // custom?
        status: {
            danger: orange[500],
        },
    })
}

export function setDarkTheme() {
    console.log('setDarkTheme');
    // myTheme.palette.type = 'dark';
    const newtype = myTheme.palette.type === 'dark' ? 'light' : 'dark';
    createTheme(newtype);
}

/* const darkTheme = createMuiTheme({
    palette: {
        type: 'dark',
    },
}); */

// const myTheme = createMuiTheme({


// export default myTheme;