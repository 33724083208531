import React, { useReducer, createContext, useContext, useMemo } from 'react'
import { getBrandsAndGroupsMap, getBrandsData, getRulesItems, getExceptionsData } from '../api/api'
import { globalAppStateReducer } from './myGASReducer'

// src/global-app-state/context.js
const GlobalAppStateContext = createContext()
const initalAppState = {
    selectedTab: 0,
    isLoading: false,
    isLoading_LOV: false,
    lastError: [],

    brands: [],
    rules: [],
    exceptions: [],

    brands_LOV: [],
    billingGroups_LOV: [],
    procedureGroups_LOV: [],
    stayTypes_LOV: [],

    exceptionCodes_LOV: [],
    exceptionCodes_for_CREATE_EXC_LOV: [],

    property_LOV: [],
    resorts_LOV: [],
    resorts_from_exceptions_LOV: [],
    chain_codes_LOV: [],

    complete_map: [],
}

// Context Provider
function GlobalAppStateProvider(props) {
    const [state, dispatch] = useReducer(globalAppStateReducer, initalAppState)
    const value = useMemo(() => [state, dispatch], [state])
    return <GlobalAppStateContext.Provider value={value} {...props} />
}

// hook
function useGlobalAppState() {
    const context = useContext(GlobalAppStateContext)
    if (!context) {
        throw new Error(`useGlobalAppState must be used within a GlobalAppStateProvider`)
    }
    const [state, dispatch] = context

    /**
     * Load LOV to populate "Brand" and "Billing Group" Select component options.
     */
    // const load_brands_LOV = () => {
    const load_LOVs = () => {
        // console.log('load_brands_LOV');
        set_isLoading_LOV(true)
        getBrandsAndGroupsMap()
            .then(resp => {
                console.log('getBrandsAndGroupsMap resp:', resp);
                // 
                // For the brand / billing group drop down: select distinct brand_name / billing_group_desc from the following:
                // https://gha-soa-billing-api-v1.de-c1.cloudhub.io/api/gha/billing/brand-mapping
                const sourceArray = Array.from(resp);

                /* const respitem = {
                    billing_group: "AN",
                    billing_group_desc: "MINOR",
                    brand: "AN",
                    brand_name: "Anantara",
                    chain_end: "",
                    chain_start: "29-JUN-10",
                    enrollment_code: "ANAABS_W",
                    enrollment_desc: "Al Baleed Resort Salalah by Anantara WIFI",
                    enrollment_exception_codes: "WAN",
                    enrollment_group: "AN",
                    first_stay_intragroup_yn: "Y",
                    hotel_end: "",
                    hotel_start: "28-JUL-16",
                    optin_yn: "N",
                    property: "ANAABS",
                    property_name: "Al Baleed Resort Salalah by Anantara",
                    repeat_exception_codes: "",
                    utc_property_yn: "N",
                    web_user_account_yn: "Y",
                    welcome_email_yn: "Y",
                } */

                //
                const lov = sourceArray
                    .map(v => ({
                        // value: v.chain_code,
                        // label: v.chain_code_desc,
                        value: v.brand,
                        label: v.brand_name,
                    }))
                    .filter((v, i, a) => a.findIndex(c => c.value === v.value) === i)
                    .sort((a, b) => (a.label > b.label) ? 1 : -1)

                //
                const bg_lov = sourceArray
                    .map(v => ({
                        value: v.billing_group,
                        label: v.billing_group_desc,
                    }))
                    .filter((v, i, a) => a.findIndex(c => c.label === v.label) === i)
                    .sort((a, b) => (a.label > b.label) ? 1 : -1)

                // could grab exceptoins grouped here, but no id for excpetion for editing/PUT
                /*  const exceptionsGrouped = sourceArray
                     .map(v => ({
                         resort ...
                         property
                         property_name
                         resort: v.property,
                         resort_desc: v.property_name,
                     }))
                     .filter((v, i, a) => a.findIndex(c => c.label === v.label) === i)
                     .sort((a, b) => (a.label > b.label) ? 1 : -1) */
                // exceptions codes LOV
                // enrollment_code: "ANAABS_W"
                // enrollment_desc: "Al Baleed Resort Salalah by Anantara WIFI"
                const exc_codes_lov = sourceArray
                    .map(v => ({
                        value: v.enrollment_code,
                        label: v.enrollment_code, // + ' - ' + v.enrollment_desc,
                        //  aaaaaaaa: v.id,
                        chain_code: v.brand,
                        ...v
                    }))
                    .filter((v, i, a) => a.findIndex(c => c.label === v.label) === i)
                    // .filter((v, i, a) => a.findIndex(c => c.value === v.value) === i)
                    .sort((a, b) => (a.label > b.label) ? 1 : -1)
                console.log('getBrandsAndGroupsMap exc_codes_lov:', exc_codes_lov);


                //
                /* var result = groupArrayBy(sourceArray, function (item) {
                    return [item.property];
                });
                console.log('new_resorts_LOV groupArrayBy:', result); */

                const new_resorts_LOV = sourceArray
                    .filter((v, i, a) => a.findIndex(c => c.property === v.property) === i)
                    .map(v => {
                        return {
                            // value: v.brand,
                            value: v.property,
                            label: v.property + ' - ' + v.property_name,
                            chain_code: v.brand,
                            ...v
                            // add for API
                            // chain_code: v.chain_code,
                            // chain_code_desc: v.chain_code_desc
                        }
                    })
                    .filter(v => {
                        return !v.hotel_end || (new Date(v.hotel_end).getTime() > new Date().getTime())
                    })

                /* const sortField = 'label'
                new_resorts_LOV.sort((a, b) => {
                    const aitem = String(a[sortField]).toLowerCase()
                    const bitem = String(b[sortField]).toLowerCase()
                    // if (a[sortField] < b[sortField]) { return -1; }
                    // if (a[sortField] > b[sortField]) { return 1; }
                    if (aitem < bitem) { return -1; }
                    if (aitem > bitem) { return 1; }
                    return 0;
                })
                console.log('set_resorts_LOV:', new_resorts_LOV); */
                set_resorts_LOV(new_resorts_LOV)



                //
                set_complete_map(sourceArray)
                set_brands_LOV(lov)
                set_billingGroups_LOV(bg_lov)
                set_exceptionCodes_for_CREATE_EXC_LOV(exc_codes_lov)
                set_isLoading_LOV(false)
            })
            .catch(err => {
                console.error('getBrandsAndGroupsMap catched error:', err)
                set_lastError([...state.lastError, err]);
                set_isLoading_LOV(false)
            });

        // dispatch({ type: 'SET_BRANDS_LOV', data: brands_LOV })
    }

    const load_brands = () => {
        set_isLoading(true)

        getBrandsData()
            .then(resp => {
                console.log('getBrandsData resp:', resp);
                set_brands(resp)
                set_isLoading(false)
            })
            .catch(err => {
                console.error('getBrandsData catched error:', err)
                set_lastError([...state.lastError, err]);
                set_isLoading(false)
            });
    }
    const loadSetupsFromBackend = () => {
        set_isLoading(true)

        getRulesItems()
            .then(resp => {
                console.log('getRulesItems resp:', resp);
                set_rules(resp ? resp : [])
                set_isLoading(false)
            }).catch(err => {
                console.error('getRulesItems catched error:', err)
                set_lastError([...state.lastError, err]);
                set_isLoading(false)
            });
    }

    const loadExceptionsFromBackend = () => {
        set_isLoading(true)

        getExceptionsData()
            .then(resp => {
                console.log('getExceptionsData resp:', resp);


                const exc_codes_lov = resp.map(v => ({
                    ...v,
                    value: v.enrollment_code,
                    // label: v.enrollment_code + ' - ' + v.enrollment_desc,
                    // Daniel: dont show desc
                    // https://gitea.nokantdo.com:25443/NKD/Billing/issues/35
                    label: v.enrollment_code,
                }))
                /* .map(v => ({
                    value: v.enrollment_code,
                    label: v.enrollment_code + ' - ' + v.enrollment_desc,
                    aaaaaaaa: v.id,
                }))
                    .filter((v, i, a) => a.findIndex(c => c.label === v.label) === i)
                    // .filter((v, i, a) => a.findIndex(c => c.value === v.value) === i)
                    .sort((a, b) => (a.label > b.label) ? 1 : -1)
                    */
                console.log('getExceptionsData exc_codes_lov:', exc_codes_lov);
                set_exceptionCodes_LOV(exc_codes_lov)

                // set_rules(resp ? resp : [])

                // group the data 
                /* 
                chain_code: "VI"
                chain_code_desc: "Viceroy Hotels and Resorts"
                enrollment_code: "WVI"
                id: 2196
                inactive_date: null
                insert_user: "daniel.voegele@nokantdo.com"
                is_enrollment: "Y"
                is_repeat: "N"
                resort: "VILER"
                resort_desc: "L'ERMITAGE HOTEL"
                update_user: "daniel.voegele@nokantdo.com" */

                // grp by resort val
                /* [
                    {
                        resort: 'ssssss',
                        resort_desc: 'ssssss',
                        // make it ARRAY !!
                        exception_codes_enrollment_stay: 'ss, rr, 333, rrr',
                        exception_codes_repeat_stay: 'ss, rr, 333, rrr',
                    },
                    ...
                ] */
                const res = []

                resp.forEach(item => {

                    // console.log('item:', item);

                    const resort = item.resort;
                    const is_enrollment = item.is_enrollment === 'Y';
                    const is_repeat = item.is_repeat === 'Y';
                    const exists = res.find(r => r.resort === resort)

                    // console.log('exists:', exists);

                    if (exists) {
                        //add to same group
                        if (is_enrollment) {
                            // exists.exception_codes_enrollment_stay.push(item.enrollment_code)
                            exists.exception_codes_enrollment_stay_onlyLabel.push(item.enrollment_code)
                            exists.exception_codes_enrollment_stay.push(item)
                        }
                        if (is_repeat) {
                            // exists.exception_codes_repeat_stay.push(item.enrollment_code)
                            exists.exception_codes_repeat_stay_onlyLabel.push(item.enrollment_code)
                            exists.exception_codes_repeat_stay.push(item)
                        }
                        exists.allExceptions.push(item)
                    } else {
                        // first of of this resort/group
                        const newRow = {
                            id: item.id,
                            resort: resort,
                            resort_desc: item.resort_desc,
                            exception_codes_enrollment_stay: [],
                            exception_codes_enrollment_stay_onlyLabel: [],
                            exception_codes_repeat_stay: [],
                            exception_codes_repeat_stay_onlyLabel: [],
                            chain_code: item.chain_code,
                            chain_code_desc: item.chain_code_desc,

                            // adding for easier Copy exceptions
                            allExceptions: []
                        }
                        if (is_enrollment) {
                            // newRow.exception_codes_enrollment_stay.push(item.enrollment_code)
                            newRow.exception_codes_enrollment_stay_onlyLabel.push(item.enrollment_code)
                            newRow.exception_codes_enrollment_stay.push(item)
                        }
                        if (is_repeat) {
                            // newRow.exception_codes_repeat_stay.push(item.enrollment_code)
                            newRow.exception_codes_repeat_stay_onlyLabel.push(item.enrollment_code)
                            newRow.exception_codes_repeat_stay.push(item)
                        }
                        newRow.allExceptions.push(item)

                        res.push(newRow)
                    }
                })
                /* var result = groupArrayBy(exceptionsArray, function (item) {
                    // return [item.calc_id, item.chain, item.fee_type_desc, item.base, item.begin_date, item.end_date];
                    return [item.resort];
                }); */
                console.log('exceptionsArray res:', res);

                // build resorts_LOV here? 
                // will be only one item - no point for filter as it is just groupped?
                const new_resorts_LOV = res.map(v => {
                    return {
                        value: v.resort,
                        // label: 'chaincode:' + v.chain_code + ' - ' + v.resort + ' - ' + v.resort_desc,
                        // label: 'id:' + v.id + ' (chaincode:)' + v.chain_code + ' - ' + v.resort + ' - ' + v.resort_desc,
                        //  label: v.resort + ' - ' + v.resort_desc,
                        //  label: v.resort + ' - ' + v.resort_desc,
                        label: v.resort_desc,
                        // add for API
                        chain_code: v.chain_code,
                        chain_code_desc: v.chain_code_desc
                    }
                });
                const sortField = 'label'
                new_resorts_LOV.sort((a, b) => {
                    const aitem = String(a[sortField]).toLowerCase()
                    const bitem = String(b[sortField]).toLowerCase()
                    // if (a[sortField] < b[sortField]) { return -1; }
                    // if (a[sortField] > b[sortField]) { return 1; }
                    if (aitem < bitem) { return -1; }
                    if (aitem > bitem) { return 1; }
                    return 0;
                })
                console.log('set_resorts_from_exceptions_LOV:', new_resorts_LOV);
                set_resorts_from_exceptions_LOV(new_resorts_LOV)

                // START chain_codes_LOV - only unique cahin codes
                const new_chain_codes_LOV = []
                res.forEach(v => {
                    if (!new_chain_codes_LOV.find(cc => cc.chain_code === v.chain_code)) {
                        new_chain_codes_LOV.push({
                            chain_code: v.chain_code,
                            chain_code_desc: v.chain_code_desc
                        })
                    }
                });
                set_chain_codes_LOV(new_chain_codes_LOV)

                // END chain_codes_LOV
                // res.sort()
                // res.sort((a, b) => a.resort - b.resort)
                // const sortField = 'resort'
                /* const sortField = 'resort_desc'
                res.sort((a, b) => {
                    const aitem = String(a[sortField]).toLowerCase()
                    const bitem = String(b[sortField]).toLowerCase()
                    // if (a[sortField] < b[sortField]) { return -1; }
                    // if (a[sortField] > b[sortField]) { return 1; }
                    if (aitem < bitem) { return -1; }
                    if (aitem > bitem) { return 1; }
                    return 0;
                }) */

                // console.log('exceptionsArray res:', res);

                // const res_sec = 
                //     console.log('exceptionsArray res:', res_sec);

                if (res) {
                    set_exceptions(res)
                } else {
                    set_exceptions([])
                }


                set_isLoading(false)
            }).catch(err => {
                console.error('getExceptionsData catched error:', err)
                set_lastError([...state.lastError, err]);
                set_isLoading(false)
            });
    }
    const load_procedureGroups_LOV = () => {
        console.log('load_procedureGroups_LOV');
        const demodata = [
            'STANDARD',
            'CROSSBRAND',
            'AHL',
            'UHC_VIP',
            'OU',
            'INTERNAL',
            'TERMINATED'
        ];
        const lov = demodata.map(v => ({ value: v, label: v }))
        // sort ASC
        lov.sort((a, b) => (a.label > b.label) ? 1 : -1)

        set_procedureGroups_LOV(lov)
    }

    const load_stayTypes_LOV = () => {
        console.log('load_stayTypes_LOV');

        const demo_stayTypes = [
            {
                value: 'SAME_HOTEL',
                label: 'SAME HOTEL',
            },
            {
                value: 'ENROLMENT',
                label: 'ENROLMENT',
            },
            {
                value: 'CROSS_BRAND',
                label: 'CROSS BRAND',
            },
            /* {
                value: 'ENROLMENT_VIP_ONLY',
                label: 'ENROLMENT VIP ONLY',
            }, */
            {
                value: 'INTRA_GROUP',
                label: 'INTRA GROUP'
            },
            {
                value: 'INTRA_BRAND',
                label: 'INTRA BRAND'
            },
        ];
        const lov = demo_stayTypes;

        set_stayTypes_LOV(lov)
    }

    // const set_selected_tab = (tabId) => dispatch({ type: 'SET_SELECTED_TAB', data: tabId })

    const set_complete_map = (map) => {
        dispatch({ type: 'SET_COMPLETE_MAP', data: map })
    }
    const set_brands_LOV = (lov) => {
        dispatch({ type: 'SET_BRANDS_LOV', data: lov })
    }
    const set_procedureGroups_LOV = (lov) => {
        dispatch({ type: 'SET_PROCEDURE_GROUPS_LOV', data: lov })
    }
    const set_stayTypes_LOV = (lov) => {
        dispatch({ type: 'SET_STAY_TYPES_LOV', data: lov })
    }
    const set_exceptionCodes_LOV = (lov) => {
        // console.log('set_exceptionCodes_LOV lov:', lov);
        dispatch({ type: 'SET_EXCEPTION_CODES_LOV', data: lov })
    }

    const set_exceptionCodes_for_CREATE_EXC_LOV = (lov) => {
        // console.log('set_exceptionCodes_for_CREATE_EXC_LOV lov:', lov);
        dispatch({ type: 'EXCEPTION_CODES_FOR_CREATE_EXC_LOV', data: lov })
    }

    const set_billingGroups_LOV = (lov) => dispatch({ type: 'SET_BILLING_GROUPS_LOV', data: lov })
    // const set_property_LOV = (lov) => dispatch({ type: 'SET_PROPERTY_LOV', data: lov })
    const set_resorts_LOV = (lov) => dispatch({ type: 'SET_RESORTS_LOV', data: lov })
    const set_resorts_from_exceptions_LOV = (lov) => dispatch({ type: 'SET_RESORTS_FROM_EXCEPTIONS_LOV', data: lov })
    const set_chain_codes_LOV = (lov) => dispatch({ type: 'SET_CHAIN_CODES_LOV', data: lov })

    const set_brands = brands => dispatch({ type: 'SET_BRANDS', data: brands })
    const set_rules = rules => dispatch({ type: 'SET_BILLING_SETUP_RULES', data: rules })
    const set_exceptions = exceptions => dispatch({ type: 'SET_EXCEPTIONS', data: exceptions })

    const set_isLoading = isLoading => dispatch({ type: 'SET_IS_LOADING', data: isLoading })
    const set_isLoading_LOV = isLoading => dispatch({ type: 'SET_IS_LOADING_LOV', data: isLoading })
    const set_lastError = errors => dispatch({ type: 'SET_LAST_ERROR', data: errors })

    return {
        state,
        dispatch,

        // set_selected_tab,
        set_isLoading,
        set_lastError,
        // set_brands_LOV,
        set_billingGroups_LOV,
        // add_brands,
        load_LOVs,
        load_brands,
        loadSetupsFromBackend,
        loadExceptionsFromBackend,

        // set_rules,
        // set_property_LOV,
        set_exceptions,

        load_procedureGroups_LOV,
        load_stayTypes_LOV,
        // load_exceptionCodes_LOV,
    }
}

export { GlobalAppStateProvider, useGlobalAppState }
