import {
    Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel,
    FormGroup, FormLabel, Input, InputLabel, LinearProgress, MenuItem, Select, Typography, IconButton, TextField
} from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { insertBrand } from '../../api/api';
import { AuthService } from '../../api/auth';
import { MyFabCancel, MyFabSubmit } from '../../comps/MyFab';
import { useWhyDidYouUpdate } from '../../hooks/whyDidYouUpdate';
import { useGlobalAppState } from '../../state/myGlobalContext';
import { useStyles_dialog } from '../../theme/dialogStyles';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import { ErrorsAlert } from '../../comps/ErrorsAlert';

export default function AddBrandDialog(props) {

    console.log('AddBrandDialog render, props:', props);

    useWhyDidYouUpdate('AddBrandDialog', props)

    const { open, onClose } = props;
    const classes = useStyles_dialog();
    const [lastError, set_lastError] = useState([]);
    const [entryValues, set_entryValues] = useState({
        chain_code: '',
        billing_group: '',
        procedure_name: '',
        exception_codes_enrollment: 'N',
        exception_codes_repeat: 'N',
        billing_group_desc: '',
        insert_user: AuthService.userData.email,
    });

    const [toOpenAddBillingGroupDialog, set_toOpenAddBillingGroupDialog] = useState(false);
    const [addBillingGroupValues, set_addBillingGroupValues] = useState({
        // billing_group: '',
        // billing_group_desc: '',
        bg_name: '',
        bg_desc: '',
    });

    const {
        state: { brands_LOV, billingGroups_LOV, isLoading_LOV, procedureGroups_LOV },
        load_LOVs, load_procedureGroups_LOV, set_billingGroups_LOV
    } = useGlobalAppState()

    // LOAD DATA USING GLOBAL STATE 
    // to prevent reloading after state is set with brands LOV
    // use callback so load_LOVs_cb stays the same reference, 
    const load_LOVs_cb = useCallback(load_LOVs, [])
    const load_procedureGroups_LOV_cb = useCallback(load_procedureGroups_LOV, []);

    useEffect(() => {
        if (open) {
            load_LOVs_cb();
            load_procedureGroups_LOV_cb();
        }
    }, [open, load_LOVs_cb, load_procedureGroups_LOV_cb]);

    const handleChangeEntry = event => {
        const targetName = event.target.name;
        const targetValue = event.target.value;
        console.log('name value:', targetName, targetValue);

        // if billin gr is changed, fetch related bil gr desc
        if (targetName === 'billing_group') {

            console.log('got bg LOV', billingGroups_LOV);

            const obj = billingGroups_LOV.find(item => item.value === targetValue)
            console.log('got bg obj', obj);
            const new_billing_group_desc = obj ? obj.label : 'n/a';

            set_entryValues(prev => ({ ...prev, billing_group_desc: new_billing_group_desc }))
        }

        set_entryValues(prev => ({ ...prev, [targetName]: targetValue }))
    };

    const handleChangeEntry_CheckBox = event => {
        const targetName = event.target.name;
        const targetValue = event.target.checked ? 'Y' : 'N';
        console.log('name value:', targetName, targetValue);

        set_entryValues(prev => ({ ...prev, [targetName]: targetValue }))
    };

    const handleSubmit = event => {
        console.log('handleSubmit:', entryValues);

        insertBrand(entryValues)
            .then(resp => {
                console.log('insertBrand resp:', resp);
                onClose(true)
            }
            ).catch(err => {
                console.error('insertBrand err:', err);
                set_lastError(prev => { return [...prev, err] });
            });
    };

    const onClickedNewBillingGroup = () => {
        // show dialog
        set_toOpenAddBillingGroupDialog(true)
    }
    const onClickedNewBillingGroup_close = (toAddBG) => {

        console.log('onClickedNewBillingGroup_close:', toAddBG);

        // hide dialog
        set_toOpenAddBillingGroupDialog(false)

        if (!toAddBG) {
            return;
        }

        // read new bill gr, add it in the list, make it selected
        // ..
        console.log('onClickedNewBillingGroup_close got vals:', addBillingGroupValues);

        // add it into bg LOV - only temporarely while dilaog is opened, as later fecth will remove it if not new bg semnt to server
        const newbgs = billingGroups_LOV;
        newbgs.push({
            // billing_group: addBillingGroupValues.bg_name,
            // billing_group_desc: addBillingGroupValues.bg_desc
            value: addBillingGroupValues.bg_name,
            label: addBillingGroupValues.bg_desc
        })
        console.log('newbgs:', newbgs);
        set_billingGroups_LOV(newbgs)

        // make it selected 
        set_entryValues(prev => ({
            ...prev,
            billing_group: addBillingGroupValues.bg_name,
            billing_group_desc: addBillingGroupValues.bg_desc
        }))
    }

    const handleChange_addBG_Entry = event => {
        const targetName = event.target.name;
        const targetValue = event.target.value;
        console.log('name value:', targetName, targetValue);

        set_addBillingGroupValues(prev => ({ ...prev, [targetName]: targetValue }))
    };


    return (
        <Dialog
            fullWidth maxWidth="md"
            open={open}
            onClose={() => onClose(false)}
        >

            <DialogTitle disableTypography>
                <Typography variant="h5" color="primary">ADD BRAND</Typography>

                <ErrorsAlert lastError={lastError} set_lastError={set_lastError} />
            </DialogTitle>

            <div style={{ height: '12px' }}>
                {
                    isLoading_LOV && <LinearProgress />
                }
            </div>

            <DialogContent className={classes.dialogContentMinHeight}>

                <DialogContentText>
                    You may add a BRAND that was previously configured in Opera and link them to the available BILLING GROUP.<br />
                    The PROCESS GROUP determines if any specifics apply in terms of how you want to collect DISCOVERY transactional data.<br />
                    Define whether EXCEPTIONS are to be considered for this brand during enrolment or repeat stays.
            </DialogContentText>

                <FormControl className={classes.formControl} fullWidth>
                    <InputLabel>BRAND</InputLabel>
                    <Select
                        value={entryValues.chain_code}
                        onChange={handleChangeEntry}
                        input={<Input name="chain_code" />}
                    >
                        <MenuItem value=""><em>None</em></MenuItem>
                        {
                            brands_LOV.map((b, i) => {
                                return <MenuItem value={b.value} key={i}>{b.label}</MenuItem>
                            })
                        }
                    </Select>
                </FormControl>

                <div className={classes.formGroup_row}>
                    <FormControl className={classes.formControl}
                        fullWidth
                    >
                        <InputLabel>BILLING GROUP</InputLabel>
                        <Select
                            value={entryValues.billing_group}
                            onChange={handleChangeEntry}
                            input={<Input name="billing_group" />}
                        >
                            <MenuItem value=""><em>None</em></MenuItem>
                            {
                                billingGroups_LOV.map((b, i) => {
                                    return <MenuItem value={b.value} key={i}>{b.label}</MenuItem>
                                })
                            }
                        </Select>
                        {/* <IconButton>
                            <ControlPointIcon />
                        </IconButton> */}
                    </FormControl>

                    <IconButton title="Add new billing group" onClick={onClickedNewBillingGroup}>
                        <ControlPointIcon />
                    </IconButton>

                    {/* <IconButton>edit</IconButton> */}
                </div>

                <FormControl className={classes.formControl} fullWidth>
                    <InputLabel>PROCESS GROUP</InputLabel>
                    <Select
                        value={entryValues.procedure_name}
                        onChange={handleChangeEntry}
                        input={<Input name="procedure_name" />}
                    >
                        <MenuItem value=""><em>None</em></MenuItem>
                        {
                            // demo_procedureGroup.map((b, i) => {
                            procedureGroups_LOV.map((b, i) => {
                                return <MenuItem value={b.value} key={i}>{b.label}</MenuItem>
                            })
                        }
                    </Select>
                </FormControl>

                <FormGroup className={classes.formGroup_enrollment}>
                    <FormLabel component="p">ENROLLMENT CODE EXCEPTIONS APPLY DURING:</FormLabel>
                    <FormControlLabel
                        label={<Typography color="primary">ENROLLMENT STAY</Typography>}
                        control={
                            <Checkbox
                                name="exception_codes_enrollment"
                                checked={entryValues.exception_codes_enrollment === 'Y'}
                                onChange={handleChangeEntry_CheckBox}
                            />
                        }
                    />
                    <FormControlLabel
                        label={<Typography color="primary">REPEAT STAY</Typography>}
                        control={
                            <Checkbox
                                name="exception_codes_repeat"
                                checked={entryValues.exception_codes_repeat === 'Y'}
                                onChange={handleChangeEntry_CheckBox}
                            />
                        }
                    />
                </FormGroup>

                {/*  <pre>
                    {JSON.stringify(entryValues, null, 2)}
                </pre> */}

            </DialogContent>

            <DialogActions>
                <MyFabCancel onClick={() => onClose(false)} />
                <MyFabSubmit onClick={() => handleSubmit()} />
            </DialogActions>






            <Dialog open={toOpenAddBillingGroupDialog} onClose={() => onClickedNewBillingGroup_close(false)}>
                <DialogTitle>NEW BILLING GROUP</DialogTitle>
                <DialogContent>
                    {/* <DialogContentText>E</DialogContentText> */}
                    <TextField
                        autoFocus
                        // margin="dense"
                        name="bg_name"
                        label="BILLING GROUP NAME"
                        type="text"
                        fullWidth
                        onChange={handleChange_addBG_Entry}
                    />
                    <TextField
                        // autoFocus
                        // margin="dense"
                        name="bg_desc"
                        label="BILLING GROUP DESCRIPTION"
                        type="text"
                        fullWidth
                        onChange={handleChange_addBG_Entry}
                    />
                </DialogContent>
                <DialogActions>
                    <MyFabCancel onClick={() => onClickedNewBillingGroup_close(false)} />
                    <MyFabSubmit onClick={() => onClickedNewBillingGroup_close(true)} />
                </DialogActions>
            </Dialog>



        </Dialog>
    );
}
