export const myTableStyles = theme => ({
    flexContainer: {
        display: 'flex',
        alignItems: 'center',
        boxSizing: 'border-box',
    },
    table: {
        // temporary right-to-left patch, waiting for
        // https://github.com/bvaughn/react-virtualized/issues/454
        '& .ReactVirtualized__Table__headerRow': {
            flip: false,
            // paddingRight: theme.direction === 'rtl' ? '0px !important' : undefined,
        },
    },
    tableRow: {
        cursor: 'pointer',
        /* '&:hover': {
            backgroundColor: theme.palette.grey[200],
        }, */
    },
    tableRowHover: {
        '&:hover': {
            // backgroundColor: theme.palette.grey[200],
            backgroundColor: '#cccccc55',

        },
    },
    tableRowSelected: {
        backgroundColor: '#e0e0e044',
    },
    tableRowHeader: {
        // backgroundColor: '#4499bb !important',
        /*   border: '1px solid red',
          // marginRight: 12,
          // paddingRight: '21px', // scroller. if not: header cells are not aligned with other rows
          backgroundColor: 'var(--main-text-color)', */
    },
    tableCell: {
        flex: 1,
        lineHeight: 1,
    },
    noClick: {
        cursor: 'initial',
    },

    //adding
    tableCellHeader: {
        // backgroundColor: theme.palette.common.black,

        // backgroundColor: 'var(--main-text-color)',
        // color: theme.palette.common.white,

        '&:hover': {
            // color: theme.palette.grey[800],
        },
        fontSize: 13,
        lineHeight: 1.1,
        fontWeight: 'bold',
    },

    // sort label span
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
      },
});