import { AuthService } from "./auth";
import { API_GATEWAY_URL, DATA_API_URL } from "../constants";
import { parseJwt } from "./login";
import { isJson } from "../utils/utils";
// import { demo_rules_resp } from "./temp-rules-resp";

function isTokenExpired(token) {
    try {
        const date = new Date(0);
        const decoded = parseJwt(token);
        date.setUTCSeconds(decoded.exp);
        return date.valueOf() < new Date().valueOf();
    } catch (err) {
        return err;
    }
};

/**
 * Should get/load localy stored token if yet not stored/received/expired, renew if expired.
 */
async function getTokenForGHAAPI() {
    // check for existing one
    if (AuthService.dataAPI_TokenData && AuthService.dataAPI_TokenData.access_token) {
        const isValid = !isTokenExpired(AuthService.dataAPI_TokenData.access_token);
        if (isValid) {
            // console.warn('data API token has not expired')
            return AuthService.dataAPI_TokenData.access_token;
        } else {
            console.warn('data API: token expired')
        }
    }

    // check for OAuth/openid protected API access token
    const accesstoken = sessionStorage.getItem('token');

    if (!accesstoken) {
        console.error('getTokenForGHAAPI cant get tokens');
        return;
    }

    const url = API_GATEWAY_URL + '/getTokenForDataAPI';
    const requestOptions = {
        method: 'GET',
        headers: {
            "Authorization": "Bearer " + accesstoken
        },
    };

    try {
        const response = await fetch(url, requestOptions);
        const resJSON = await response.json();
        // console.log('getTokenForGHAAPI resp:', resJSON);
        // from AWS: {"message":"Unauthorized"}
        if (response.status === 401) {
            AuthService.logout();
        }

        const resJSONResult = JSON.parse(resJSON.result)
        console.log('getTokenForGHAAPI resp:', resJSONResult);
        AuthService.dataAPI_TokenData = resJSONResult;
    } catch (error) {
        console.error('getTokenForGHAAPI error:', error)
    }
}

// maybe move this to hook ? 
async function doCall(url, init) {
    // throw new Error("this is error TEST.");

    // get token if not yet
    await getTokenForGHAAPI();
    // add auth to header
    // "Authorization": "Bearer " + AuthService.dataAPI_TokenData.access_token,
    init.headers.Authorization = "Bearer " + AuthService.dataAPI_TokenData.access_token;

    const response = await fetch(url, init);

    if (!response.ok) {
        // throw new Error("responses not ok: " + JSON.stringify(response.statusText, null, 2));
        // throw new Error("responses not ok: " + await response.text() + ' statusText:' + response.statusText);

        const resText = await response.text();

        if (isJson(resText)) {
            // const resJSON = await response.json();
            const resJSON = JSON.parse(resText);

            if (resJSON.ERROR) {
                // throw new Error("error from API: " + JSON.stringify(resJSON.ERROR));
                // throw new Error(JSON.stringify(resJSON.ERROR));
                console.log('got field - ERROR:', resJSON.ERROR);

                throw new Error(resJSON.ERROR);
            } 
            else if(resJSON.message) {
                console.log('got field - message:', resJSON.ERROR);

                throw new Error(resJSON.message);
            }
            else {
                console.warn('should not happen? res is JSON but no ERROR prop.')
            }
        }
        else {
            // backend 403 404 error should be catched here
            // throw new Error(await response.text());
            throw new Error(resText);
        }
    }

    const resJSON = await response.json();
    // console.log('resJSON:', resJSON);

    // check if err from AWS
    // example: error: "Access token was not provided"
    if (resJSON.error) {
        // throw new Error("error from API backend: " + JSON.stringify(resJSON.error));
        // throw new Error(JSON.stringify(resJSON.error));
        throw new Error(resJSON.error);
    }

    // check if err in response - uppercase ERROR field
    if (resJSON.ERROR) {
        // throw new Error("error from API: " + JSON.stringify(resJSON.ERROR));
        // throw new Error(JSON.stringify(resJSON.ERROR));

        console.log('got ERROR field::', resJSON.ERROR);

        throw new Error(resJSON.ERROR);
    }
    return resJSON.RESPONSE;
}

//
// BRANDS
//

export async function getBrandsAndGroupsMap() {
    const url = DATA_API_URL + '/brand-mapping';
    const init = {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        method: 'GET',
    };
    return await doCall(url, init)
}
export async function getBrandsData() {
    const url = DATA_API_URL + '/brands';
    const init = {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        method: 'GET',
    };
    return await doCall(url, init)
}
export async function insertBrand(brandData) {
    console.log('insertBrand brandData:', brandData);
    const url = DATA_API_URL + '/brands';
    const init = {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(brandData)
    };
    return await doCall(url, init)
}

export async function updateBrand(brandData) {
    console.log('updateBrand brandData:', brandData);
    const url = DATA_API_URL + '/brands';
    const payload = {
        id: brandData.id,
        chain_code: brandData.chain_code,
        billing_group: brandData.billing_group,
        billing_group_desc: brandData.billing_group_desc,
        procedure_name: brandData.procedure_name,
        exception_codes_enrollment: brandData.exception_codes_enrollment,
        exception_codes_repeat: brandData.exception_codes_repeat,
        update_user: AuthService.userData.email,
    }
    const init = {
        method: 'PUT',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
    };
    return await doCall(url, init)
}

//
// BILLING SETUP - RULES
//
export async function getRulesItems() {
    // DEMO DATA:
    /* return new Promise((resolve, reject) => {
        resolve(demo_rules_resp.RESPONSE)
    }); */
    const url = DATA_API_URL + '/setup';
    const init = {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        method: 'GET',
    };
    return await doCall(url, init)
}
export async function insertRule(payload) {
    console.log('insertRule payload:', payload);
    const url = DATA_API_URL + '/codes';
    const init = {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(payload)
    };
    return await doCall(url, init)
}
export async function updateRule(payload) {
    console.log('updateRule payload:', payload);
    const url = DATA_API_URL + '/codes';
    const init = {
        method: 'PUT',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
    };
    return await doCall(url, init)
}

//
// LINKED ITEMS
//
/* export async function getLinkedItems() {
    return []
}
*/
export async function insertLinkedItem(payload) {
    console.log('insertLinkedItem payload:', payload);

    const url = DATA_API_URL + '/configuration';
    const init = {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(payload)
    };
    return await doCall(url, init)
}
export async function updateLinkedItem(payload) {
    console.log('updateLinkedItem payload:', payload);

    const url = DATA_API_URL + '/configuration';
    const init = {
        method: 'PUT',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
    };
    return await doCall(url, init)
}

//
// EXCEPTIONS
//
export async function getExceptionsData() {
    const url = DATA_API_URL + '/enr-exception';
    const init = {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        method: 'GET',
    };
    return await doCall(url, init)
}
export async function insertException(excData) {
    console.log('insertException data:', excData);
    const url = DATA_API_URL + '/enr-exception';
    const init = {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(excData)
    };
    return await doCall(url, init)
}
export async function updateException({
    id,
    enrollment_code,
    is_enrollment,
    is_repeat,
    update_user,
    inactive_date
}) {
    // console.log('updateException excData:', excData);
    const url = DATA_API_URL + '/enr-exception';
    /* {
        "id": 22598,
        "enrollment_code": "KIBUD",
        "is_enrollment": "N",
        "is_repeat": "N",
        "update_user": "someoneelse.voegele@nokantdo.com",
        "inactive_date": "2020-01-06" 
    } */
    const payload = {
        id,
        enrollment_code,
        is_enrollment,
        is_repeat,
        update_user,
        inactive_date
    }
    const init = {
        method: 'PUT',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
    };
    return await doCall(url, init)
}
