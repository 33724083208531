import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';

// pick a date util library
// import MomentUtils from '@date-io/moment';
import DateFnsUtils from '@date-io/date-fns';
import { GlobalAppStateProvider } from './state/myGlobalContext';
// import { GlobalStateProvider } from './state/myGlobalState';
// import { CountProvider } from './state/myGlobalContext';

// ReactDOM.render(<App />, document.getElementById('root'));
ReactDOM.render(
    <GlobalAppStateProvider>
        {/* <GlobalStateProvider> */}
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <App />
        </MuiPickersUtilsProvider>
        {/* </GlobalStateProvider> */}
    </GlobalAppStateProvider>
    ,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
