export function globalAppStateReducer(state, action) {
    switch (action.type) {
        case 'SET_SELECTED_TAB': {
            return {
                ...state,
                selectedTab: action.data
            }
        }

        case 'SET_COMPLETE_MAP': {
            return {
                ...state,
                complete_map: action.data
            }
        }
        case 'SET_BRANDS_LOV': {
            return {
                ...state,
                brands_LOV: action.data
            }
        }
        case 'SET_BRANDS': {
            return {
                ...state,
                brands: action.data
            }
        }

        case 'SET_BILLING_SETUP_RULES': {
            return {
                ...state,
                rules: action.data
            }
        }
        case 'SET_BILLING_GROUPS_LOV': {
            return {
                ...state,
                billingGroups_LOV: action.data
            }
        }

        case 'SET_PROPERTY_LOV': {
            return {
                ...state,
                property_LOV: action.data
            }
        }

        case 'SET_EXCEPTIONS': {
            return {
                ...state,
                exceptions: action.data
            }
        }

        case 'SET_IS_LOADING': {
            return {
                ...state,
                isLoading: action.data
            }
        }

        case 'SET_IS_LOADING_LOV': {
            return {
                ...state,
                isLoading_LOV: action.data
            }
        }

        case 'SET_LAST_ERROR': {
            return {
                ...state,
                lastError: action.data
            }
        }

        case 'SET_PROCEDURE_GROUPS_LOV': {
            return {
                ...state,
                procedureGroups_LOV: action.data
            }
        }
        case 'SET_STAY_TYPES_LOV': {
            return {
                ...state,
                stayTypes_LOV: action.data
            }
        }
        case 'SET_EXCEPTION_CODES_LOV': {
            return {
                ...state,
                exceptionCodes_LOV: action.data
            }
        }
        case 'EXCEPTION_CODES_FOR_CREATE_EXC_LOV': {
            return {
                ...state,
                exceptionCodes_for_CREATE_EXC_LOV: action.data
            }
        }
        
        case 'SET_RESORTS_LOV': {
            return {
                ...state,
                resorts_LOV: action.data
            }
        }
        case 'SET_RESORTS_FROM_EXCEPTIONS_LOV': {
            return {
                ...state,
                resorts_from_exceptions_LOV: action.data
            }
        }
        case 'SET_CHAIN_CODES_LOV': {
            return {
                ...state,
                chain_codes_LOV: action.data
            }
        }

        default: {
            throw new Error(`Unsupported action type: ${action.type}`)
        }
    }
}
