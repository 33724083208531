import { Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormGroup, FormLabel, Input, InputLabel, LinearProgress, MenuItem, Select, Typography } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { updateBrand } from '../../api/api';
// import { updateBrand } from '../../api/api';
import { AuthService } from '../../api/auth';
import { ErrorsAlert } from '../../comps/ErrorsAlert';
import { MyFabCancel, MyFabSubmit } from '../../comps/MyFab';
import { useWhyDidYouUpdate } from '../../hooks/whyDidYouUpdate';
import { useGlobalAppState } from '../../state/myGlobalContext';
import { useStyles_dialog } from '../../theme/dialogStyles';

export default function EditBrandDialog(props) {

    console.log('EditBrandDialog render, props:', props);

    useWhyDidYouUpdate('EditBrandDialog', props)

    const { open, onClose, editBrandItem } = props;
    const classes = useStyles_dialog();
    const {
        state: { billingGroups_LOV, isLoading_LOV, procedureGroups_LOV },
        load_LOVs, load_procedureGroups_LOV
    }
        = useGlobalAppState()
    const [lastError, set_lastError] = useState([]);
    const [entryValues, set_entryValues] = useState({

        // id, chain_code and chain_code_desc are not edited, but used for API payload
        id: 0,
        chain_code: '',
        chain_code_desc: '',

        billing_group: '',
        billing_group_desc: '',
        procedure_name: '',
        exception_codes_enrollment: 'N',
        exception_codes_repeat: 'N',
        update_user: AuthService.userData.email,
    });

    const load_LOVs_cb = useCallback(load_LOVs, []);
    const load_procedureGroups_LOV_cb = useCallback(load_procedureGroups_LOV, []);

    useEffect(() => {
        if (open) {
            load_LOVs_cb()
            load_procedureGroups_LOV_cb()
        }
        // return () => { console.log('EditBrandDialog componentWillUnmount'); }
    }, [open, load_LOVs_cb, load_procedureGroups_LOV_cb]);

    useEffect(() => {
        const pi = editBrandItem;
        if (pi) {
            // IT IS EDITING MODE
            set_entryValues(entryValues => (
                {
                    ...entryValues, // it gets ID and others if any
                    id: pi.id,
                    chain_code: pi.chain_code,
                    chain_code_desc: pi.chain_code_desc,
                    billing_group: pi.billing_group,
                    billing_group_desc: pi.billing_group_desc,
                    procedure_name: pi.procedure_name,
                    exception_codes_enrollment: pi.exception_codes_enrollment,
                    exception_codes_repeat: pi.exception_codes_repeat,
                })
            )
        }
        return () => { };
    }, [editBrandItem]);

    const handleChangeEntry = event => {
        const targetName = event.target.name;
        const targetValue = event.target.value;
        console.log('name value:', targetName, targetValue);

        // if billin gr is changed, fetch related bil gr desc
        if (targetName === 'billing_group') {

            console.log('got bg LOV', billingGroups_LOV);

            const obj = billingGroups_LOV.find(item => item.value === targetValue)
            console.log('got bg obj', obj);
            const new_billing_group_desc = obj ? obj.label : 'n/a';

            set_entryValues(prev => ({ ...prev, billing_group_desc: new_billing_group_desc }))
        }

        set_entryValues(prev => ({ ...prev, [targetName]: targetValue }))
    };

    const handleChangeEntry_CheckBox = event => {
        const targetName = event.target.name;
        const targetValue = event.target.checked ? 'Y' : 'N';
        console.log('name value:', targetName, targetValue);

        set_entryValues(prev => ({ ...prev, [targetName]: targetValue }))
    };

    const handleSubmit = event => {
        console.log('handleSubmit:', entryValues);

        updateBrand(entryValues)
            .then(resp => {
                console.log('updateBrand resp:', resp);
                onClose(true)
            }
            ).catch(err => {
                console.error('updateBrand err:', err);
                set_lastError(prev => { return [...prev, err] });
            });
    };

    return (
        <Dialog
            fullWidth maxWidth="md"
            open={open}
            onClose={() => onClose(false)}
        >

            <DialogTitle disableTypography>
                <Typography variant="h5" color="primary">EDIT BRAND (ID: {entryValues.id})</Typography>

                <ErrorsAlert lastError={lastError} set_lastError={set_lastError} />
            </DialogTitle>

            <div style={{ height: '12px' }}>
                {
                    isLoading_LOV && <LinearProgress />
                }
            </div>

            <DialogContent>
                {
                    // process.env.NODE_ENV === 'development' && 
                    // <p>DEBUG: editing id: {entryValues.ID}</p>
                }
                <FormControl className={classes.formControl} fullWidth>
                    <InputLabel>BRAND</InputLabel>
                    <Input disabled
                        name="chain_code_desc"
                        value={entryValues.chain_code_desc}
                    />
                </FormControl>

                <FormControl className={classes.formControl} fullWidth>
                    <InputLabel>BILLING GROUP</InputLabel>
                    <Select
                        value={entryValues.billing_group}
                        onChange={handleChangeEntry}
                        input={<Input name="billing_group" />}
                    >
                        <MenuItem value=""><em>None</em></MenuItem>
                        {
                            billingGroups_LOV.map((b, i) => {
                                return <MenuItem value={b.value} key={i}>{b.label}</MenuItem>
                            })
                        }
                    </Select>
                </FormControl>

                <FormControl className={classes.formControl} fullWidth>
                    <InputLabel>PROCESS GROUP</InputLabel>
                    <Select
                        value={entryValues.procedure_name}
                        onChange={handleChangeEntry}
                        input={<Input name="procedure_name" />}
                    >
                        <MenuItem value=""><em>None</em></MenuItem>
                        {
                            // demo_procedureGroup.map((b, i) => {
                            procedureGroups_LOV.map((b, i) => {
                                return <MenuItem value={b.value} key={i}>{b.label}</MenuItem>
                            })
                        }
                    </Select>
                </FormControl>

                <FormGroup className={classes.formGroup_enrollment}>
                    <FormLabel component="p">ENROLLMENT CODE EXCEPTIONS APPLY DURING:</FormLabel>
                    <FormControlLabel
                        label={<Typography color="primary">ENROLLMENT STAY</Typography>}
                        control={
                            <Checkbox
                                name="exception_codes_enrollment"
                                checked={entryValues.exception_codes_enrollment === 'Y'}
                                onChange={handleChangeEntry_CheckBox}
                            />
                        }
                    />
                    <FormControlLabel
                        label={<Typography color="primary">REPEAT STAY</Typography>}
                        control={
                            <Checkbox
                                name="exception_codes_repeat"
                                checked={entryValues.exception_codes_repeat === 'Y'}
                                onChange={handleChangeEntry_CheckBox}
                            />
                        }
                    />
                </FormGroup>

                {/*  <pre>
                    {JSON.stringify(entryValues, null, 2)}
                </pre> */}

            </DialogContent>

            <DialogActions>
                <MyFabCancel onClick={() => onClose(false)} />
                <MyFabSubmit onClick={() => handleSubmit()} />
            </DialogActions>
        </Dialog>
    );
}
