import React, { useState, useCallback, useEffect } from 'react';
import {
    Dialog, DialogActions, DialogContent, DialogTitle, FormControl,
    FormControlLabel, Input, InputLabel, MenuItem, Radio, RadioGroup, Select,
    Typography, LinearProgress, Box, TextField, DialogContentText, Chip, Checkbox
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { useGlobalAppState } from '../../state/myGlobalContext';
import { MyFabCancel, MyFabSubmit, MyFabCopy } from '../../comps/MyFab';
import { useStyles_dialogLink } from '../../theme/dialogStyles';
import VirtualizedSelect from '../../comps/AutoCompleteComp';
import { useWhyDidYouUpdate } from '../../hooks/whyDidYouUpdate';
import { AuthService } from '../../api/auth';
import { updateLinkedItem, insertLinkedItem } from '../../api/api';
import { getFormattedDate, isDateValid } from '../../utils/utils';
import moment from 'moment'
import { ErrorsAlert } from '../../comps/ErrorsAlert';
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

export default function EditLinkDialog(props) {

    console.log('EditLinkDialog props:', props);

    useWhyDidYouUpdate('EditLinkDialog', props)

    const classes = useStyles_dialogLink();
    const {
        state: { brands_LOV, stayTypes_LOV, isLoading_LOV, resorts_LOV },
        load_LOVs, load_stayTypes_LOV
    } = useGlobalAppState();
    const { open, onClose, selectedRule, editLinkItem } = props;
    const [lastError, set_lastError] = useState([]);
    const [isCopyInProcess, set_isCopyInProcess] = useState(false);

    /* selectedRule:
    amt: "7.5"
    base: "ROOM_REVENUE"
    begin_date: "01-JAN-20"
    calc_id: 13
    cap_nights: 28
    cap_revenue: null
    chain: "AV"
    chain_desc: "AVANI"
    currency: ""
    daily_yn: ""
    description: "CROSS_BRAND"
    end_date: "31-DEC-20"
    fee_type: "PERCENTAGE"
    id: 20923
    no_rules_defined: 0
    resort: "AVVATR"
    resort_desc: "Avani Atrium Bangkok"
    stay_type: "CROSS_BRAND"
    vip_yn: "" */
    const [entryValues, set_entryValues] = useState({

        id: selectedRule.id || '',

        calc_id: selectedRule.calc_id || '',
        chain_code: selectedRule.chain || '',

        calc_desc: selectedRule.description || '',

        // once written upon dialog open, non editable
        ruleName: selectedRule.description || '',
        fee_type: selectedRule.fee_type || '',
        base: selectedRule.base || '',
        amount: selectedRule.amt || '',
        is_vip: selectedRule.vip_yn, // === 'Y' ? 'Y' : 'N',


        /* resortOrBrand: 'resort',
        resort: selectedRule.resort || '',
        brand: selectedRule.brand || '',

        // stayTypes: [selectedRule.status],
        stayTypes: selectedRule.stay_type ? [selectedRule.stay_type] : [],

        dateFrom: selectedRule.begin_date,
        dateTo: selectedRule.end_date, */

        // if BRAND: resort is '' and chain_code is chain
        // if RESORT: resort is resort and chain_code is chain
        //


        resortOrBrand: editLinkItem && editLinkItem.resort === '' ? 'brand' : 'resort',
        resort: editLinkItem && editLinkItem.resort ? editLinkItem.resort : '',
        brand: editLinkItem && editLinkItem.chain ? editLinkItem.chain : '',

        // stayTypes: [selectedRule.status],
        stayTypes: editLinkItem && editLinkItem.stay_type ? [editLinkItem.stay_type] : [],


        dateFrom: '', // editLinkItem && editLinkItem.begin_date ? moment(editLinkItem.begin_date, 'DD-MMM-YYYY') : '',
        dateTo: '', //editLinkItem && editLinkItem.end_date ? moment(editLinkItem.end_date, 'DD-MMM-YYYY') : '',

        begin_date: editLinkItem && editLinkItem.begin_date ? editLinkItem.begin_date : '',
        end_date: editLinkItem && editLinkItem.end_date ? editLinkItem.end_date : '',

        update_user: AuthService.userData.email,
    });

    useEffect(() => {

        const bd = editLinkItem && editLinkItem.begin_date ? moment(editLinkItem.begin_date, 'DD-MMM-YYYY').toDate() : ''
        const ed = editLinkItem && editLinkItem.end_date ? moment(editLinkItem.end_date, 'DD-MMM-YYYY').toDate() : ''

        // getFormattedDate

        set_entryValues(prev => ({
            ...prev,

            resortOrBrand: editLinkItem && editLinkItem.resort === '' ? 'brand' : 'resort',
            resort: editLinkItem && editLinkItem.resort ? editLinkItem.resort : '',
            brand: editLinkItem && editLinkItem.chain ? editLinkItem.chain : '',

            // stayTypes: [selectedRule.status],
            stayTypes: editLinkItem && editLinkItem.stay_type ? [editLinkItem.stay_type] : [],

            dateFrom: editLinkItem && editLinkItem.begin_date ? moment(editLinkItem.begin_date, 'DD-MMM-YYYY') : '',
            dateTo: editLinkItem && editLinkItem.end_date ? moment(editLinkItem.end_date, 'DD-MMM-YYYY') : '',

            // begin_date: editLinkItem && editLinkItem.begin_date ? editLinkItem.begin_date : '',
            // end_date: editLinkItem && editLinkItem.end_date ? editLinkItem.end_date : '',
            begin_date: getFormattedDate(bd),
            end_date: getFormattedDate(ed),
        }))

        return () => { }
    }, [editLinkItem]);


    const load_LOVs_cb = useCallback(load_LOVs, []);
    const load_stayTypes_LOV_cb = useCallback(load_stayTypes_LOV, []);

    useEffect(() => {
        load_LOVs_cb()
        load_stayTypes_LOV_cb()
        return () => { }
    }, [load_LOVs_cb, load_stayTypes_LOV_cb]);

    const handleChangeEntry = event => {
        const targetName = event.target.name;
        const targetValue = event.target.value;
        console.log('name value:', targetName, targetValue);

        // submit RESORT value corresponding to the selected RESORT_NAME. If BRAND was selected then submit null “”
        if (targetName === 'resortOrBrand') {

            if (targetValue === 'brand') {
                // set_entryValues(prev => ({ ...prev, resort: null, chain_code: selectedRule.chain }))
                set_entryValues(prev => ({ ...prev, resort: '', chain_code: selectedRule.chain }))
            } else {

                // get chain code per targetValue
                /* const resortsList = getResortsForChain(selectedRule.chain).map((b, i) => {
                    // console.log('b:', b);
                    return <MenuItem value={b.value} key={i}>{b.label}</MenuItem>
                }) */

                // set_entryValues(prev => ({ ...prev, resort: targetValue, chain_code: '' }))
                set_entryValues(prev => ({ ...prev, resort: entryValues.resort, chain_code: entryValues.chain_code }))
            }
        }

        set_entryValues(prev => ({ ...prev, [targetName]: targetValue }))
    };

    const handleSubmit = async event => {
        // props.onSubmitRule()
        /* "id": 2,
           "calc_id": 38,
           "chain_code": "KI",
           "resort": "KIBUD",
           "status": "ENROLMENT",
           "begin_date": "2025-01-01",
           "end_date": "2025-12-31",
           "update_user": "daniel.voegele@nokantdo.com",
           "is_vip": "Y",
           "calc_desc": "TEST RULE DESCRIPTION" */

        let status = '';

        if (
            selectedRule.fee_type === 'FIXED_DAILY'
            || selectedRule.fee_type === 'FIXED_MONTHLY'
            || selectedRule.fee_type === 'FIXED_QUARTERLY'
            || selectedRule.fee_type === 'FIXED_YEARLY'
        ) {
            status = 'FIXED'
            //
            // CALL ONLY ONCE
            //
            const payload = {
                id: entryValues.id,
                calc_id: entryValues.calc_id,
                chain_code: entryValues.chain_code,
                resort: entryValues.resort,
                begin_date: entryValues.begin_date, //  "2020-01-01",
                end_date: entryValues.end_date, //"2020-12-31",
                is_vip: entryValues.is_vip,
                calc_desc: entryValues.calc_desc,
                status: status,
                update_user: AuthService.userData.email,
            }

            updateLinkedItem(payload)
                .then(resp => {
                    console.log('updateLinkedItem resp:', resp);
                    onClose(true)
                }
                ).catch(err => {
                    console.error('updateLinkedItem err:', err);
                    set_lastError(prev => { return [...prev, err] });
                });

        } else {
            // it is PCT or FLAT
            // read stay types. per each entry do one call

            let wasError = false;

            if (entryValues.stayTypes.length === 0) {
                console.log('updateLinkedItem return: no stayTypes');
                return;
            }

            const payloads = [];

            entryValues.stayTypes.forEach((stayType, i) => {
                const payload = {
                    id: entryValues.id,
                    calc_id: entryValues.calc_id,
                    chain_code: entryValues.chain_code,
                    resort: entryValues.resort,
                    begin_date: entryValues.begin_date, //  "2020-01-01",
                    end_date: entryValues.end_date, //"2020-12-31",
                    is_vip: entryValues.is_vip,
                    calc_desc: entryValues.calc_desc,
                    status: stayType,
                    update_user: AuthService.userData.email,
                }
                console.log('payload:', i, payload);
                payloads.push(payload)
            })

            await Promise.all(payloads.map((payload, index, array) => {
                console.log('Edit Link sendAll index:', index);
                // return insertLinkedItem(payload).then(resp => {
                return updateLinkedItem(payload).then(resp => {
                    console.log('updateLinkedItem resp:', resp);
                })
            })).catch(err => {
                console.error('Promise.all updateLinkedItem err:', err);
                wasError = true;
                set_lastError(prev => { return [...prev, err] });
            });

            console.log('DONE');

            if (!wasError) {
                onClose(true)
            }
        }
    };

    const handleChange_date = (d, inputName) => {
        console.log('handleChange_date a,b,c:', d, inputName);
        /* set_entryValues(prev => {
            return {
                ...prev,
                [inputName]: d
            }
        }) */

        // Date.

        // set date val to state
        set_entryValues(prev => ({ ...prev, [inputName]: d }))

        if (!isDateValid(d)) {
            return;
        }

        // save for payload formatted date
        if (inputName === 'dateFrom') {
            set_entryValues(prev => ({ ...prev, begin_date: getFormattedDate(d) }))
        }
        if (inputName === 'dateTo') {
            set_entryValues(prev => ({ ...prev, end_date: getFormattedDate(d) }))
        }
    }

    const [toOpenCopyLinkDialog, set_toOpenCopyLinkDialog] = useState(false);
    const [copyTarget_resorts, set_copyTarget_resorts] = useState([]);

    const handleCopy = event => {
        set_toOpenCopyLinkDialog(true)
        set_copyTarget_resorts([])
    }
    const onCopyLinkDialog_close = async (toCopy) => {
        console.log('onCopyLinkDialog_close toCopy:', toCopy);
        console.log('onCopyLinkDialog_close copyTarget_resorts:', copyTarget_resorts);

        if (!toCopy) {
            set_toOpenCopyLinkDialog(false)
            return;
        }

        if (copyTarget_resorts.length === 0) {
            console.log('onCopyLinkDialog_close return: no copyTarget_resorts');
            return;
        }

        set_isCopyInProcess(true);
        let wasError = false;
        const payloads = [];

        let status = '';

        if (selectedRule.fee_type === 'FIXED_DAILY'
            || selectedRule.fee_type === 'FIXED_MONTHLY'
            || selectedRule.fee_type === 'FIXED_QUARTERLY'
            || selectedRule.fee_type === 'FIXED_YEARLY'
        ) {
            status = 'FIXED'

            // call once per each target
            await Promise.all(copyTarget_resorts.map((targetObj, index) => {
                console.log('targetObj:', index, targetObj);

                const payload = {
                    calc_id: entryValues.calc_id,
                    chain_code: entryValues.chain_code,
                    resort: targetObj.property,
                    begin_date: entryValues.begin_date,
                    end_date: entryValues.begin_date,
                    is_vip: entryValues.is_vip,
                    calc_desc: entryValues.calc_desc,
                    status: status,
                    insert_user: AuthService.userData.email,
                }
                console.log('payload:', index, payload);
                payloads.push(payload)

                return insertLinkedItem(payload).then(resp => {
                    console.log('copy link insertLinkedItem resp:', resp);
                })

            })).catch(err => {
                console.error('Promise.all copy link err:', err);
                wasError = true;
                set_lastError(prev => { return [...prev, err] });
            });

            console.log('DONE');

            set_isCopyInProcess(false);

            if (!wasError) {
                set_toOpenCopyLinkDialog(false)
                onClose(true)
            } else {
                set_toOpenCopyLinkDialog(false)
            }
        }

        else {
            // it is PCT or FLAT
            // call onse per each stay type for each target

            let wasError = false;

            if (entryValues.stayTypes.length === 0) {
                console.log('insertLinkedItem return: no stayTypes');
                return;
            }

            const payloads = [];

            entryValues.stayTypes.forEach((stayType, i) => {

                copyTarget_resorts.forEach((targetObj, ind) => {

                    const payload = {
                        calc_id: entryValues.calc_id,
                        chain_code: entryValues.chain_code,
                        resort: targetObj.property,
                        begin_date: entryValues.begin_date,
                        end_date: entryValues.begin_date,
                        is_vip: entryValues.is_vip,
                        calc_desc: entryValues.calc_desc,
                        status: stayType,
                        insert_user: AuthService.userData.email,
                    }
                    console.log('payload:', i, payload);
                    payloads.push(payload)
                });
            });

            await Promise.all(payloads.map((payload, index, array) => {
                console.log('Copy Link sendAll index:', index);
                return insertLinkedItem(payload).then(resp => {
                    console.log('Copy link insertLinkedItem resp:', resp);
                })
            })).catch(err => {
                console.error('Promise.all copy link insertLinkedItem err:', err);
                wasError = true;
                set_lastError(prev => { return [...prev, err] });
            });

            console.log('DONE');

            set_isCopyInProcess(false);

            if (!wasError) {
                set_toOpenCopyLinkDialog(false)
                onClose(true)
            } else {
                set_toOpenCopyLinkDialog(false)
            }
        }
    }

    const getResortsWithSameChain = () => {
        return resorts_LOV.filter(val => val.chain_code === entryValues.chain_code);
    };

    const selectAllLabel = 'Select all';
    const getResortsWithSameChain_excludeSelf = () => {
        console.log('getResortsWithSameChain_excludeSelf');
        let filtered = getResortsWithSameChain().filter(val => val.value !== entryValues.resort);

        return [{ label: selectAllLabel, value: "select-all" }, ...filtered];
    };
    const copyResortOptions = getResortsWithSameChain_excludeSelf();

    const allSelected = copyTarget_resorts.length > 0 && (copyResortOptions.length - 1 === copyTarget_resorts.length);

    const getOptionLabel = (option) => option.label;

    const autoCompleteSelectAll_optionRenderer = (option, { selected }) => {
        const selectAllProps = option.value === "select-all" ? { checked: allSelected } : {};
        return (
            <>
                <Checkbox
                    color="primary"
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                    {...selectAllProps}
                />
                {getOptionLabel(option)}
            </>
        );
    };

    // simulate limitsTag when focused
    const autoCompleteSelectAll_renderTags = (value, getTagProps) => {
        /* const getCustomizedTagProps = (params) => ({
            className: clsx(classes.tag, {
              [classes.tagSizeSmall]: size === 'small',
            }),
            disabled,
            ...getTagProps(params),
          }); */
        const restCount = value.length > 5 ? value.length - 5 : 0;

        return (
            <>
                {
                    value
                        .filter(itm => itm.value !== 'select-all')
                        .slice(0, 5)
                        .map((option, index) => (
                            <span key={index}>

                                <Chip
                                    label={getOptionLabel(option)}
                                    //   size={size}
                                    //   {...getCustomizedTagProps({ index })}
                                    //   {...ChipProps}
                                    {...getTagProps({ index })}
                                // key={index}
                                />
                            </span>
                        ))
                }
                {
                    restCount > 0 &&
                    <Typography>+ {restCount} more</Typography>
                }
            </>
        );
    }

    const amountpreview = () => {
        const rule = selectedRule;
        if(rule.fee_type === "PCT") {
            if(rule.discovery1_yn && rule.discovery1_yn === 'Y'){
                return rule.amt;
            } else{
                return rule.amt_rooms + '% ROOMS / ' + rule.amt_non_rooms + '% NON ROOMS';
            }
        } 
        return rule.amt;
    }


    return (
        <Dialog
            fullWidth maxWidth="md"
            open={open}
            onClose={() => onClose(false)}
        >
            <DialogTitle disableTypography>
                <Typography variant="h5" color="primary">EDIT LINK</Typography>

                <ErrorsAlert lastError={lastError} set_lastError={set_lastError} />
            </DialogTitle>

            <div style={{ height: '12px' }}>
                {
                    isLoading_LOV && <LinearProgress />
                }
            </div>

            <DialogContent>

                <FormControl className={classes.formControl} fullWidth>
                    <InputLabel>RULE NAME</InputLabel>
                    <Input
                        name="ruleName"
                        value={entryValues.ruleName}
                        onChange={handleChangeEntry}
                        disabled
                    />
                </FormControl>

                <FormControl className={classes.formControl} fullWidth>
                    <InputLabel>FEE TYPE</InputLabel>
                    <Input
                        name="fee_type"
                        value={entryValues.fee_type}
                        onChange={handleChangeEntry}
                        disabled
                    />
                </FormControl>

                {/* <FormControl className={classes.formControl} fullWidth>
                    <InputLabel>BASED ON</InputLabel>
                    <Input
                        name="base"
                        value={entryValues.base}
                        onChange={handleChangeEntry}
                        disabled
                    />
                </FormControl> */}

                <FormControl className={classes.formControl} fullWidth>
                    <InputLabel>AMOUNT</InputLabel>
                    {/* value={entryValues.amount} */}
                    <Input
                        name="amount"
                        value={amountpreview()}
                        onChange={handleChangeEntry}
                        disabled
                    />
                </FormControl>

                <FormControl className={classes.formControl} fullWidth>
                    <InputLabel>VIP ONLY</InputLabel>
                    <Input
                        name="is_vip"
                        value={entryValues.is_vip}
                        onChange={handleChangeEntry}
                        disabled
                    />
                </FormControl>

                <div className={classes.formControlRadioGroup}>
                    <RadioGroup
                        name="resortOrBrand"
                        className={classes.formControlRadioGroup_horizontal}
                        value={entryValues.resortOrBrand}
                        onChange={handleChangeEntry}
                    >
                        <FormControlLabel
                            value="resort"
                            control={<Radio />}
                            label={<Typography color="primary">RESORT</Typography>}
                        />
                        <FormControlLabel
                            value="brand"
                            control={<Radio />}
                            label={<Typography color="primary">BRAND</Typography>}
                        />
                    </RadioGroup>
                </div>

                {
                    entryValues.resortOrBrand === 'resort' &&
                    <FormControl className={classes.formControl} fullWidth>
                        <InputLabel>RESORT</InputLabel>
                        <Select
                            value={entryValues.resort}
                            onChange={handleChangeEntry}
                            input={<Input name="resort" />}
                        >
                            <MenuItem value=""><em>None</em></MenuItem>
                            {
                                /* brands_LOV.map((b, i) => {
                                    return <MenuItem value={b.value} key={i}>{b.label}</MenuItem>
                                }) */

                                getResortsWithSameChain(selectedRule.chain).map((b, i) => {
                                    // console.log('b:', b);
                                    return <MenuItem value={b.value} key={i}>{b.label}</MenuItem>
                                })
                            }
                        </Select>
                    </FormControl>
                }

                {
                    entryValues.resortOrBrand === 'brand' &&
                    <FormControl className={classes.formControl} fullWidth>
                        <InputLabel>BRAND</InputLabel>
                        <Select
                            value={entryValues.brand}
                            onChange={handleChangeEntry}
                            input={<Input name="brand" />}
                        >
                            <MenuItem value=""><em>None</em></MenuItem>
                            {
                                brands_LOV.map((b, i) => {
                                    return <MenuItem value={b.value} key={i}>{b.label}</MenuItem>
                                })
                            }
                        </Select>
                    </FormControl>
                }



                {/* If FEE TYPE (which you will display on top) is PCT (PERCENTAGE) or FLAT then it will be visible. */}
                {
                    (entryValues.fee_type === 'PCT' || entryValues.fee_type === 'FLAT') &&
                    // (entryValues.fee_type === 'PERCENTAGE' || entryValues.fee_type === 'FLAT') &&

                    <FormControl className={classes.formControl} fullWidth>
                        <InputLabel>STAY TYPE</InputLabel>
                        <Select
                            multiple
                            MenuProps={{ variant: 'menu' }}
                            value={entryValues.stayTypes}
                            onChange={handleChangeEntry}
                            input={<Input name="stayTypes" />}
                        >
                            <MenuItem value=""><em>None</em></MenuItem>
                            {
                                stayTypes_LOV.map((b, i) => {
                                    return <MenuItem value={b.value} key={i}>{b.label}</MenuItem>
                                })
                            }
                        </Select>
                    </FormControl>
                }


                <div className={classes.formControlRadioGroup_horizontal}>

                    <FormControl className={classes.formControlFilter_horizontal_date} fullWidth>

                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="dd-MMM-yy"
                            name="dateFrom"
                            label="FROM DATE"
                            value={entryValues.dateFrom}
                            // onChange={handleChangeFilterVal_date}
                            onChange={date => handleChange_date(date, 'dateFrom')}

                            maskChar="-"
                            inputProps={{
                                style: {
                                    textTransform: "uppercase",
                                }
                            }}
                            refuse="/[^\d]+/gi"

                            invalidDateMessage="Expected Date Format DD-MON-YY"
                        />
                    </FormControl>

                    <FormControl fullWidth>
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="dd-MMM-yy"
                            name="dateTo"
                            label="TO DATE"
                            value={entryValues.dateTo}
                            onChange={date => handleChange_date(date, 'dateTo')}

                            maskChar="-"
                            inputProps={{
                                style: {
                                    textTransform: "uppercase",
                                }
                            }}
                            refuse="/[^\d]+/gi"

                            invalidDateMessage="Expected Date Format DD-MON-YY"
                        />
                    </FormControl>

                </div>

                {/* <pre>
                    {JSON.stringify(entryValues, null, 2)}
                </pre> */}

            </DialogContent>

            <DialogActions>
                <MyFabCancel onClick={() => onClose(false)} />
                {/* <MyFabSubmit onClick={() => handleSubmit()} /> */}
                <div>
                    <MyFabCopy onClick={() => handleCopy()} title="Copy" />
                    <Box component="span" m={1} />
                    <MyFabSubmit onClick={() => handleSubmit()} title="Submit" />
                </div>
            </DialogActions>






            <Dialog
                fullWidth maxWidth="sm"
                open={toOpenCopyLinkDialog}
                onClose={() => onCopyLinkDialog_close(false)}>
                <DialogTitle>COPY LINK</DialogTitle>

                <div style={{ height: '12px' }}>{isCopyInProcess && <LinearProgress />}</div>

                <DialogContent>
                    <DialogContentText>Selected resorts to copy link to.</DialogContentText>
                    <FormControl className={classes.formControl} fullWidth>
                        <VirtualizedSelect
                            multiple
                            name="resorts_copyTargets"
                            options={copyResortOptions}

                            renderInput={(params) => <TextField {...params} variant="standard" label="RESORTS" />}
                            getOptionLabel={getOptionLabel}
                            value={copyTarget_resorts}
                            onChange={(event, newValue) => {
                                console.log('copyTarget_resorts onChange event, newValue:', event, newValue);
                                // set_copyTarget_resorts(newValue)
                                if (newValue.find(itm => itm.value === 'select-all')) {
                                    if (allSelected) {
                                        // deselect all
                                        set_copyTarget_resorts([])
                                    } else {
                                        // select all
                                        set_copyTarget_resorts([...copyResortOptions.slice(1)])
                                        // set_copyTarget_resorts([...resortOptions])
                                    }
                                } else {
                                    set_copyTarget_resorts(newValue)
                                }
                            }}

                            renderOption={autoCompleteSelectAll_optionRenderer}
                            disableCloseOnSelect
                            // limitTags is active only when focused
                            limitTags={5}
                            getLimitTagsText={(more) => `${more}`}
                            openOnFocus
                            renderTags={autoCompleteSelectAll_renderTags}

                        />
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <MyFabCancel onClick={() => onCopyLinkDialog_close(false)} />
                    <MyFabSubmit onClick={() => onCopyLinkDialog_close(true)} title="Submit" />
                </DialogActions>
            </Dialog>




        </Dialog>
    );
}
