import React from 'react';
import { Fab } from '@material-ui/core';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import StarOutlineOutlinedIcon from '@material-ui/icons/StarOutlined';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';

export const MyFab = (props) => {
    return <Fab
        // color="secondary"
        style={{
            background: 'linear-gradient(45deg,#bec6c0 0,#99a49c 100%)',
            color: '#fff'
        }}
        size="medium"
        {...props}
    >
        <AddOutlinedIcon />
    </Fab>
}

export const MyFabSubmit = (props) => {
    return <Fab
        // color="secondary"
        style={{
            background: 'linear-gradient(45deg,#bec6c0 0,#99a49c 100%)',
            color: '#fff',
            opacity: props.disabled ? 0.5 : 1
        }}
        size="medium"
        {...props}
    >
        <StarOutlineOutlinedIcon />
    </Fab>
}

export const MyFabCancel = (props) => {
    return <Fab
        // color="secondary"
        style={{
            background: 'linear-gradient(45deg,#bec6c0 0,#99a49c 100%)',
            color: '#fff'
        }}
        size="small"
        {...props}
    >
        <ArrowBackIcon />
    </Fab>
}
export const MyFabCopy = (props) => {
    return <Fab
        // color="secondary"
        style={{
            background: 'linear-gradient(45deg,#bec6c0 0,#99a49c 100%)',
            color: '#fff'
        }}
        size="medium"
        {...props}
    >
        <FileCopyOutlinedIcon />
    </Fab>
}
/* export const MyFabBack = (props) => {
    return <Fab
        // color="secondary"
        style={{
            background: 'linear-gradient(45deg,#bec6c0 0,#99a49c 100%)',
            color: '#fff'
        }}
        size="medium"
        {...props}
    >
        <ArrowBackIcon />
    </Fab>
} */