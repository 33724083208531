import React from 'react';
import { Alert } from '@material-ui/lab';
import { Typography } from '@material-ui/core';

export const ErrorsAlert = (props) => {
    const { lastError, set_lastError } = props;

    if (lastError && lastError.length > 0) {
        return (
            <Alert severity="error" onClose={() => { set_lastError([]) }}>
                {/* <AlertTitle>Error</AlertTitle> */}
                {
                    lastError.map((err, i) => {
                        return <Typography key={i}>{String(err)}</Typography>
                    })
                }
            </Alert>
        )
    }
    return null;
}
