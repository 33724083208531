import { AUTH_CONFIG } from "../constants"
import { getCodes } from "./code-challenge-gen"

export const loginRedirectURL_PKCE = () => {
    const codes = getCodes();

    // save for later
    sessionStorage.setItem('codeVerifier', codes.code_verifier);

    const binRandom = new Uint8Array(12)
    window.crypto.getRandomValues(binRandom)
    const nonce = [...binRandom]
        // eslint-disable-next-line no-bitwise
        .map(b => (`0${(b & 0xFF).toString(16)}`).slice(-2))
        .join('')
    const params = [
        `nonce=${nonce}`,
        `redirect_uri=${encodeURIComponent(AUTH_CONFIG.REDIRECT_URI)}`,
        'response_type=code',
        'scope=openid+email+profile',
        'client_id=' + AUTH_CONFIG.CLIENT_ID,

        'code_challenge_method=S256',
        'code_challenge=' + codes.code_challenge,
        'state=xyz'
    ]

    return AUTH_CONFIG.ISSUER_URI + AUTH_CONFIG.AUTH_PATH + '?' + params.join('&');
}

export const loginWithCode_PKCE = async (code) => {
    console.log('loginWithCode_PKCE:', code);

    const codeVerifier = sessionStorage.getItem('codeVerifier');
    console.log('loginWithCode_PKCE code_verifier:', codeVerifier);

    if (!codeVerifier) {
        console.error('loginWithCode_PKCE: no code verifier stored.');
        return;
    }

    const url = AUTH_CONFIG.ISSUER_URI + AUTH_CONFIG.TOKEN_PATH;
    // if used in proxy:
    // const url = AUTH_CONFIG.TOKEN_PATH;
    const formData = {
        grant_type: 'authorization_code',
        client_id: AUTH_CONFIG.CLIENT_ID,
        redirect_uri: AUTH_CONFIG.REDIRECT_URI,
        // redirect_uri: encodeURIComponent(AUTH_CONFIG.REDIRECT_URI),
        code,
        code_verifier: codeVerifier,
    }

    try {
        const response = await fetch(url, {
            method: 'POST',
            body: encodeFormData(formData),
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'accept': 'application/json',
                'cache-control': 'no-cache',
            }
        })
        const json = await response.json()
        console.log('loginWithCode_PKCE resp:', json);
        return json
    } catch (error) {
        console.error('loginWithCode_PKCE error:', error);
        throw error;
    }
};

export const logoutRedirectURL = (state) => {
    const idtoken = sessionStorage.getItem('idtoken');

    console.log('logoutRedirectURL idtoken:', idtoken);

    if (!idtoken) {
        console.error('logoutRedirectURL error? should have idTokne stored?');
        return;
    }

    return AUTH_CONFIG.ISSUER_URI + AUTH_CONFIG.LOGOUT_PATH + '?'
        + 'id_token_hint=' + idtoken
        + '&post_logout_redirect_uri=' + encodeURIComponent(AUTH_CONFIG.LOGOUT_REDIRECT_URI); 
}

export const extractParamsFromLocation = () => {
    console.log('extractParamsFromLocation window.location.search:', window.location.search);
    // const params = window.location.search
    try {
        const params = window.location.search
            .replace('?', '')
            .split('&')
            .map(p => p.split('='))
            .reduce((obj, p) => ({
                ...obj,
                [p[0]]: p[1],
            }), {})
        return {
            code: params.code,
            error: params.error,
            error_description: params.error_description,
        }
    } catch (_) {
        return undefined
    }
}

export const parseJwt = (token) => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};

export const encodeFormData = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
        .join('&');
}