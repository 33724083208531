import { FormControl, LinearProgress, Typography, TextField, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// import SearchIcon from '@material-ui/icons/Search';
import React, { useCallback, useEffect, useState } from 'react';
// import { getExceptionsData } from '../api/api';
import { MyFab } from '../comps/MyFab';
import { VirtualizedTable, stableSort, getComparator } from '../comps/MyTable';
import AddExceptionDialog from '../dialogs/exception/AddExceptionDialog';
import EditExceptionDialog from '../dialogs/exception/EditExceptionDialog';
import { useGlobalAppState } from '../state/myGlobalContext';
import { useWhyDidYouUpdate } from '../hooks/whyDidYouUpdate';
import { Autocomplete } from '@material-ui/lab';
//import './Exceptions.css';

const useStyles = makeStyles(theme => ({
    formControlFilter: {
        margin: theme.spacing(1, 0),
        // width: '100%',
        width: 220,
        minWidth: 140,

        // margin-right: 8px;
    },
    formControlFilter_date: {
        margin: theme.spacing(1, 0),
        width: 140,
    },
}));


// let wasFirstLoad = false;

export default function Exceptions(props) {

    // console.log('Exceptions props:', props);

    useWhyDidYouUpdate('Exceptions', props)

    const classes = useStyles();
    const {
        state: {
            // resorts_LOV, 
            resorts_from_exceptions_LOV,
            chain_codes_LOV, exceptions, isLoading },
        loadExceptionsFromBackend, // set_exceptions, set_isLoading, // load_LOVs
    } = useGlobalAppState();
    // const [isLoading, set_isLoading] = useState(false);
    // const [items, set_items] = useState([]);
    const [toOpen_AddExceptionDialog, set_toOpen_AddExceptionDialog] = useState(false);
    const [toOpen_EditExceptionDialog, set_toOpen_EditExceptionDialog] = useState(false);
    const [editExceptionItem, set_editExceptionItem] = useState(null);

    const [filterValues, set_filterValues] = useState({
        resort: null, // {} '',
        chain: null, //'',
    });
    const [filters_inputValue, set_filters_inputValue] = useState({
        resort: '', // {} '',
        chain: '', //'',
    });


    const [itemsSorted, set_itemsSorted] = useState([]);
    // sorting
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('resort_desc');
    // const [resorts_LOV, set_resorts_LOV] = useState([]);

    const handleRequestSort = (event, property) => {
        console.log('handleRequestSort:', property);

        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    }

    const loadExceptionsFromBackend_cb = useCallback(loadExceptionsFromBackend, []);
    // const load_LOVs_cb = useCallback(load_LOVs, [])

    useEffect(() => {
        loadExceptionsFromBackend_cb()
        // load_LOVs_cb()
        return () => { }
    }, [loadExceptionsFromBackend_cb,
        // load_LOVs_cb
    ]);

    // filter by RESORT and CHAIN
    useEffect(() => {
        let res = exceptions

        if (filters_inputValue.resort && filters_inputValue.resort !== '') {
            const strToSearch = String(filters_inputValue.resort).toLowerCase();
            console.log('AAAAAA filters_inputValue.resort strToSearch:', strToSearch);
            res = res.filter(item => {
                // return item.chain_code.includes(filters_inputValue.resort.value)
                console.log('item.resort_desc:', item.resort, item.resort_desc);
                // const res = String(item.resort).toLowerCase()
                const res_desc = String(item.resort_desc).toLowerCase()
                return res_desc.includes(strToSearch)
                // return res.includes(strToSearch) || res_desc.includes(strToSearch)
                    // || (res + ' - ' +  res_desc).includes(filters_inputValue.resort)
            })
        }

        if (filters_inputValue.chain && filters_inputValue.chain !== '') {
            res = res.filter(item => {
                return item.chain_code.includes(filters_inputValue.chain) ||
                    item.chain_code_desc.includes(filters_inputValue.chain)
            })
        }

        // sort
        // const sorted = stableSort(exceptions, getComparator(order, orderBy))
        const sorted = stableSort(res, getComparator(order, orderBy))
        set_itemsSorted(sorted)
    }, [exceptions, order, orderBy, filters_inputValue]);

    /* const handleChangeFilterVal = event => {
        const { name, value } = event.target; // <-- moved outside asynchronous context
        console.log('set_filterValues name value:', name, value);
        set_filterValues(prev => {
            return {
                ...prev, [name]: value
            }
        })
    }; */
    /* const handleChangeFilterVal = (event, newValue) => {
        const { name, value } = event.target; // <-- moved outside asynchronous context
        console.log('set_filterValues name value:', name, value);
        set_filterValues(prev => {
            return {
                ...prev, [name]: value
            }
        })
    }; */

    // const onSearch = () => { }

    const onRowClick = (e) => {
        console.log('onRowClick', e);
    }

    const onRowDoubleClick = (e) => {
        console.log('onRowDoubleClick', e);
        onEditItem(e.index)
    }

    const onEditItem = (rowIndex) => {
        // console.log('onEditItem', rowIndex);
        // const itemToEdit = exceptions[rowIndex];
        const itemToEdit = itemsSorted[rowIndex];
        console.log('onEditItem', rowIndex, itemToEdit);

        //
        set_editExceptionItem(itemToEdit)
        set_toOpen_EditExceptionDialog(true)
    }

    const handleCloseDialog = (toReload) => {
        set_toOpen_AddExceptionDialog(false)

        if (toReload) {
            loadExceptionsFromBackend_cb()
        }
    }
    const handleClose_EditExceptionDialog = (toReload = false) => {
        set_toOpen_EditExceptionDialog(false)
        set_editExceptionItem(null)
        if (toReload) {
            loadExceptionsFromBackend_cb();
        }
    }

    // this way, close handler does not provoke rerender of Dialog
    const handleCloseDialog_cb = useCallback(handleCloseDialog, []);
    const handleClose_EditExceptionDialog_cb = useCallback(handleClose_EditExceptionDialog, []);

    return (
        <div className="Exceptions">

            <Typography variant="h2" color="primary">SAME HOTEL EXCEPTIONS</Typography>

            <div style={{ height: '12px' }}>
                {
                    isLoading && <LinearProgress />
                }
            </div>

            <div className="filtersDiv">


                <FormControl className={classes.formControlFilter} >
                    {/*  <InputLabel>RESORT</InputLabel>
                        <Select
                            value={filterValues.resort}
                            onChange={handleChangeFilterVal}
                            input={<Input name="resort" />}
                        >
                            <MenuItem value=""><em>None</em></MenuItem>
                            {
                                resorts_LOV.map((b, i) => {
                                    return <MenuItem value={b.value} key={i}>{b.label}</MenuItem>
                                })
                            }
                        </Select> */}

                    <Autocomplete
                        // options={resorts_LOV}
                        options={resorts_from_exceptions_LOV}
                        getOptionLabel={(option) => option.label}
                        // style={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="RESORT" />}

                        value={filterValues.resort}
                        // onChange={handleChangeFilterVal}
                        onChange={(event, newValue) => {
                            console.log('onChange', newValue)
                            set_filterValues(prev => ({
                                // ...prev,
                                resort: newValue,
                                chain: prev.chain
                            }))
                        }}
                        inputValue={filters_inputValue.resort}
                        onInputChange={(event, newInputValue) => {
                            set_filters_inputValue(
                                prev => ({
                                    resort: newInputValue,
                                    chain: prev.chain
                                })
                            );
                        }}
                    />

                </FormControl>

                <Box component="span" m={1} />


                <FormControl className={classes.formControlFilter} fullWidth>
                    {/*  <InputLabel>CHAIN</InputLabel>
                        <Input
                            name="chain"
                            value={filterValues.chain}
                            onChange={handleChangeFilterVal}
                        /> */}

                    <Autocomplete
                        // use resorts LOV here as it has chain code in same object
                        // options={resorts_LOV}
                        options={chain_codes_LOV}

                        // getOptionLabel={(option) => option.label}
                        getOptionLabel={(option) => option ? option.chain_code_desc : ''}

                        // getOptionSelected

                        // style={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="CHAIN" />}

                        value={filterValues.chain}
                        // onChange={handleChangeFilterVal}
                        onChange={(event, newValue) => {

                            console.log('CHAIN newValue:', newValue);
                            set_filterValues(prev => ({
                                resort: prev.resort,
                                // chain: newValue ? newValue.chain_code_desc : ''
                                chain: newValue //? newValue.chain_code_desc : ''
                            }))
                        }}
                        inputValue={filters_inputValue.chain}
                        onInputChange={(event, newInputValue) => {
                            set_filters_inputValue(
                                prev => ({
                                    resort: prev.resort,
                                    chain: newInputValue
                                })
                            );
                        }}
                    />
                </FormControl>


                {/* <div className="filterItem_search_btn">
                    <IconButton onClick={onSearch}>
                        <SearchIcon />
                    </IconButton>
                </div> */}
            </div>

            <div style={{ height: '100%', width: '100%', padding: 12, boxSizing: 'border-box' }}>

                <VirtualizedTable
                    // rowCount={exceptions.length}
                    // rowGetter={({ index }) => exceptions[index]}
                    rowCount={itemsSorted.length}
                    rowGetter={({ index }) => itemsSorted[index]}
                    onRowClick={onRowClick}
                    onEditItem={onEditItem}
                    onRowDoubleClick={onRowDoubleClick}

                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}

                    columns={[
                        {
                            width: 300,
                            label: 'RESORT CODE',
                            dataKey: 'resort',
                        },
                        {
                            width: 300,
                            label: 'RESORT NAME',
                            dataKey: 'resort_desc',
                        },
                        {
                            width: 100,
                            label: 'EXCEPTION CODES ENROLLMENT STAY',
                            dataKey: 'exception_codes_enrollment_stay_onlyLabel',
                            numeric: true,
                            // renderCustom: renderExceptionCell,
                        },
                        {
                            width: 100,
                            label: 'EXCEPTION CODES REPEAT STAY',
                            dataKey: 'exception_codes_repeat_stay_onlyLabel',
                            numeric: true,
                            // renderCustom: renderExceptionCell,
                        },

                        /* {
                            width: 100,
                            label: 'EXCEPTION CODES ENROLLMENT STAY',
                            dataKey: 'is_enrollment',
                            numeric: true,
                            renderCustom: renderExceptionCell,
                        },
                        {
                            width: 100,
                            label: 'EXCEPTION CODES REPEAT STAY',
                            dataKey: 'is_repeat',
                            numeric: true,
                            renderCustom: renderExceptionCell,
                        }, */
                        {
                            width: 80,
                            label: '',
                            dataKey: 'actionButtons',
                            numeric: true,
                        },
                    ]}
                />
            </div>

            <div className="footer">
                <MyFab onClick={e => set_toOpen_AddExceptionDialog(true)} />
            </div>

            <AddExceptionDialog
                open={toOpen_AddExceptionDialog}
                onClose={handleCloseDialog_cb}
            />

            <EditExceptionDialog
                open={toOpen_EditExceptionDialog}
                onClose={handleClose_EditExceptionDialog_cb}
                editExceptionItem={editExceptionItem}
            />

        </div>
    );
}
