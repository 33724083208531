export function isDevelopmentMode() {
    return process.env.NODE_ENV === 'development'
}


export function groupArrayBy(array, f) {
    var groups = {};
    array.forEach(function (o) {
        var group = JSON.stringify(f(o));
        groups[group] = groups[group] || [];
        groups[group].push(o);
    });
    return Object.keys(groups).map(function (group) {
        return groups[group];
    })
}

export const getFormattedDate = (aDate) => {
    console.log('getFormattedDate aDate:', aDate);

    const date = aDate ? aDate : new Date();
    const dateString = new Date(date.getTime() - (date.getTimezoneOffset() * 60000))
        .toISOString()
        .split("T")[0];
    return dateString;
}

export const isDateValid = (val) => {
    return val instanceof Date && !isNaN(val.valueOf())
}

export const isJson = (str) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}