import {
    Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl,
    FormControlLabel, Input, InputLabel, MenuItem, Select, Typography, LinearProgress,
    TextField, Box, DialogContentText, Chip
} from '@material-ui/core';
import React, { useState, useCallback, useEffect } from 'react';
import { MyFabCancel, MyFabSubmit, MyFabCopy } from '../../comps/MyFab';
import { useGlobalAppState } from '../../state/myGlobalContext';
import { useStyles_dialog } from '../../theme/dialogStyles';
import { useWhyDidYouUpdate } from '../../hooks/whyDidYouUpdate';
import { updateException, insertException } from '../../api/api';
import VirtualizedSelect from '../../comps/AutoCompleteComp';
import { AuthService } from '../../api/auth';
import { getFormattedDate, isJson } from '../../utils/utils';
import { ErrorsAlert } from '../../comps/ErrorsAlert';
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

export default function EditExceptionDialog(props) {

    // console.log('EditExceptionDialog props:', props);

    useWhyDidYouUpdate('EditExceptionDialog', props)

    const classes = useStyles_dialog();
    const { open, onClose, editExceptionItem } = props;
    const {
        state: { resorts_LOV,
            exceptionCodes_LOV, isLoading_LOV, resorts_from_exceptions_LOV },
        load_LOVs,
    } = useGlobalAppState();

    const [entryValues, set_entryValues] = useState({
        resort: '',
        exceptionCodes_enrollmentStay: [],
        exceptionCodes_repeatStay: [],
        activate: true,
    });
    const [lastError, set_lastError] = useState([]);
    const [isCopyInProcess, set_isCopyInProcess] = useState(false);
    

    const load_LOVs_cb = useCallback(load_LOVs, [])
    // const load_exceptionCodes_LOV_cb = useCallback(load_exceptionCodes_LOV, []);

    useEffect(() => {
        if (open) {
            load_LOVs_cb()
            // reset copy dialog resorts values
            set_copyTarget_resorts([])
            // load_exceptionCodes_LOV_cb()
        }
    }, [open, load_LOVs_cb]);
    // }, [load_exceptionCodes_LOV_cb]);

    const getOptFor = useCallback((exception) => {
        return {
            // add label value
            ...exceptionCodes_LOV.find(o => o.value === exception.enrollment_code),
            // add rest object data
            ...exception
        }
    }, [exceptionCodes_LOV])

    const [initialExceptions, set_initialExceptions] = useState({
        enrStayOpts: [],
        enrRepeatOpts: [],
    });
    useEffect(() => {
        const pi = editExceptionItem;
        if (pi) {
            console.log('pi:', pi);

            // store init data, to compare on submit
            const enrStayOpts = pi.exception_codes_enrollment_stay.map(v => getOptFor(v));
            const enrRepeatOpts = pi.exception_codes_repeat_stay.map(v => getOptFor(v));
            console.log('pi enrStayOpts:', enrStayOpts);

            set_initialExceptions({
                enrStayOpts,
                enrRepeatOpts
            })

            set_entryValues(entryValues => (
                {
                    resort: pi.resort,
                    chain_code: pi.chain_code,
                    exceptionCodes_enrollmentStay: enrStayOpts,
                    exceptionCodes_repeatStay: enrRepeatOpts,
                    activate: true,
                })
            )

            // clear errors
            set_lastError([]);
        }
        return () => { };
    }, [editExceptionItem, getOptFor]);

    const handleChangeEntry_CheckBox = event => {
        const targetName = event.target.name;
        const targetValue = event.target.checked;
        console.log('name value:', targetName, targetValue);

        set_entryValues(prev => ({ ...prev, [targetName]: targetValue }))
    };

    const handleSubmit = async event => {
        console.log('handleSubmit:', entryValues);

        // for each exception code - do API call

        if (!entryValues.activate) {
            //
            // set inactive all in the list
            //

            let wasError = false;
            const payloads = [];

            entryValues.exceptionCodes_enrollmentStay.forEach(exc => {
                const payload = {
                    id: exc.id,
                    enrollment_code: exc.enrollment_code,
                    is_enrollment: exc.is_enrollment,
                    is_repeat: exc.is_repeat,
                    inactive_date: getFormattedDate(new Date()),
                    update_user: AuthService.userData.email,
                }
                payloads.push(payload)
            })
            entryValues.exceptionCodes_repeatStay.forEach(exc => {
                const payload = {
                    id: exc.id,
                    enrollment_code: exc.enrollment_code,
                    is_enrollment: exc.is_enrollment,
                    is_repeat: exc.is_repeat,
                    inactive_date: getFormattedDate(new Date()),
                    update_user: AuthService.userData.email,
                }
                payloads.push(payload)
            })

            console.log('Edit Exceptions, set inactive, total payloads:', payloads);

            await Promise.all(payloads.map((payload, index) => {
                return updateException(payload).then(resp => {
                    console.log('set inactive exc updateException resp:', resp);
                })
            })).catch(err => {
                console.error('Promise.all set inactive exc updateException err:', err);
                wasError = true;
                set_lastError(prev => { return [...prev, err] });
            });

            console.log('DONE');

            if (!wasError) {
                onClose(true)
            }
        }
        else {
            // what changed ?
            checkWhatToUpdate();
        }
    };

    const checkWhatToUpdate = async () => {
        // check for removed ones
        let removed = []
        initialExceptions.enrStayOpts.forEach(initialItem => {
            if (entryValues.exceptionCodes_enrollmentStay.find(a => a.id === initialItem.id)) {
                // found
            } else {
                // missing
                console.log('removed item:', initialItem);
                // removed.push(initialItem.id)
                removed.push(initialItem)
            }
        })
        // check for new ones
        console.log(' check for new ones:', initialExceptions.enrStayOpts);
        console.log(' check for new ones:', entryValues.exceptionCodes_enrollmentStay);
        let added = []
        entryValues.exceptionCodes_enrollmentStay.forEach(itemInTheSelect => {
            if (initialExceptions.enrStayOpts.find(a => a.id === itemInTheSelect.id)) {
                // found same one as in initial list
            } else {
                // not found in initial list - new one
                console.log('added item:', itemInTheSelect);
                // added.push(itemInTheSelect.id)
                added.push(itemInTheSelect)
            }
        })
        console.log('enrollmentStay added:', added);
        console.log('enrollmentStay removed:', removed);

        //
        //
        let removed_repeatOpts = []
        initialExceptions.enrRepeatOpts.forEach(initialItem => {
            if (entryValues.exceptionCodes_repeatStay.find(a => a.id === initialItem.id)) {
                // found
            } else {
                // missing
                console.log('removed item:', initialItem);
                // removed.push(initialItem.id)
                removed_repeatOpts.push(initialItem)
            }
        })
        // check for new ones
        console.log(' check for new ones:', initialExceptions.enrRepeatOpts);
        console.log(' check for new ones:', entryValues.exceptionCodes_repeatStay);
        let added_repeatOpts = []
        entryValues.exceptionCodes_repeatStay.forEach(itemInTheSelect => {
            if (initialExceptions.enrRepeatOpts.find(a => a.id === itemInTheSelect.id)) {
                // found same one as in initial list
            } else {
                // not found in initial list - new one
                console.log('added item:', itemInTheSelect);
                // added.push(itemInTheSelect.id)
                added_repeatOpts.push(itemInTheSelect)
            }
        })
        console.log('repeatStay added:', added_repeatOpts);
        console.log('repeatStay removed:', removed_repeatOpts);

        //
        //

        // await setExceptionsTo_enrStay(added, removed)
        await setExceptionsToNewData(added, removed, added_repeatOpts, removed_repeatOpts)

        // await setExceptionsTo_repeatStay
    }
    /* 
    
        TODO: 
        ----- on sumbit create new if not existing??? so do POST if needed ? others update with  PUT ? 
        1. filter exceptions only for this resort
        2. on submit, 
        - update removed both from enr and reapeat start, 
        - update all left
    
     */

    // const setExceptionsTo_enrStay = async (added, removed) => {
    const setExceptionsToNewData = async (added, removed, added_repeatOpts, removed_repeatOpts) => {
        let wasError = false;

        await Promise.all(added.map((exc, index) => {
            const payload = {
                id: exc.id,
                enrollment_code: exc.enrollment_code,
                is_enrollment: 'Y',
                is_repeat: exc.is_repeat,
                update_user: AuthService.userData.email,
                inactive_date: ''
            }
            return updateException(payload).then(resp => {
                console.log('updateException resp:', resp);
            })
        })).catch(err => {
            console.error('Promise.all setExceptionsToNewData added err:', err);
            wasError = true;
            set_lastError(prev => { return [...prev, err] });
        });

        await Promise.all(removed.map((exc, index) => {
            const payload = {
                id: exc.id,
                enrollment_code: exc.enrollment_code,
                is_enrollment: 'N',
                is_repeat: exc.is_repeat,
                update_user: AuthService.userData.email,
                inactive_date: '', //getFormattedDate(new Date())
            }
            return updateException(payload).then(resp => {
                console.log('updateException resp:', resp);
            })
        })).catch(err => {
            console.error('Promise.all setExceptionsToNewData removed err:', err);
            wasError = true;
            set_lastError(prev => { return [...prev, err] });
        });

        //
        //

        await Promise.all(added_repeatOpts.map((exc, index) => {
            const payload = {
                id: exc.id,
                enrollment_code: exc.enrollment_code,
                is_enrollment: exc.is_enrollment,
                is_repeat: 'Y',
                update_user: AuthService.userData.email,
                inactive_date: ''
            }
            return updateException(payload).then(resp => {
                console.log('updateException resp:', resp);
            })
        })).catch(err => {
            console.error('Promise.all setExceptionsToNewData added_repeatOpts err:', err);
            wasError = true;
            set_lastError(prev => { return [...prev, err] });
        });

        await Promise.all(removed_repeatOpts.map((exc, index) => {
            const payload = {
                id: exc.id,
                enrollment_code: exc.enrollment_code,
                is_enrollment: exc.is_enrollment,
                is_repeat: 'N',
                update_user: AuthService.userData.email,
                inactive_date: '', //getFormattedDate(new Date())
            }
            return updateException(payload).then(resp => {
                console.log('updateException resp:', resp);
            })
        })).catch(err => {
            console.error('Promise.all setExceptionsToNewData removed_repeatOpts err:', err);
            wasError = true;
            set_lastError(prev => { return [...prev, err] });
        });

        //
        //

        console.log('DONE');

        if (!wasError) {
            onClose(true)
        }
    }

    /* const setExceptionsTo_repeatStay = async (added, removed) => {
        let wasError = false;

        await Promise.all(added.map((exc, index) => {
            const payload = {
                id: exc.id,
                enrollment_code: exc.enrollment_code,
                is_enrollment: exc.is_enrollment,
                is_repeat: 'Y',
                update_user: AuthService.userData.email,
                inactive_date: ''
            }
            return updateException(payload).then(resp => {
                console.log('updateException resp:', resp);
            })
        })).catch(err => {
            console.error('Promise.all setExceptionsTo_repeatStay err:', err);
            wasError = true;
            set_lastError(err);
        });

        await Promise.all(removed.map((exc, index) => {
            const payload = {
                id: exc.id,
                enrollment_code: exc.enrollment_code,
                is_enrollment: exc.is_enrollment,
                is_repeat: 'N',
                update_user: AuthService.userData.email,
                inactive_date: ''
            }
            return updateException(payload).then(resp => {
                console.log('updateException resp:', resp);
            })
        })).catch(err => {
            console.error('Promise.all setExceptionsTo_repeatStay err:', err);
            wasError = true;
            set_lastError(err);
        });

        console.log('DONE');

        if (!wasError) {
            onClose(true)
        }
    } */





    const [toOpenCopyExceptionDialog, set_toOpenCopyExceptionDialog] = useState(false);
    const [copyTarget_resorts, set_copyTarget_resorts] = useState([]);

    const handleCopy = event => {
        set_toOpenCopyExceptionDialog(true)
    }
    const onCopyExceptionDialog_close = async (toCopy) => {
        console.log('onCopyExceptionDialog_close toCopy:', toCopy);
        console.log('onCopyExceptionDialog_close copyTarget_resorts:', copyTarget_resorts);

        if (!toCopy) {
            set_toOpenCopyExceptionDialog(false)
            return;
        }

        let wasError = false;
        set_isCopyInProcess(true);

        //
        // for each target obj copy all exceptions
        //
        const payloads = [];

        /* entryValues.exceptionCodes_enrollmentStay.forEach((exc, i) => {
            copyTarget_resorts.forEach((targetObj, ind) => {

                console.log('copyTarget_resorts.forEach targetObj:', i, targetObj);

                const payload = {
                    // resort: targetObj.property,
                    resort: targetObj.value,
                    chain_code: targetObj.chain_code,

                    enrollment_code: exc.enrollment_code,
                    is_enrollment: exc.is_enrollment,
                    is_repeat: exc.is_repeat,
                    inactive_date: exc.inactive_date || '',

                    insert_user: AuthService.userData.email,
                }
                console.log('payload:', i, payload);
                payloads.push(payload)
            });
        });

        entryValues.exceptionCodes_repeatStay.forEach((exc, i) => {
            copyTarget_resorts.forEach((targetObj, ind) => {

                console.log('copyTarget_resorts.forEach targetObj:', i, targetObj);

                const payload = {
                    // resort: targetObj.property,
                    resort: targetObj.value,
                    chain_code: targetObj.chain_code,

                    enrollment_code: exc.enrollment_code,
                    is_enrollment: exc.is_enrollment,
                    is_repeat: exc.is_repeat,
                    inactive_date: exc.inactive_date || '',

                    insert_user: AuthService.userData.email,
                }
                console.log('payload:', i, payload);
                payloads.push(payload)
            });
        }); */

        editExceptionItem.allExceptions.forEach((exc, i) => {
            copyTarget_resorts.forEach((targetObj, ind) => {

                console.log('copyTarget_resorts.forEach targetObj:', i, targetObj);

                const payload = {
                    // resort: targetObj.property,
                    resort: targetObj.value,
                    chain_code: targetObj.chain_code,

                    enrollment_code: exc.enrollment_code,
                    is_enrollment: exc.is_enrollment,
                    is_repeat: exc.is_repeat,
                    inactive_date: exc.inactive_date || '',

                    insert_user: AuthService.userData.email,
                }
                console.log('payload:', i, payload);
                payloads.push(payload)
            });
        });


        console.log('Copy Exceptions total payloads:', payloads);


        /* await Promise.all(payloads.map((payload, index) => {
            return insertException(payload).then(resp => {
                console.log('copy exc insertException resp:', resp);
            })
        })).catch(err => {
            console.error('Promise.all copy exceptions err:', err);
            wasError = true;
            set_lastError(prev => { return [...prev, err] });
        }); */

        await Promise.allSettled(payloads.map((payload, index) => {
            return insertException(payload).then(resp => {
                console.log('copy exc insertException resp:', resp);
            })
        }))
            .then(
                results => {

                    /* 
                    For each outcome object, a status string is present. 
                    If the status is fulfilled, then a value is present. 
                    If the status is rejected, then a reason is present. 
                    The value (or reason) reflects what value each promise was fulfilled (or rejected) with. */

                    results.forEach((result) => {
                        console.log('result:', result)
                        // console.log('result.status:', result.status)
                        // "fulfilled" or "rejected"
                        if (result.status === "rejected") {
                            const err = result.reason;

                            // console.log('result.reason:', result.reason)

                            console.error('Promise.all copy exceptions err:', err);
                            wasError = true;
                            // if(result.reason.ERROR) {
                            /* if(err.ERROR) {
                                set_lastError(prev => { return [...prev, err.ERROR] });
                            } else {
                                set_lastError(prev => { return [...prev, err] });
                            } */

                            // check if is response body having ERROR


                            // console.log('result.isJson(err) 1:', isJson(result.reason))
                            // console.log('result.isJson(err) 2:', isJson(result.reason.message))
                            // console.log('result.isJson(err) 3:', isJson(err))

                            // if( isJson(err)) {
                            if (isJson(result.reason.message)) {

                                const errMsg = JSON.parse(result.reason.message);
                                // set_lastError(prev => { return [...prev, err.ERROR] });
                                set_lastError(prev => { return [...prev, errMsg.ERROR] });
                            }

                            /* else if(err typeof Error) {
                                const errMsg = JSON.parse(err);
                                // set_lastError(prev => { return [...prev, err.ERROR] });
                                set_lastError(prev => { return [...prev, errMsg.ERROR] });
                            } */
                            else {
                                set_lastError(prev => { return [...prev, err] });
                            }

                        }
                    }
                    )
                }
            )
        /* .catch(err => {
            console.error('Promise.all copy exceptions err:', err);
            wasError = true;
            set_lastError(prev => { return [...prev, err] });
        }); */

        console.log('DONE');

        set_isCopyInProcess(false);

        if (!wasError) {
            set_toOpenCopyExceptionDialog(false)
            onClose(true)
        }
        else {
            set_toOpenCopyExceptionDialog(false)
            // onClose(true)
        }
    }


    const selectAllLabel = 'Select all';
    const getResortsWithSameChain_excludeSelfAndBlank = () => {
        console.log('getResortsWithSameChain_excludeSelfAndBlank entryValues:', entryValues);
        const filtered = resorts_LOV
            .filter(item => item.label !== '' && item.value !== entryValues.resort)
            .filter(item => item.chain_code === entryValues.chain_code);

        return [{ label: selectAllLabel, value: "select-all" }, ...filtered];
    }
    const copyResortOptions = getResortsWithSameChain_excludeSelfAndBlank();

    const allSelected = copyTarget_resorts.length > 0 && (copyResortOptions.length - 1 === copyTarget_resorts.length);

    const getOptionLabel = (option) => option.label;

    const autoCompleteSelectAll_optionRenderer = (option, { selected }) => {
        const selectAllProps = option.value === "select-all" ? { checked: allSelected } : {};
        return (
            <>
                <Checkbox
                    color="primary"
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                    {...selectAllProps}
                />
                {getOptionLabel(option)}
            </>
        );
    };

    // simulate limitsTag when focused
    const autoCompleteSelectAll_renderTags = (value, getTagProps) => {
        /* const getCustomizedTagProps = (params) => ({
            className: clsx(classes.tag, {
              [classes.tagSizeSmall]: size === 'small',
            }),
            disabled,
            ...getTagProps(params),
          }); */
        const restCount = value.length > 5 ? value.length - 5 : 0;

        return (
            <>
                {
                    value
                        .filter(itm => itm.value !== 'select-all')
                        .slice(0, 5)
                        .map((option, index) => (
                            <span key={index}>

                                <Chip
                                    label={getOptionLabel(option)}
                                    //   size={size}
                                    //   {...getCustomizedTagProps({ index })}
                                    //   {...ChipProps}
                                    {...getTagProps({ index })}
                                // key={index}
                                />
                            </span>
                        ))
                }
                {
                    restCount > 0 &&
                    <Typography>+ {restCount} more</Typography>
                }
            </>
        );
    }

    return (
        <Dialog
            fullWidth maxWidth="md"
            open={open}
            onClose={() => onClose(false)}
        >
            <DialogTitle disableTypography>
                <Typography variant="h5" color="primary">EDIT EXCEPTIONS</Typography>

                <ErrorsAlert lastError={lastError} set_lastError={set_lastError} />
            </DialogTitle>

            <div style={{ height: '12px' }}>{isLoading_LOV && <LinearProgress />}</div>

            <DialogContent className={classes.dialogContentMinHeight}>

                <FormControl className={classes.formControl} fullWidth>
                    <InputLabel>RESORT</InputLabel>
                    <Select
                        value={entryValues.resort}
                        // onChange={handleChangeEntry}
                        input={<Input name="resort" />}
                        disabled
                    >
                        <MenuItem value=""><em>None</em></MenuItem>
                        {
                            // resorts_LOV.map((b, i) => {
                            resorts_from_exceptions_LOV.map((b, i) => {
                                return <MenuItem value={b.value} key={i}>{b.label}</MenuItem>
                            })
                        }
                    </Select>
                </FormControl>

                <FormControl className={classes.formControl} fullWidth>
                    <VirtualizedSelect
                        multiple
                        name="exceptionCodes_enrollmentStay"
                        options={exceptionCodes_LOV.filter(v => v.resort === entryValues.resort)}
                        renderInput={(params) => <TextField {...params} variant="standard" label="EXCEPTION CODES - ENROLLMENT STAY" />}
                        getOptionLabel={(option) => option ? option.label : ''}
                        value={entryValues.exceptionCodes_enrollmentStay}
                        onChange={(event, newValue) => {
                            console.log('onChange event, newValue:', event, newValue);
                            set_entryValues(prev => ({
                                ...prev,
                                exceptionCodes_enrollmentStay: newValue,
                            }))
                        }}
                    />
                </FormControl>

                <FormControl className={classes.formControl} fullWidth>
                    <VirtualizedSelect
                        multiple
                        name="exceptionCodes_repeatStay"
                        // options={exceptionCodes_LOV.filter(v=>v.is)}
                        // options={exceptionCodes_LOV}
                        options={exceptionCodes_LOV.filter(v => v.resort === entryValues.resort)}
                        renderInput={(params) => <TextField {...params} variant="standard" label="EXCEPTION CODES - REPEAT STAY" />}
                        getOptionLabel={(option) => option ? option.label : ''}
                        value={entryValues.exceptionCodes_repeatStay}
                        onChange={(event, newValue) => {
                            console.log('onChange event, newValue:', event, newValue);
                            set_entryValues(prev => ({
                                ...prev,
                                exceptionCodes_repeatStay: newValue,
                            }))
                        }}
                    />
                </FormControl>

                <FormControl className={classes.formControl} fullWidth>
                    <FormControlLabel
                        label={<Typography color="primary">ACTIVATE</Typography>}
                        control={
                            <Checkbox
                                name="activate"
                                checked={entryValues.activate}
                                onChange={handleChangeEntry_CheckBox}
                            />
                        }
                    />
                </FormControl>
            </DialogContent>

            <DialogActions>
                <MyFabCancel onClick={() => onClose(false)} />
                <div>
                    <MyFabCopy onClick={() => handleCopy()} title="Copy" />
                    <Box component="span" m={1} />
                    <MyFabSubmit onClick={() => handleSubmit()} title="Submit" />
                </div>
            </DialogActions>



            <Dialog
                fullWidth maxWidth="sm"
                open={toOpenCopyExceptionDialog}
                onClose={() => onCopyExceptionDialog_close(false)}>
                <DialogTitle>COPY EXCEPTION</DialogTitle>

                <div style={{ height: '12px' }}>{isCopyInProcess && <LinearProgress />}</div>

                <DialogContent>
                    <DialogContentText>Selected resorts to copy exceptions to.</DialogContentText>
                    <FormControl className={classes.formControl} fullWidth>
                        <VirtualizedSelect
                            multiple
                            name="resorts_copyTargets"
                            options={copyResortOptions}
                            renderInput={(params) => <TextField {...params} variant="standard" label="RESORTS" />}
                            getOptionLabel={getOptionLabel}
                            value={copyTarget_resorts}
                            onChange={(event, newValue) => {
                                console.log('copyTarget_resorts onChange event, newValue:', event, newValue);
                                if (newValue.find(itm => itm.value === 'select-all')) {
                                    if (allSelected) {
                                        // deselect all
                                        set_copyTarget_resorts([])
                                    } else {
                                        // select all
                                        set_copyTarget_resorts([...copyResortOptions.slice(1)])
                                        // set_copyTarget_resorts([...resortOptions])
                                    }
                                } else {
                                    set_copyTarget_resorts(newValue)
                                }
                            }}

                            renderOption={autoCompleteSelectAll_optionRenderer}
                            disableCloseOnSelect
                            // limitTags is active only when focused
                            limitTags={5}
                            getLimitTagsText={(more) => `${more}`}
                            openOnFocus
                            renderTags={autoCompleteSelectAll_renderTags}

                        />
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <MyFabCancel onClick={() => onCopyExceptionDialog_close(false)} />
                    <MyFabSubmit onClick={() => onCopyExceptionDialog_close(true)} title="Submit" />
                </DialogActions>
            </Dialog>


        </Dialog>
    );
}
