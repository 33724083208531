import { Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormGroup, FormLabel, Typography, LinearProgress, TextField } from '@material-ui/core';
import React, { useState, useCallback, useEffect } from 'react';
import { useGlobalAppState } from '../../state/myGlobalContext';
import { MyFabCancel, MyFabSubmit } from '../../comps/MyFab';
import { insertException } from '../../api/api';
import { useStyles_dialog } from '../../theme/dialogStyles';
import { AuthService } from '../../api/auth';
import { useWhyDidYouUpdate } from '../../hooks/whyDidYouUpdate';
import VirtualizedSelect from '../../comps/AutoCompleteComp';
import { Autocomplete } from '@material-ui/lab';
import { getFormattedDate } from '../../utils/utils';
import { ErrorsAlert } from '../../comps/ErrorsAlert';

const INITIAL_STATE = {
    resort: '',
    exceptionCodes: [], // ['aaa', 'bbbbbbb bbb'], // use it as array of enrollment_code
    is_enrollment: 'N',
    is_repeat: 'N',
    activate: true, // use to set inactive_date: ''
}

export default function AddExceptionDialog(props) {

    // console.log('AddExceptionDialog props:', props);

    useWhyDidYouUpdate('AddExceptionDialog', props)

    const classes = useStyles_dialog();
    const { open, onClose } = props;
    const [lastError, set_lastError] = useState([]);
    const {
        state: { resorts_LOV,
            // exceptionCodes_LOV, 
            exceptionCodes_for_CREATE_EXC_LOV,
            isLoading_LOV },
        load_LOVs,
    } = useGlobalAppState();

    const [entryValues, set_entryValues] = useState(
        /* {
        resort: '',
        exceptionCodes: [], // ['aaa', 'bbbbbbb bbb'], // use it as array of enrollment_code
        is_enrollment: 'N',
        is_repeat: 'N',
        activate: true, // use to set inactive_date: ''
    } */
        INITIAL_STATE
    );

    const load_LOVs_cb = useCallback(load_LOVs, [])
    // const load_exceptionCodes_LOV_cb = useCallback(load_exceptionCodes_LOV, []);

    useEffect(() => {
        if (open) {

            // reset view
            set_entryValues(INITIAL_STATE)

            // clear errors
            set_lastError([]);

            load_LOVs_cb()
            // load_exceptionCodes_LOV_cb()
        }
    }, [open, load_LOVs_cb]);
    // }, [open, load_LOVs_cb, load_exceptionCodes_LOV_cb]);


    // const [exceptionCodes_LOV_local, set_exceptionCodes_LOV_local] = useState();
    // useEffect(() => {
    //     if (open) {
    //         set_exceptionCodes_LOV_local(exceptionCodes_LOV)
    //     }
    // }, [open, exceptionCodes_LOV]);


    // const getOptFor = (enrollment_code) => {
    //     return exceptionCodes_LOV.find(o => o.value === enrollment_code)
    // }

    /* const handleChangeEntry = event => {
        const { name, value } = event.target;
        console.log('name value:', name, value);
        set_entryValues(prev => ({ ...prev, [name]: value }))
    }; */

    const handleChangeEntry_CheckBox = event => {
        const { name, checked } = event.target;
        console.log('name checked:', name, checked);
        let newVal = checked;
        if (name !== 'activate') {
            newVal = checked ? 'Y' : 'N'
        }
        set_entryValues(prev => ({ ...prev, [name]: newVal }))
    };

    const handleSubmit = event => {
        console.log('handleSubmit:', entryValues);

        // for each excep code in array, do POST:
        /* {
            "resort": "KIBUD",
            "chain_code": "KI",
            "enrollment_code": "GHA",
            "is_enrollment": "Y",
            "is_repeat": "N",
            "insert_user": "daniel.voegele@nokantdo.com",
            "inactive_date": ""
        } */

        // const resort = entryValues.resort
        const resort = entryValues.resort.value
        const fi = resorts_LOV.find(r => r.value === resort)
        const chain_code = fi ? fi.chain_code : '';

        const payloads = [];
        entryValues.exceptionCodes.forEach((enrollment_code, i) => {
            const payload = {
                resort,
                chain_code,
                // chain_code: entryValues.chain_code,
                enrollment_code: enrollment_code.value,
                is_enrollment: entryValues.is_enrollment,
                is_repeat: entryValues.is_repeat,
                insert_user: AuthService.userData.email,
                inactive_date: entryValues.activate ? '' : getFormattedDate()
            }
            console.log('payload:', i, payload);
            payloads.push(payload)
        })

        sendAll(payloads)
    };
    const sendAll = async (payloads) => {

        let wasError = false;

        await Promise.all(payloads.map((payload, index, array) => {
            console.log('sendAll index:', index);
            return insertException(payload).then(resp => {
                console.log('insertException resp:', resp);
            })
        })).catch(err => {
            console.error('Promise.all insertException err:', err);
            wasError = true;
            set_lastError(prev => { return [...prev, err] });
        });

        console.log('DONE');

        if (!wasError) {
            onClose(true)
        }
    }

    return (
        <Dialog
            fullWidth maxWidth="md"
            open={open}
            onClose={() => onClose(false)}
        >
            <DialogTitle disableTypography>
                <Typography variant="h5" color="primary">CREATE EXCEPTION</Typography>

                <ErrorsAlert lastError={lastError} set_lastError={set_lastError} />
            </DialogTitle>

            <div style={{ height: '12px' }}>
                {
                    isLoading_LOV && <LinearProgress />
                }
            </div>

            <DialogContent>

                <DialogContentText>
                    Define ENROLLMENT CODE exceptions that are to be considered SAME HOTEL during enrollment or repeat stays for a particular property.
            </DialogContentText>

                <FormControl className={classes.formControl} fullWidth>
                    {/* <InputLabel>RESORT</InputLabel>
                    <Select
                        value={entryValues.resort}
                        onChange={handleChangeEntry}
                        input={<Input name="resort" />}
                    >
                        <MenuItem value=""><em>None</em></MenuItem>
                        {
                            resorts_LOV.map((b, i) => {
                                return <MenuItem value={b.value} key={i}>{b.label}</MenuItem>
                            })
                        }
                    </Select> */}

                    <Autocomplete
                        // options={resorts_LOV}
                        options={resorts_LOV}
                        getOptionLabel={(option) => option.label}
                        // style={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="RESORT" />}

                        value={entryValues.resort}
                        // onChange={handleChangeFilterVal}
                        onChange={(event, newValue) => {
                            console.log('onChange', newValue)
                            /* set_filterValues(prev => ({
                                // ...prev,
                                resort: newValue,
                                chain: prev.chain
                            })) */
                            // const { name, value } = event.target;
                            // console.log('name value:', name, value);
                            set_entryValues(prev => ({ ...prev, resort: newValue }))
                        }}
                    /* getOptionLabel={
                        (x) => { return x.label }
                    } */
                    /* inputValue={filters_inputValue.resort}
                    onInputChange={(event, newInputValue) => {
                        set_filters_inputValue(
                            prev => ({
                                resort: newInputValue,
                                chain: prev.chain
                            })
                        );
                    }} */
                    />

                </FormControl>

                <FormControl className={classes.formControl} fullWidth>
                    <VirtualizedSelect
                        multiple
                        name="exceptionCodes"
                        // options={exceptionCodes_LOV}
                        options={exceptionCodes_for_CREATE_EXC_LOV}
                        renderInput={(params) => <TextField {...params} name="exceptionCodes" variant="standard" label="EXCEPTION CODES" />}
                        getOptionLabel={(option) => option.label}
                        value={entryValues.exceptionCodes}
                        onChange={(event, newValue) => {
                            console.log('onChange event, newValue:', event, newValue);
                            set_entryValues(prev => ({
                                ...prev,
                                exceptionCodes: newValue,
                                /* exceptionCodes: [
                                    ...prev.exceptionCodes,
                                    // ...newValue.filter((option) => prev.exceptionCodes.indexOf(option) === -1),
                                    ...newValue.filter((option) => {
                                        // getOptFor
                                        console.log('option:', option);
                                        return prev.exceptionCodes.indexOf(option) === -1
                                    }),
                                ] */
                            }))
                        }}
                    />
                </FormControl>

                <FormGroup className={classes.formGroup_enrollment}>
                    <FormLabel component="p">ENROLLMENT CODE EXCEPTIONS APPLY DURING:</FormLabel>
                    <FormControlLabel
                        label={<Typography color="primary">ENROLLMENT STAY</Typography>}
                        control={
                            <Checkbox
                                name="is_enrollment"
                                checked={entryValues.is_enrollment === 'Y'}
                                onChange={handleChangeEntry_CheckBox}
                            />
                        }
                    />
                    <FormControlLabel
                        label={<Typography color="primary">REPEAT STAY</Typography>}
                        control={
                            <Checkbox
                                name="is_repeat"
                                checked={entryValues.is_repeat === 'Y'}
                                onChange={handleChangeEntry_CheckBox}
                            />
                        }
                    />
                </FormGroup>

                <FormControl className={classes.formControl} fullWidth>
                    <FormControlLabel
                        label={<Typography color="primary">ACTIVATE</Typography>}
                        control={
                            <Checkbox
                                name="activate"
                                checked={entryValues.activate}
                                onChange={handleChangeEntry_CheckBox}
                            />
                        }
                    />
                </FormControl>
            </DialogContent>

            <DialogActions>
                <MyFabCancel onClick={() => onClose(false)} />
                <MyFabSubmit onClick={() => handleSubmit()}
                    disabled={entryValues.is_enrollment === 'N' && entryValues.is_repeat === 'N'}
                />
            </DialogActions>
        </Dialog>
    );
}
